'use strict';

var cov_22dd8a6imc = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/projects/project-list/project-list-item.js',
      hash = 'abf1140016036cb1f7976ef032bddbc163ee7813',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/projects/project-list/project-list-item.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 38,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 26
        },
        end: {
          line: 11,
          column: 3
        }
      },
      '2': {
        start: {
          line: 13,
          column: 2
        },
        end: {
          line: 13,
          column: 35
        }
      },
      '3': {
        start: {
          line: 16,
          column: 17
        },
        end: {
          line: 16,
          column: 21
        }
      },
      '4': {
        start: {
          line: 18,
          column: 4
        },
        end: {
          line: 20,
          column: 6
        }
      },
      '5': {
        start: {
          line: 19,
          column: 6
        },
        end: {
          line: 19,
          column: 20
        }
      },
      '6': {
        start: {
          line: 22,
          column: 4
        },
        end: {
          line: 28,
          column: 6
        }
      },
      '7': {
        start: {
          line: 35,
          column: 2
        },
        end: {
          line: 37,
          column: 51
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 38,
            column: 1
          }
        }
      },
      '1': {
        name: 'projectListItemCtrl',
        decl: {
          start: {
            line: 15,
            column: 11
          },
          end: {
            line: 15,
            column: 30
          }
        },
        loc: {
          start: {
            line: 15,
            column: 33
          },
          end: {
            line: 33,
            column: 3
          }
        }
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 18,
            column: 24
          },
          end: {
            line: 18,
            column: 25
          }
        },
        loc: {
          start: {
            line: 18,
            column: 30
          },
          end: {
            line: 20,
            column: 5
          }
        }
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 22,
            column: 19
          },
          end: {
            line: 22,
            column: 20
          }
        },
        loc: {
          start: {
            line: 22,
            column: 31
          },
          end: {
            line: 28,
            column: 5
          }
        }
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_22dd8a6imc.s[0];
(function () {
  ++cov_22dd8a6imc.f[0];

  var projectListItem = (++cov_22dd8a6imc.s[1], {
    bindings: {
      project: '=',
      roles: '<',
      update: '&'
    },
    templateUrl: 'app/projects/project-list/project-list-item.html',
    controller: projectListItemCtrl
  });

  ++cov_22dd8a6imc.s[2];
  projectListItemCtrl.$inject = [];

  function projectListItemCtrl() {
    ++cov_22dd8a6imc.f[1];

    var self = (++cov_22dd8a6imc.s[3], this);

    ++cov_22dd8a6imc.s[4];
    self.toggleActive = function () {
      ++cov_22dd8a6imc.f[2];
      ++cov_22dd8a6imc.s[5];

      self.update();
    };

    ++cov_22dd8a6imc.s[6];
    self.$onInit = function () {
      // Messages.getByProject(self.project.$id)
      //   .$loaded(messages => {
      //     self.messages = messages;
      //     self.messageCount = messages.length;
      //   });

      ++cov_22dd8a6imc.f[3];
    };

    // self.$onDestroy = () => {
    //   self.messages.$destory();
    // };
  }

  ++cov_22dd8a6imc.s[7];
  angular.module('app').component('projectListItem', projectListItem);
})();