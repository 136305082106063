'use strict';

var cov_2plerv8av3 = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/common/modals/update-app-version.component.js',
      hash = '1a94f99ab953e432ab778c44d13c065c9292aff4',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/common/modals/update-app-version.component.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 31,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 27
        },
        end: {
          line: 10,
          column: 3
        }
      },
      '2': {
        start: {
          line: 13,
          column: 17
        },
        end: {
          line: 13,
          column: 21
        }
      },
      '3': {
        start: {
          line: 15,
          column: 4
        },
        end: {
          line: 19,
          column: 6
        }
      },
      '4': {
        start: {
          line: 16,
          column: 6
        },
        end: {
          line: 18,
          column: 9
        }
      },
      '5': {
        start: {
          line: 21,
          column: 4
        },
        end: {
          line: 25,
          column: 6
        }
      },
      '6': {
        start: {
          line: 22,
          column: 6
        },
        end: {
          line: 24,
          column: 9
        }
      },
      '7': {
        start: {
          line: 28,
          column: 2
        },
        end: {
          line: 30,
          column: 53
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 31,
            column: 1
          }
        }
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 12,
            column: 11
          },
          end: {
            line: 12,
            column: 21
          }
        },
        loc: {
          start: {
            line: 12,
            column: 24
          },
          end: {
            line: 26,
            column: 3
          }
        }
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 15,
            column: 14
          },
          end: {
            line: 15,
            column: 15
          }
        },
        loc: {
          start: {
            line: 15,
            column: 20
          },
          end: {
            line: 19,
            column: 5
          }
        }
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 21,
            column: 18
          },
          end: {
            line: 21,
            column: 19
          }
        },
        loc: {
          start: {
            line: 21,
            column: 24
          },
          end: {
            line: 25,
            column: 5
          }
        }
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_2plerv8av3.s[0];
(function () {
  ++cov_2plerv8av3.f[0];

  var updateAppVersion = (++cov_2plerv8av3.s[1], {
    bindings: {
      close: '&',
      dismiss: '&'
    },
    templateUrl: 'app/common/modals/update-app-version.html',
    controller: controller
  });

  function controller() {
    ++cov_2plerv8av3.f[1];

    var self = (++cov_2plerv8av3.s[2], this);

    ++cov_2plerv8av3.s[3];
    self.ok = function () {
      ++cov_2plerv8av3.f[2];
      ++cov_2plerv8av3.s[4];

      self.close({
        $value: true
      });
    };

    ++cov_2plerv8av3.s[5];
    self.cancel = function () {
      ++cov_2plerv8av3.f[3];
      ++cov_2plerv8av3.s[6];

      self.dismiss({
        $value: 'cancel'
      });
    };
  }

  ++cov_2plerv8av3.s[7];
  angular.module('dc-common').component('updateAppVersion', updateAppVersion);
})();

// $ctrl.openComponentModal = function () {
//   var modalInstance = $uibModal.open({
//     animation: $ctrl.animationsEnabled,
//     component: 'modalComponent',
//     resolve: {
//       items: function () {
//         return $ctrl.items;
//       }
//     }
//   });

//   modalInstance.result.then(function (selectedItem) {
//     $ctrl.selected = selectedItem;
//   }, function () {
//     $log.info('modal-component dismissed at: ' + new Date());
//   });