'use strict';

var cov_2k20v03524 = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/common/customer/customer-list/customer-list.js',
      hash = '8ba4b4b3a609581b931db4ef41b097591ac7b780',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/common/customer/customer-list/customer-list.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 30,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 23
        },
        end: {
          line: 6,
          column: 3
        }
      },
      '2': {
        start: {
          line: 8,
          column: 2
        },
        end: {
          line: 8,
          column: 52
        }
      },
      '3': {
        start: {
          line: 11,
          column: 17
        },
        end: {
          line: 11,
          column: 21
        }
      },
      '4': {
        start: {
          line: 13,
          column: 4
        },
        end: {
          line: 20,
          column: 6
        }
      },
      '5': {
        start: {
          line: 14,
          column: 6
        },
        end: {
          line: 14,
          column: 38
        }
      },
      '6': {
        start: {
          line: 16,
          column: 6
        },
        end: {
          line: 19,
          column: 11
        }
      },
      '7': {
        start: {
          line: 18,
          column: 10
        },
        end: {
          line: 18,
          column: 37
        }
      },
      '8': {
        start: {
          line: 22,
          column: 4
        },
        end: {
          line: 24,
          column: 6
        }
      },
      '9': {
        start: {
          line: 23,
          column: 6
        },
        end: {
          line: 23,
          column: 30
        }
      },
      '10': {
        start: {
          line: 27,
          column: 2
        },
        end: {
          line: 29,
          column: 45
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 30,
            column: 1
          }
        }
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 10,
            column: 11
          },
          end: {
            line: 10,
            column: 21
          }
        },
        loc: {
          start: {
            line: 10,
            column: 46
          },
          end: {
            line: 25,
            column: 3
          }
        }
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 13,
            column: 19
          },
          end: {
            line: 13,
            column: 20
          }
        },
        loc: {
          start: {
            line: 13,
            column: 25
          },
          end: {
            line: 20,
            column: 5
          }
        }
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 17,
            column: 17
          },
          end: {
            line: 17,
            column: 18
          }
        },
        loc: {
          start: {
            line: 17,
            column: 30
          },
          end: {
            line: 19,
            column: 9
          }
        }
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 22,
            column: 22
          },
          end: {
            line: 22,
            column: 23
          }
        },
        loc: {
          start: {
            line: 22,
            column: 28
          },
          end: {
            line: 24,
            column: 5
          }
        }
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_2k20v03524.s[0];
(function () {
  ++cov_2k20v03524.f[0];

  var customerList = (++cov_2k20v03524.s[1], {
    templateUrl: 'app/common/customer/customer-list/customer-list.html',
    controller: controller
  });

  ++cov_2k20v03524.s[2];
  controller.$inject = ['CustomerService', 'Utils'];

  function controller(CustomerService, Utils) {
    ++cov_2k20v03524.f[1];

    var self = (++cov_2k20v03524.s[3], this);

    ++cov_2k20v03524.s[4];
    self.$onInit = function () {
      ++cov_2k20v03524.f[2];
      ++cov_2k20v03524.s[5];

      self.count = Utils.objectLength;

      ++cov_2k20v03524.s[6];
      CustomerService.all().$loaded(function (customers) {
        ++cov_2k20v03524.f[3];
        ++cov_2k20v03524.s[7];

        self.customers = customers;
      });
    };

    ++cov_2k20v03524.s[8];
    self.$onDestroy = function () {
      ++cov_2k20v03524.f[4];
      ++cov_2k20v03524.s[9];

      CustomerService.reset();
    };
  }

  ++cov_2k20v03524.s[10];
  angular.module('dc-customer').component('customerList', customerList);
})();