'use strict';

var cov_13rnucqyqj = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/users/helpers/user-select.component.js',
      hash = '77ed01a3f0502130ff29978b509d42f0e5043c26',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/users/helpers/user-select.component.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 28,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 21
        },
        end: {
          line: 12,
          column: 3
        }
      },
      '2': {
        start: {
          line: 14,
          column: 2
        },
        end: {
          line: 14,
          column: 44
        }
      },
      '3': {
        start: {
          line: 17,
          column: 17
        },
        end: {
          line: 17,
          column: 21
        }
      },
      '4': {
        start: {
          line: 18,
          column: 4
        },
        end: {
          line: 24,
          column: 6
        }
      },
      '5': {
        start: {
          line: 19,
          column: 6
        },
        end: {
          line: 19,
          column: 38
        }
      },
      '6': {
        start: {
          line: 20,
          column: 6
        },
        end: {
          line: 20,
          column: 61
        }
      },
      '7': {
        start: {
          line: 21,
          column: 6
        },
        end: {
          line: 23,
          column: 9
        }
      },
      '8': {
        start: {
          line: 22,
          column: 8
        },
        end: {
          line: 22,
          column: 59
        }
      },
      '9': {
        start: {
          line: 27,
          column: 2
        },
        end: {
          line: 27,
          column: 64
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 28,
            column: 1
          }
        }
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 16,
            column: 11
          },
          end: {
            line: 16,
            column: 21
          }
        },
        loc: {
          start: {
            line: 16,
            column: 38
          },
          end: {
            line: 25,
            column: 3
          }
        }
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 18,
            column: 19
          },
          end: {
            line: 18,
            column: 20
          }
        },
        loc: {
          start: {
            line: 18,
            column: 25
          },
          end: {
            line: 24,
            column: 5
          }
        }
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 21,
            column: 26
          },
          end: {
            line: 21,
            column: 27
          }
        },
        loc: {
          start: {
            line: 21,
            column: 35
          },
          end: {
            line: 23,
            column: 7
          }
        }
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 19,
            column: 20
          },
          end: {
            line: 19,
            column: 37
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 19,
            column: 20
          },
          end: {
            line: 19,
            column: 31
          }
        }, {
          start: {
            line: 19,
            column: 35
          },
          end: {
            line: 19,
            column: 37
          }
        }]
      },
      '1': {
        loc: {
          start: {
            line: 20,
            column: 25
          },
          end: {
            line: 20,
            column: 60
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 20,
            column: 25
          },
          end: {
            line: 20,
            column: 41
          }
        }, {
          start: {
            line: 20,
            column: 45
          },
          end: {
            line: 20,
            column: 60
          }
        }]
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_13rnucqyqj.s[0];
(function () {
  ++cov_13rnucqyqj.f[0];

  var userSelect = (++cov_13rnucqyqj.s[1], {
    bindings: {
      ngModel: '=',
      required: '<',
      filter: '<',
      placeholder: '<'
    },
    templateUrl: 'app/users/helpers/user-select.html',
    controller: controller
  });

  ++cov_13rnucqyqj.s[2];
  controller.$inject = ['Users', '$filter'];

  function controller(Users, $filter) {
    ++cov_13rnucqyqj.f[1];

    var self = (++cov_13rnucqyqj.s[3], this);
    ++cov_13rnucqyqj.s[4];
    self.$onInit = function () {
      ++cov_13rnucqyqj.f[2];
      ++cov_13rnucqyqj.s[5];

      self.filter = (++cov_13rnucqyqj.b[0][0], self.filter) || (++cov_13rnucqyqj.b[0][1], {});
      ++cov_13rnucqyqj.s[6];
      self.placeholder = (++cov_13rnucqyqj.b[1][0], self.placeholder) || (++cov_13rnucqyqj.b[1][1], 'Select a User');
      ++cov_13rnucqyqj.s[7];
      Users.all().$loaded(function (users) {
        ++cov_13rnucqyqj.f[3];
        ++cov_13rnucqyqj.s[8];

        self.users = $filter('filter')(users, self.filter);
      });
    };
  }

  ++cov_13rnucqyqj.s[9];
  angular.module('dc-user').component('userSelect', userSelect);
})();