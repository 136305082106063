'use strict';

var cov_4oxca9pu9 = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/services/products.js',
      hash = 'd1246f5d963e3b97ab1d40e06b5bbd03b1350134',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/services/products.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 51,
          column: 5
        }
      },
      '1': {
        start: {
          line: 5,
          column: 19
        },
        end: {
          line: 5,
          column: 23
        }
      },
      '2': {
        start: {
          line: 8,
          column: 20
        },
        end: {
          line: 14,
          column: 5
        }
      },
      '3': {
        start: {
          line: 16,
          column: 4
        },
        end: {
          line: 16,
          column: 19
        }
      },
      '4': {
        start: {
          line: 20,
          column: 6
        },
        end: {
          line: 20,
          column: 21
        }
      },
      '5': {
        start: {
          line: 24,
          column: 20
        },
        end: {
          line: 24,
          column: 23
        }
      },
      '6': {
        start: {
          line: 25,
          column: 6
        },
        end: {
          line: 27,
          column: 7
        }
      },
      '7': {
        start: {
          line: 26,
          column: 8
        },
        end: {
          line: 26,
          column: 68
        }
      },
      '8': {
        start: {
          line: 28,
          column: 6
        },
        end: {
          line: 28,
          column: 22
        }
      },
      '9': {
        start: {
          line: 32,
          column: 22
        },
        end: {
          line: 32,
          column: 69
        }
      },
      '10': {
        start: {
          line: 33,
          column: 6
        },
        end: {
          line: 33,
          column: 21
        }
      },
      '11': {
        start: {
          line: 37,
          column: 22
        },
        end: {
          line: 37,
          column: 97
        }
      },
      '12': {
        start: {
          line: 38,
          column: 6
        },
        end: {
          line: 38,
          column: 21
        }
      },
      '13': {
        start: {
          line: 42,
          column: 6
        },
        end: {
          line: 45,
          column: 7
        }
      },
      '14': {
        start: {
          line: 43,
          column: 8
        },
        end: {
          line: 43,
          column: 28
        }
      },
      '15': {
        start: {
          line: 44,
          column: 8
        },
        end: {
          line: 44,
          column: 24
        }
      },
      '16': {
        start: {
          line: 49,
          column: 2
        },
        end: {
          line: 50,
          column: 81
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 51,
            column: 1
          }
        }
      },
      '1': {
        name: 'Products',
        decl: {
          start: {
            line: 4,
            column: 11
          },
          end: {
            line: 4,
            column: 19
          }
        },
        loc: {
          start: {
            line: 4,
            column: 58
          },
          end: {
            line: 47,
            column: 3
          }
        }
      },
      '2': {
        name: 'Product',
        decl: {
          start: {
            line: 19,
            column: 13
          },
          end: {
            line: 19,
            column: 20
          }
        },
        loc: {
          start: {
            line: 19,
            column: 23
          },
          end: {
            line: 21,
            column: 5
          }
        }
      },
      '3': {
        name: 'all',
        decl: {
          start: {
            line: 23,
            column: 13
          },
          end: {
            line: 23,
            column: 16
          }
        },
        loc: {
          start: {
            line: 23,
            column: 19
          },
          end: {
            line: 29,
            column: 5
          }
        }
      },
      '4': {
        name: 'getProductByUID',
        decl: {
          start: {
            line: 31,
            column: 13
          },
          end: {
            line: 31,
            column: 28
          }
        },
        loc: {
          start: {
            line: 31,
            column: 41
          },
          end: {
            line: 34,
            column: 5
          }
        }
      },
      '5': {
        name: 'getProductByID',
        decl: {
          start: {
            line: 36,
            column: 13
          },
          end: {
            line: 36,
            column: 27
          }
        },
        loc: {
          start: {
            line: 36,
            column: 39
          },
          end: {
            line: 39,
            column: 5
          }
        }
      },
      '6': {
        name: 'reset',
        decl: {
          start: {
            line: 41,
            column: 13
          },
          end: {
            line: 41,
            column: 18
          }
        },
        loc: {
          start: {
            line: 41,
            column: 21
          },
          end: {
            line: 46,
            column: 5
          }
        }
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 25,
            column: 6
          },
          end: {
            line: 27,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 25,
            column: 6
          },
          end: {
            line: 27,
            column: 7
          }
        }, {
          start: {
            line: 25,
            column: 6
          },
          end: {
            line: 27,
            column: 7
          }
        }]
      },
      '1': {
        loc: {
          start: {
            line: 42,
            column: 6
          },
          end: {
            line: 45,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 42,
            column: 6
          },
          end: {
            line: 45,
            column: 7
          }
        }, {
          start: {
            line: 42,
            column: 6
          },
          end: {
            line: 45,
            column: 7
          }
        }]
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_4oxca9pu9.s[0];
(function () {
  ++cov_4oxca9pu9.f[0];

  // define function Products
  function Products($db, $firebaseArray, $firebaseObject) {
    ++cov_4oxca9pu9.f[1];

    var products = (++cov_4oxca9pu9.s[1], null);

    // service definition
    var service = (++cov_4oxca9pu9.s[2], {
      Product: Product,
      getProductByID: getProductByID,
      getProductByUID: getProductByUID,
      all: all,
      reset: reset
    });

    ++cov_4oxca9pu9.s[3];
    return service;

    // functions
    function Product() {
      ++cov_4oxca9pu9.f[2];
      ++cov_4oxca9pu9.s[4];

      this.name = '';
    }

    function all() {
      ++cov_4oxca9pu9.f[3];

      var limit = (++cov_4oxca9pu9.s[5], 200);
      ++cov_4oxca9pu9.s[6];
      if (!products) {
        ++cov_4oxca9pu9.b[0][0];
        ++cov_4oxca9pu9.s[7];

        products = $firebaseArray($db.products.limitToFirst(limit));
      } else {
        ++cov_4oxca9pu9.b[0][1];
      }
      ++cov_4oxca9pu9.s[8];
      return products;
    }

    function getProductByUID(productUID) {
      ++cov_4oxca9pu9.f[4];

      var product = (++cov_4oxca9pu9.s[9], $firebaseObject($db.products.child(productUID)));
      ++cov_4oxca9pu9.s[10];
      return product;
    }

    function getProductByID(productID) {
      ++cov_4oxca9pu9.f[5];

      var product = (++cov_4oxca9pu9.s[11], $firebaseObject($db.products.orderByChild('itemNumber').equalTo(productID)));
      ++cov_4oxca9pu9.s[12];
      return product;
    }

    function reset() {
      ++cov_4oxca9pu9.f[6];
      ++cov_4oxca9pu9.s[13];

      if (products) {
        ++cov_4oxca9pu9.b[1][0];
        ++cov_4oxca9pu9.s[14];

        products.$destroy();
        ++cov_4oxca9pu9.s[15];
        products = null;
      } else {
        ++cov_4oxca9pu9.b[1][1];
      }
    }
  }

  ++cov_4oxca9pu9.s[16];
  angular.module('app').factory('Products', ['$db', '$firebaseArray', '$firebaseObject', Products]);
})();