'use strict';

var cov_1oxixnxau9 = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/directives/croppie.js',
      hash = '650430fd6bce825b26692ba96fc7a3e183b77acf',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/directives/croppie.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 42,
          column: 5
        }
      },
      '1': {
        start: {
          line: 4,
          column: 4
        },
        end: {
          line: 36,
          column: 6
        }
      },
      '2': {
        start: {
          line: 11,
          column: 8
        },
        end: {
          line: 13,
          column: 9
        }
      },
      '3': {
        start: {
          line: 12,
          column: 10
        },
        end: {
          line: 12,
          column: 17
        }
      },
      '4': {
        start: {
          line: 14,
          column: 18
        },
        end: {
          line: 30,
          column: 10
        }
      },
      '5': {
        start: {
          line: 24,
          column: 12
        },
        end: {
          line: 28,
          column: 15
        }
      },
      '6': {
        start: {
          line: 25,
          column: 14
        },
        end: {
          line: 27,
          column: 17
        }
      },
      '7': {
        start: {
          line: 26,
          column: 16
        },
        end: {
          line: 26,
          column: 36
        }
      },
      '8': {
        start: {
          line: 32,
          column: 8
        },
        end: {
          line: 34,
          column: 11
        }
      },
      '9': {
        start: {
          line: 39,
          column: 2
        },
        end: {
          line: 41,
          column: 39
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 42,
            column: 1
          }
        }
      },
      '1': {
        name: 'dcCroppie',
        decl: {
          start: {
            line: 3,
            column: 11
          },
          end: {
            line: 3,
            column: 20
          }
        },
        loc: {
          start: {
            line: 3,
            column: 23
          },
          end: {
            line: 37,
            column: 3
          }
        }
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 24,
            column: 73
          },
          end: {
            line: 24,
            column: 74
          }
        },
        loc: {
          start: {
            line: 24,
            column: 80
          },
          end: {
            line: 28,
            column: 13
          }
        }
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 25,
            column: 27
          },
          end: {
            line: 25,
            column: 28
          }
        },
        loc: {
          start: {
            line: 25,
            column: 33
          },
          end: {
            line: 27,
            column: 15
          }
        }
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 11,
            column: 8
          },
          end: {
            line: 13,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 11,
            column: 8
          },
          end: {
            line: 13,
            column: 9
          }
        }, {
          start: {
            line: 11,
            column: 8
          },
          end: {
            line: 13,
            column: 9
          }
        }]
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0
    },
    b: {
      '0': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_1oxixnxau9.s[0];
(function () {
  ++cov_1oxixnxau9.f[0];

  function dcCroppie() {
    ++cov_1oxixnxau9.f[1];
    ++cov_1oxixnxau9.s[1];

    return {
      restrict: 'E',
      scope: {
        src: '=',
        ngModel: '='
      },
      link: function link(scope, element) {
        ++cov_1oxixnxau9.s[2];

        if (!scope.src) {
          ++cov_1oxixnxau9.b[0][0];
          ++cov_1oxixnxau9.s[3];

          return;
        } else {
          ++cov_1oxixnxau9.b[0][1];
        }
        var c = (++cov_1oxixnxau9.s[4], new Croppie(element[0], {
          viewport: {
            width: 300,
            height: 300
          },
          boundary: {
            width: 360,
            height: 360
          },
          update: function update() {
            ++cov_1oxixnxau9.s[5];

            c.result('blob', { width: 180, height: 180 }, 'png', 0.8).then(function (img) {
              ++cov_1oxixnxau9.f[2];
              ++cov_1oxixnxau9.s[6];

              scope.$apply(function () {
                ++cov_1oxixnxau9.f[3];
                ++cov_1oxixnxau9.s[7];

                scope.ngModel = img;
              });
            });
          }
        }));

        ++cov_1oxixnxau9.s[8];
        c.bind({
          url: scope.src
        });
      }
    };
  }

  ++cov_1oxixnxau9.s[9];
  angular.module('app').directive('dcCroppie', dcCroppie);
})();