'use strict';

var cov_xyvtyk9w1 = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/products/product.routes.js',
      hash = 'f3159d28e81f371407d9c023da9871aa14ddb2e3',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/products/product.routes.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 20,
          column: 5
        }
      },
      '1': {
        start: {
          line: 4,
          column: 4
        },
        end: {
          line: 14,
          column: 9
        }
      },
      '2': {
        start: {
          line: 17,
          column: 2
        },
        end: {
          line: 19,
          column: 45
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 20,
            column: 1
          }
        }
      },
      '1': {
        name: 'routeConfig',
        decl: {
          start: {
            line: 3,
            column: 11
          },
          end: {
            line: 3,
            column: 22
          }
        },
        loc: {
          start: {
            line: 3,
            column: 39
          },
          end: {
            line: 15,
            column: 3
          }
        }
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0
    },
    f: {
      '0': 0,
      '1': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_xyvtyk9w1.s[0];
(function () {
  ++cov_xyvtyk9w1.f[0];

  function routeConfig($stateProvider) {
    ++cov_xyvtyk9w1.f[1];
    ++cov_xyvtyk9w1.s[1];

    $stateProvider.state('product', {
      redirectTo: 'product.all',
      parent: 'app',
      url: '/products',
      template: '<ui-view autoscroll="true"></ui-view>'
    }).state('product.all', {
      url: '/',
      component: 'productList'
    });
  }

  ++cov_xyvtyk9w1.s[2];
  angular.module('dc-products').config(['$stateProvider', routeConfig]);
})();