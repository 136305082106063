'use strict';

var cov_247zopda4c = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/common/auth/auth.module.js',
      hash = '0dc3433275cf166d57f9e487160d5e34247e7d58',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/common/auth/auth.module.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 7,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 2
        },
        end: {
          line: 6,
          column: 7
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 7,
            column: 1
          }
        }
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0
    },
    f: {
      '0': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_247zopda4c.s[0];
(function () {
  ++cov_247zopda4c.f[0];
  ++cov_247zopda4c.s[1];

  angular.module('dc-auth', ['ui.router']);
})();