'use strict';

var cov_21a01e6w1c = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/service/helpers/ready-to-review.filter.js',
      hash = 'c76bb8e2d68413050e335642cedf59cecf739553',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/service/helpers/ready-to-review.filter.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 19,
          column: 5
        }
      },
      '1': {
        start: {
          line: 4,
          column: 4
        },
        end: {
          line: 16,
          column: 6
        }
      },
      '2': {
        start: {
          line: 5,
          column: 18
        },
        end: {
          line: 5,
          column: 20
        }
      },
      '3': {
        start: {
          line: 6,
          column: 6
        },
        end: {
          line: 14,
          column: 9
        }
      },
      '4': {
        start: {
          line: 7,
          column: 8
        },
        end: {
          line: 10,
          column: 9
        }
      },
      '5': {
        start: {
          line: 8,
          column: 10
        },
        end: {
          line: 8,
          column: 25
        }
      },
      '6': {
        start: {
          line: 9,
          column: 10
        },
        end: {
          line: 9,
          column: 17
        }
      },
      '7': {
        start: {
          line: 11,
          column: 8
        },
        end: {
          line: 13,
          column: 9
        }
      },
      '8': {
        start: {
          line: 12,
          column: 10
        },
        end: {
          line: 12,
          column: 25
        }
      },
      '9': {
        start: {
          line: 15,
          column: 6
        },
        end: {
          line: 15,
          column: 17
        }
      },
      '10': {
        start: {
          line: 18,
          column: 2
        },
        end: {
          line: 18,
          column: 63
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 19,
            column: 1
          }
        }
      },
      '1': {
        name: 'filter',
        decl: {
          start: {
            line: 3,
            column: 11
          },
          end: {
            line: 3,
            column: 17
          }
        },
        loc: {
          start: {
            line: 3,
            column: 20
          },
          end: {
            line: 17,
            column: 3
          }
        }
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 4,
            column: 11
          },
          end: {
            line: 4,
            column: 12
          }
        },
        loc: {
          start: {
            line: 4,
            column: 36
          },
          end: {
            line: 16,
            column: 5
          }
        }
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 6,
            column: 29
          },
          end: {
            line: 6,
            column: 30
          }
        },
        loc: {
          start: {
            line: 6,
            column: 37
          },
          end: {
            line: 14,
            column: 7
          }
        }
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 7,
            column: 8
          },
          end: {
            line: 10,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 7,
            column: 8
          },
          end: {
            line: 10,
            column: 9
          }
        }, {
          start: {
            line: 7,
            column: 8
          },
          end: {
            line: 10,
            column: 9
          }
        }]
      },
      '1': {
        loc: {
          start: {
            line: 11,
            column: 8
          },
          end: {
            line: 13,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 11,
            column: 8
          },
          end: {
            line: 13,
            column: 9
          }
        }, {
          start: {
            line: 11,
            column: 8
          },
          end: {
            line: 13,
            column: 9
          }
        }]
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_21a01e6w1c.s[0];
(function () {
  ++cov_21a01e6w1c.f[0];

  function filter() {
    ++cov_21a01e6w1c.f[1];
    ++cov_21a01e6w1c.s[1];

    return function (array, exists) {
      ++cov_21a01e6w1c.f[2];

      var out = (++cov_21a01e6w1c.s[2], []);
      ++cov_21a01e6w1c.s[3];
      angular.forEach(array, function (item) {
        ++cov_21a01e6w1c.f[3];
        ++cov_21a01e6w1c.s[4];

        if (angular.isUndefined(exists)) {
          ++cov_21a01e6w1c.b[0][0];
          ++cov_21a01e6w1c.s[5];

          out.push(item);
          ++cov_21a01e6w1c.s[6];
          return;
        } else {
          ++cov_21a01e6w1c.b[0][1];
        }
        ++cov_21a01e6w1c.s[7];
        if (angular.isDefined(item.signature)) {
          ++cov_21a01e6w1c.b[1][0];
          ++cov_21a01e6w1c.s[8];

          out.push(item);
        } else {
          ++cov_21a01e6w1c.b[1][1];
        }
      });
      ++cov_21a01e6w1c.s[9];
      return out;
    };
  }
  ++cov_21a01e6w1c.s[10];
  angular.module('dc-service').filter('readyToReview', filter);
})();