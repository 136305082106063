'use strict';

var cov_1ixfo64tiz = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/common/customer/sites/create-site/create-site.component.js',
      hash = 'ef136b168154613b12b706b654c38a791bad06a0',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/common/customer/sites/create-site/create-site.component.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 49,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 20
        },
        end: {
          line: 11,
          column: 3
        }
      },
      '2': {
        start: {
          line: 13,
          column: 2
        },
        end: {
          line: 13,
          column: 39
        }
      },
      '3': {
        start: {
          line: 16,
          column: 17
        },
        end: {
          line: 16,
          column: 21
        }
      },
      '4': {
        start: {
          line: 18,
          column: 4
        },
        end: {
          line: 22,
          column: 6
        }
      },
      '5': {
        start: {
          line: 19,
          column: 6
        },
        end: {
          line: 21,
          column: 9
        }
      },
      '6': {
        start: {
          line: 24,
          column: 4
        },
        end: {
          line: 32,
          column: 6
        }
      },
      '7': {
        start: {
          line: 25,
          column: 6
        },
        end: {
          line: 31,
          column: 11
        }
      },
      '8': {
        start: {
          line: 27,
          column: 10
        },
        end: {
          line: 29,
          column: 13
        }
      },
      '9': {
        start: {
          line: 30,
          column: 10
        },
        end: {
          line: 30,
          column: 25
        }
      },
      '10': {
        start: {
          line: 34,
          column: 4
        },
        end: {
          line: 39,
          column: 6
        }
      },
      '11': {
        start: {
          line: 35,
          column: 6
        },
        end: {
          line: 35,
          column: 21
        }
      },
      '12': {
        start: {
          line: 36,
          column: 6
        },
        end: {
          line: 38,
          column: 7
        }
      },
      '13': {
        start: {
          line: 37,
          column: 8
        },
        end: {
          line: 37,
          column: 53
        }
      },
      '14': {
        start: {
          line: 41,
          column: 4
        },
        end: {
          line: 43,
          column: 6
        }
      },
      '15': {
        start: {
          line: 42,
          column: 6
        },
        end: {
          line: 42,
          column: 21
        }
      },
      '16': {
        start: {
          line: 46,
          column: 2
        },
        end: {
          line: 48,
          column: 40
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 49,
            column: 1
          }
        }
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 15,
            column: 11
          },
          end: {
            line: 15,
            column: 21
          }
        },
        loc: {
          start: {
            line: 15,
            column: 35
          },
          end: {
            line: 44,
            column: 3
          }
        }
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 18,
            column: 18
          },
          end: {
            line: 18,
            column: 19
          }
        },
        loc: {
          start: {
            line: 18,
            column: 24
          },
          end: {
            line: 22,
            column: 5
          }
        }
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 24,
            column: 18
          },
          end: {
            line: 24,
            column: 19
          }
        },
        loc: {
          start: {
            line: 24,
            column: 24
          },
          end: {
            line: 32,
            column: 5
          }
        }
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 26,
            column: 14
          },
          end: {
            line: 26,
            column: 15
          }
        },
        loc: {
          start: {
            line: 26,
            column: 21
          },
          end: {
            line: 31,
            column: 9
          }
        }
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 34,
            column: 19
          },
          end: {
            line: 34,
            column: 20
          }
        },
        loc: {
          start: {
            line: 34,
            column: 25
          },
          end: {
            line: 39,
            column: 5
          }
        }
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 41,
            column: 22
          },
          end: {
            line: 41,
            column: 23
          }
        },
        loc: {
          start: {
            line: 41,
            column: 28
          },
          end: {
            line: 43,
            column: 5
          }
        }
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 36,
            column: 6
          },
          end: {
            line: 38,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 36,
            column: 6
          },
          end: {
            line: 38,
            column: 7
          }
        }, {
          start: {
            line: 36,
            column: 6
          },
          end: {
            line: 38,
            column: 7
          }
        }]
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0
    },
    b: {
      '0': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_1ixfo64tiz.s[0];
(function () {
  ++cov_1ixfo64tiz.f[0];

  var component = (++cov_1ixfo64tiz.s[1], {
    bindings: {
      close: '&',
      dismiss: '&',
      resolve: '<'
    },
    templateUrl: 'app/common/customer/sites/create-site/create-site.html',
    controller: controller
  });

  ++cov_1ixfo64tiz.s[2];
  controller.$inject = ['SiteService'];

  function controller(SiteService) {
    ++cov_1ixfo64tiz.f[1];

    var self = (++cov_1ixfo64tiz.s[3], this);

    ++cov_1ixfo64tiz.s[4];
    self.cancel = function () {
      ++cov_1ixfo64tiz.f[2];
      ++cov_1ixfo64tiz.s[5];

      self.dismiss({
        $value: 'cancel'
      });
    };

    ++cov_1ixfo64tiz.s[6];
    self.create = function () {
      ++cov_1ixfo64tiz.f[3];
      ++cov_1ixfo64tiz.s[7];

      SiteService.create(self.site).then(function (uid) {
        ++cov_1ixfo64tiz.f[4];
        ++cov_1ixfo64tiz.s[8];

        self.close({
          $value: uid
        });
        ++cov_1ixfo64tiz.s[9];
        self.site = {};
      });
    };

    ++cov_1ixfo64tiz.s[10];
    self.$onInit = function () {
      ++cov_1ixfo64tiz.f[5];
      ++cov_1ixfo64tiz.s[11];

      self.site = {};
      ++cov_1ixfo64tiz.s[12];
      if (self.resolve.customerId) {
        ++cov_1ixfo64tiz.b[0][0];
        ++cov_1ixfo64tiz.s[13];

        self.site.customer = self.resolve.customerId;
      } else {
        ++cov_1ixfo64tiz.b[0][1];
      }
    };

    ++cov_1ixfo64tiz.s[14];
    self.$onDestroy = function () {
      ++cov_1ixfo64tiz.f[6];
      ++cov_1ixfo64tiz.s[15];

      self.site = {};
    };
  }

  ++cov_1ixfo64tiz.s[16];
  angular.module('dc-customer').component('createSite', component);
})();