'use strict';

var cov_1sc7wut5e2 = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/common/helpers/start-from.filter.js',
      hash = '200977d4c40f1b4517616410cd6050bbb12621b0',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/common/helpers/start-from.filter.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 10,
          column: 5
        }
      },
      '1': {
        start: {
          line: 4,
          column: 4
        },
        end: {
          line: 7,
          column: 6
        }
      },
      '2': {
        start: {
          line: 5,
          column: 6
        },
        end: {
          line: 5,
          column: 28
        }
      },
      '3': {
        start: {
          line: 6,
          column: 6
        },
        end: {
          line: 6,
          column: 32
        }
      },
      '4': {
        start: {
          line: 9,
          column: 2
        },
        end: {
          line: 9,
          column: 58
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 10,
            column: 1
          }
        }
      },
      '1': {
        name: 'filter',
        decl: {
          start: {
            line: 3,
            column: 11
          },
          end: {
            line: 3,
            column: 17
          }
        },
        loc: {
          start: {
            line: 3,
            column: 20
          },
          end: {
            line: 8,
            column: 3
          }
        }
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 4,
            column: 11
          },
          end: {
            line: 4,
            column: 12
          }
        },
        loc: {
          start: {
            line: 4,
            column: 35
          },
          end: {
            line: 7,
            column: 5
          }
        }
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_1sc7wut5e2.s[0];
(function () {
  ++cov_1sc7wut5e2.f[0];

  function filter() {
    ++cov_1sc7wut5e2.f[1];
    ++cov_1sc7wut5e2.s[1];

    return function (input, start) {
      ++cov_1sc7wut5e2.f[2];
      ++cov_1sc7wut5e2.s[2];

      start = Number(start); // parse to int
      ++cov_1sc7wut5e2.s[3];
      return input.slice(start);
    };
  }
  ++cov_1sc7wut5e2.s[4];
  angular.module('dc-common').filter('startFrom', filter);
})();