'use strict';

var cov_2kj30ai63m = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/common/comment/comments.component.js',
      hash = '7b280c85262122d25101e89e4654067799f74015',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/common/comment/comments.component.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 17,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 19
        },
        end: {
          line: 12,
          column: 3
        }
      },
      '2': {
        start: {
          line: 14,
          column: 2
        },
        end: {
          line: 16,
          column: 37
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 17,
            column: 1
          }
        }
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0
    },
    f: {
      '0': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_2kj30ai63m.s[0];
(function () {
  ++cov_2kj30ai63m.f[0];

  var comments = (++cov_2kj30ai63m.s[1], {
    bindings: {
      projectId: '<?',
      ticketId: '<?'
    },
    template: '\n    <post-comment project-id="$ctrl.projectId" ticket-id="$ctrl.ticketId"></post-comment>\n    <comment-list project-id="$ctrl.projectId" ticket-id="$ctrl.ticketId"></comment-list>\n    '
  });

  ++cov_2kj30ai63m.s[2];
  angular.module('dc-comments').component('comments', comments);
})();