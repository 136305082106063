'use strict';

var cov_lg5tpnyo7 = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/common/activity/activity.routes.js',
      hash = 'e6cb2be528a45012dbf8d8018398d40175b49cdb',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/common/activity/activity.routes.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 15,
          column: 5
        }
      },
      '1': {
        start: {
          line: 4,
          column: 4
        },
        end: {
          line: 9,
          column: 9
        }
      },
      '2': {
        start: {
          line: 12,
          column: 2
        },
        end: {
          line: 14,
          column: 45
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 15,
            column: 1
          }
        }
      },
      '1': {
        name: 'routeConfig',
        decl: {
          start: {
            line: 3,
            column: 11
          },
          end: {
            line: 3,
            column: 22
          }
        },
        loc: {
          start: {
            line: 3,
            column: 39
          },
          end: {
            line: 10,
            column: 3
          }
        }
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0
    },
    f: {
      '0': 0,
      '1': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_lg5tpnyo7.s[0];
(function () {
  ++cov_lg5tpnyo7.f[0];

  function routeConfig($stateProvider) {
    ++cov_lg5tpnyo7.f[1];
    ++cov_lg5tpnyo7.s[1];

    $stateProvider.state('activities', {
      parent: 'app',
      url: '/activities',
      component: 'dcActivityLog'
    });
  }

  ++cov_lg5tpnyo7.s[2];
  angular.module('dc-activity').config(['$stateProvider', routeConfig]);
})();