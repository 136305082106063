// 'use strict';
// (function () {
//   const userSchedule = {
//     templateUrl: 'app/users/schedule/schedule.html',
//     controller
//   };

//   controller.$inject = ['Users', '$log', 'Utils', '$document'];

//   function controller(Users, $log, Utils, $document) {
//     const self = this;

//     self.$onInit = () => {
//       self.users = Users.all().$loaded().then(users => {
//         const container = $document.getElementById('visualization');
//         const data = [];
//         const options = {
//           // height: '400px',
//         };
//         let id = 0;
//         angular.forEach(users, user => {
//           data.push({id, content: user.profile.username, start: user.created, end: Utils.now()});
//           id++;
//         });
//         const timeline = new vis.Timeline(container, data, options);
//       });
//       self.filter = '';

//       // const data = [
//       //   {id: 1, content: 'item 1', start: '2013-04-20'},
//       //   {id: 2, content: 'item 2', start: '2013-04-14'},
//       //   {id: 3, content: 'item 3', start: '2013-04-18'},
//       //   {id: 4, content: 'item 4', start: '2013-04-16', end: '2013-04-19'},
//       //   {id: 5, content: 'item 5', start: '2013-04-25'},
//       //   {id: 6, content: 'item 6', start: '2013-04-27'}
//       // ];
//     };
//   }

//   angular
//     .module('dc-user')
//     .component('userSchedule', userSchedule);
// })();
"use strict";

var cov_14er4e7whq = function () {
  var path = "/Users/ryanluton/Developer/divcon/divcon-tools/src/app/users/schedule/schedule.component.js",
      hash = "e75e478ab6e5033534fdcf6b134bfc971d3b1045",
      global = new Function('return this')(),
      gcv = "__coverage__",
      coverageData = {
    path: "/Users/ryanluton/Developer/divcon/divcon-tools/src/app/users/schedule/schedule.component.js",
    statementMap: {},
    fnMap: {},
    branchMap: {},
    s: {},
    f: {},
    b: {},
    _coverageSchema: "332fd63041d2c1bcb487cc26dd0d5f7d97098a6c"
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();