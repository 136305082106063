'use strict';

var cov_26w8nxbb80 = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/common/customer/create-customer/create-customer.js',
      hash = 'b9b8ba6ba4f394c90e0248df1e7f623351a2213f',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/common/customer/create-customer/create-customer.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 55,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 25
        },
        end: {
          line: 10,
          column: 3
        }
      },
      '2': {
        start: {
          line: 12,
          column: 2
        },
        end: {
          line: 12,
          column: 61
        }
      },
      '3': {
        start: {
          line: 15,
          column: 17
        },
        end: {
          line: 15,
          column: 21
        }
      },
      '4': {
        start: {
          line: 17,
          column: 4
        },
        end: {
          line: 21,
          column: 6
        }
      },
      '5': {
        start: {
          line: 18,
          column: 6
        },
        end: {
          line: 20,
          column: 9
        }
      },
      '6': {
        start: {
          line: 23,
          column: 4
        },
        end: {
          line: 41,
          column: 6
        }
      },
      '7': {
        start: {
          line: 24,
          column: 23
        },
        end: {
          line: 24,
          column: 27
        }
      },
      '8': {
        start: {
          line: 25,
          column: 22
        },
        end: {
          line: 25,
          column: 26
        }
      },
      '9': {
        start: {
          line: 26,
          column: 6
        },
        end: {
          line: 40,
          column: 11
        }
      },
      '10': {
        start: {
          line: 28,
          column: 10
        },
        end: {
          line: 28,
          column: 27
        }
      },
      '11': {
        start: {
          line: 29,
          column: 10
        },
        end: {
          line: 29,
          column: 45
        }
      },
      '12': {
        start: {
          line: 30,
          column: 10
        },
        end: {
          line: 30,
          column: 53
        }
      },
      '13': {
        start: {
          line: 32,
          column: 10
        },
        end: {
          line: 32,
          column: 26
        }
      },
      '14': {
        start: {
          line: 33,
          column: 10
        },
        end: {
          line: 38,
          column: 13
        }
      },
      '15': {
        start: {
          line: 39,
          column: 10
        },
        end: {
          line: 39,
          column: 29
        }
      },
      '16': {
        start: {
          line: 43,
          column: 4
        },
        end: {
          line: 45,
          column: 6
        }
      },
      '17': {
        start: {
          line: 44,
          column: 6
        },
        end: {
          line: 44,
          column: 25
        }
      },
      '18': {
        start: {
          line: 47,
          column: 4
        },
        end: {
          line: 49,
          column: 6
        }
      },
      '19': {
        start: {
          line: 48,
          column: 6
        },
        end: {
          line: 48,
          column: 25
        }
      },
      '20': {
        start: {
          line: 52,
          column: 2
        },
        end: {
          line: 54,
          column: 49
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 55,
            column: 1
          }
        }
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 14,
            column: 11
          },
          end: {
            line: 14,
            column: 21
          }
        },
        loc: {
          start: {
            line: 14,
            column: 55
          },
          end: {
            line: 50,
            column: 3
          }
        }
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 17,
            column: 18
          },
          end: {
            line: 17,
            column: 19
          }
        },
        loc: {
          start: {
            line: 17,
            column: 24
          },
          end: {
            line: 21,
            column: 5
          }
        }
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 23,
            column: 26
          },
          end: {
            line: 23,
            column: 27
          }
        },
        loc: {
          start: {
            line: 23,
            column: 32
          },
          end: {
            line: 41,
            column: 5
          }
        }
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 27,
            column: 14
          },
          end: {
            line: 27,
            column: 15
          }
        },
        loc: {
          start: {
            line: 27,
            column: 21
          },
          end: {
            line: 31,
            column: 9
          }
        }
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 31,
            column: 16
          },
          end: {
            line: 31,
            column: 17
          }
        },
        loc: {
          start: {
            line: 31,
            column: 23
          },
          end: {
            line: 40,
            column: 9
          }
        }
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 43,
            column: 19
          },
          end: {
            line: 43,
            column: 20
          }
        },
        loc: {
          start: {
            line: 43,
            column: 25
          },
          end: {
            line: 45,
            column: 5
          }
        }
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 47,
            column: 22
          },
          end: {
            line: 47,
            column: 23
          }
        },
        loc: {
          start: {
            line: 47,
            column: 28
          },
          end: {
            line: 49,
            column: 5
          }
        }
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_26w8nxbb80.s[0];
(function () {
  ++cov_26w8nxbb80.f[0];

  var createCustomer = (++cov_26w8nxbb80.s[1], {
    bindings: {
      close: '&',
      dismiss: '&'
    },
    templateUrl: 'app/common/customer/create-customer/create-customer.html',
    controller: controller
  });

  ++cov_26w8nxbb80.s[2];
  controller.$inject = ['CustomerService', 'ContactService'];

  function controller(CustomerService, ContactService) {
    ++cov_26w8nxbb80.f[1];

    var self = (++cov_26w8nxbb80.s[3], this);

    ++cov_26w8nxbb80.s[4];
    self.cancel = function () {
      ++cov_26w8nxbb80.f[2];
      ++cov_26w8nxbb80.s[5];

      self.dismiss({
        $value: 'cancel'
      });
    };

    ++cov_26w8nxbb80.s[6];
    self.createCustomer = function () {
      ++cov_26w8nxbb80.f[3];

      var customerId = (++cov_26w8nxbb80.s[7], null);
      var contactId = (++cov_26w8nxbb80.s[8], null);
      ++cov_26w8nxbb80.s[9];
      CustomerService.create(self.customer).then(function (uid) {
        ++cov_26w8nxbb80.f[4];
        ++cov_26w8nxbb80.s[10];

        customerId = uid;
        ++cov_26w8nxbb80.s[11];
        self.contact.customer = customerId;
        ++cov_26w8nxbb80.s[12];
        return ContactService.create(self.contact);
      }).then(function (uid) {
        ++cov_26w8nxbb80.f[5];
        ++cov_26w8nxbb80.s[13];

        contactId = uid;
        ++cov_26w8nxbb80.s[14];
        self.close({
          $value: {
            contactId: contactId,
            customerId: customerId
          }
        });
        ++cov_26w8nxbb80.s[15];
        self.customer = {};
      });
    };

    ++cov_26w8nxbb80.s[16];
    self.$onInit = function () {
      ++cov_26w8nxbb80.f[6];
      ++cov_26w8nxbb80.s[17];

      self.customer = {};
    };

    ++cov_26w8nxbb80.s[18];
    self.$onDestroy = function () {
      ++cov_26w8nxbb80.f[7];
      ++cov_26w8nxbb80.s[19];

      self.customer = {};
    };
  }

  ++cov_26w8nxbb80.s[20];
  angular.module('dc-customer').component('createCustomer', createCustomer);
})();