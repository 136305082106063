'use strict';

var cov_1o22qzlw8r = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/services/extends/projects.js',
      hash = '92fed8995cc2be56ac96ccd15827b3a04fd28623',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/services/extends/projects.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 54,
          column: 5
        }
      },
      '1': {
        start: {
          line: 5,
          column: 21
        },
        end: {
          line: 44,
          column: 6
        }
      },
      '2': {
        start: {
          line: 14,
          column: 25
        },
        end: {
          line: 14,
          column: 35
        }
      },
      '3': {
        start: {
          line: 15,
          column: 8
        },
        end: {
          line: 17,
          column: 9
        }
      },
      '4': {
        start: {
          line: 16,
          column: 10
        },
        end: {
          line: 16,
          column: 51
        }
      },
      '5': {
        start: {
          line: 18,
          column: 8
        },
        end: {
          line: 25,
          column: 13
        }
      },
      '6': {
        start: {
          line: 20,
          column: 12
        },
        end: {
          line: 22,
          column: 13
        }
      },
      '7': {
        start: {
          line: 21,
          column: 14
        },
        end: {
          line: 21,
          column: 53
        }
      },
      '8': {
        start: {
          line: 24,
          column: 12
        },
        end: {
          line: 24,
          column: 59
        }
      },
      '9': {
        start: {
          line: 24,
          column: 32
        },
        end: {
          line: 24,
          column: 57
        }
      },
      '10': {
        start: {
          line: 26,
          column: 8
        },
        end: {
          line: 26,
          column: 32
        }
      },
      '11': {
        start: {
          line: 31,
          column: 8
        },
        end: {
          line: 31,
          column: 27
        }
      },
      '12': {
        start: {
          line: 35,
          column: 20
        },
        end: {
          line: 35,
          column: 21
        }
      },
      '13': {
        start: {
          line: 36,
          column: 8
        },
        end: {
          line: 36,
          column: 23
        }
      },
      '14': {
        start: {
          line: 38,
          column: 8
        },
        end: {
          line: 41,
          column: 11
        }
      },
      '15': {
        start: {
          line: 39,
          column: 10
        },
        end: {
          line: 39,
          column: 30
        }
      },
      '16': {
        start: {
          line: 40,
          column: 10
        },
        end: {
          line: 40,
          column: 21
        }
      },
      '17': {
        start: {
          line: 42,
          column: 8
        },
        end: {
          line: 42,
          column: 21
        }
      },
      '18': {
        start: {
          line: 46,
          column: 4
        },
        end: {
          line: 49,
          column: 6
        }
      },
      '19': {
        start: {
          line: 48,
          column: 6
        },
        end: {
          line: 48,
          column: 35
        }
      },
      '20': {
        start: {
          line: 52,
          column: 2
        },
        end: {
          line: 53,
          column: 71
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 54,
            column: 1
          }
        }
      },
      '1': {
        name: '$projects',
        decl: {
          start: {
            line: 4,
            column: 11
          },
          end: {
            line: 4,
            column: 20
          }
        },
        loc: {
          start: {
            line: 4,
            column: 47
          },
          end: {
            line: 50,
            column: 3
          }
        }
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 19,
            column: 25
          },
          end: {
            line: 19,
            column: 26
          }
        },
        loc: {
          start: {
            line: 19,
            column: 33
          },
          end: {
            line: 25,
            column: 11
          }
        }
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 24,
            column: 25
          },
          end: {
            line: 24,
            column: 26
          }
        },
        loc: {
          start: {
            line: 24,
            column: 32
          },
          end: {
            line: 24,
            column: 57
          }
        }
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 38,
            column: 36
          },
          end: {
            line: 38,
            column: 37
          }
        },
        loc: {
          start: {
            line: 38,
            column: 43
          },
          end: {
            line: 41,
            column: 9
          }
        }
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 46,
            column: 11
          },
          end: {
            line: 46,
            column: 12
          }
        },
        loc: {
          start: {
            line: 46,
            column: 30
          },
          end: {
            line: 49,
            column: 5
          }
        }
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 15,
            column: 8
          },
          end: {
            line: 17,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 15,
            column: 8
          },
          end: {
            line: 17,
            column: 9
          }
        }, {
          start: {
            line: 15,
            column: 8
          },
          end: {
            line: 17,
            column: 9
          }
        }]
      },
      '1': {
        loc: {
          start: {
            line: 20,
            column: 12
          },
          end: {
            line: 22,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 20,
            column: 12
          },
          end: {
            line: 22,
            column: 13
          }
        }, {
          start: {
            line: 20,
            column: 12
          },
          end: {
            line: 22,
            column: 13
          }
        }]
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_1o22qzlw8r.s[0];
(function () {
  ++cov_1o22qzlw8r.f[0];

  // defines function Projects
  function $projects($firebaseArray, $log, $q) {
    ++cov_1o22qzlw8r.f[1];

    var Projects = (++cov_1o22qzlw8r.s[1], $firebaseArray.$extend({
      // $$added: function (snap) {
      //   const project = snap.val();
      //   const key = snap.key();
      //   project.uid = key;
      //   $log(project.uid);
      //   return project;
      // },
      findById: function findById(id) {
        var deferred = (++cov_1o22qzlw8r.s[2], $q.defer());
        ++cov_1o22qzlw8r.s[3];
        if (!id) {
          ++cov_1o22qzlw8r.b[0][0];
          ++cov_1o22qzlw8r.s[4];

          deferred.reject('projectId is required');
        } else {
          ++cov_1o22qzlw8r.b[0][1];
        }
        ++cov_1o22qzlw8r.s[5];
        this.$ref().orderByChild('number').equalTo(id).limitToFirst(1).once('value', function (snap) {
          ++cov_1o22qzlw8r.f[2];
          ++cov_1o22qzlw8r.s[6];

          if (!snap.val()) {
            ++cov_1o22qzlw8r.b[1][0];
            ++cov_1o22qzlw8r.s[7];

            deferred.reject('projectId not found');
          } else {
            ++cov_1o22qzlw8r.b[1][1];
          }
          // should only be one at this point. (limited query for safe keeping)
          ++cov_1o22qzlw8r.s[8];
          snap.forEach(function (obj) {
            ++cov_1o22qzlw8r.f[3];
            ++cov_1o22qzlw8r.s[9];
            return deferred.resolve(obj.key);
          });
        });
        ++cov_1o22qzlw8r.s[10];
        return deferred.promise;
      },

      /* eslint-disable angular/no-private-call */
      $$updated: function $$updated(snapshot) {
        ++cov_1o22qzlw8r.s[11];

        /* eslint-enable angular/no-private-call */
        $log.log(snapshot);
        // $log.log('updated snap');
      },
      getTotal: function getTotal() {
        var total = (++cov_1o22qzlw8r.s[12], 0);
        ++cov_1o22qzlw8r.s[13];
        $log.log(this);
        // the array data is located in this.$list
        ++cov_1o22qzlw8r.s[14];
        angular.forEach(this.$list, function (rec) {
          ++cov_1o22qzlw8r.f[4];
          ++cov_1o22qzlw8r.s[15];

          $log.log(rec.val());
          ++cov_1o22qzlw8r.s[16];
          total += 1;
        });
        ++cov_1o22qzlw8r.s[17];
        return total;
      }
    }));

    ++cov_1o22qzlw8r.s[18];
    return function (listRef) {
      ++cov_1o22qzlw8r.f[5];
      ++cov_1o22qzlw8r.s[19];

      // create an instance of Projects
      return new Projects(listRef);
    };
  }

  ++cov_1o22qzlw8r.s[20];
  angular.module('app').factory('$projects', ['$firebaseArray', '$log', '$q', $projects]);
})();

// app.factory('SeriesArrayFactory', function ($firebaseArray, $q) {
//   return $firebaseArray.$extend({
//     findSeries: function (seriesName) {
//       var deferred = $q.defer();
//       if (seriesName.isEmpty()) {
//         deferred.reject("seriesName is empty.");
//       }
//       this.$ref().orderByChild("name").equalTo(seriesName).once("value", function (dataSnapshot) {
//         if (dataSnapshot.val()) {
//           deferred.resolve(dataSnapshot.val());
//         } else {
//           deferred.reject("Not found.");
//         }
//       });
//       return deferred.promise;
//     }
//   });
// });