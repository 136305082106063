'use strict';

var cov_1qg8ol8hm7 = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/components/project-helpers/project-number.js',
      hash = '3ea7574a6157c7faf403e79c273d78bbdf17f20e',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/components/project-helpers/project-number.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 44,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 24
        },
        end: {
          line: 12,
          column: 3
        }
      },
      '2': {
        start: {
          line: 14,
          column: 2
        },
        end: {
          line: 14,
          column: 53
        }
      },
      '3': {
        start: {
          line: 17,
          column: 17
        },
        end: {
          line: 17,
          column: 21
        }
      },
      '4': {
        start: {
          line: 22,
          column: 4
        },
        end: {
          line: 29,
          column: 6
        }
      },
      '5': {
        start: {
          line: 23,
          column: 6
        },
        end: {
          line: 28,
          column: 7
        }
      },
      '6': {
        start: {
          line: 24,
          column: 8
        },
        end: {
          line: 27,
          column: 13
        }
      },
      '7': {
        start: {
          line: 26,
          column: 12
        },
        end: {
          line: 26,
          column: 42
        }
      },
      '8': {
        start: {
          line: 31,
          column: 4
        },
        end: {
          line: 35,
          column: 6
        }
      },
      '9': {
        start: {
          line: 32,
          column: 6
        },
        end: {
          line: 34,
          column: 7
        }
      },
      '10': {
        start: {
          line: 33,
          column: 8
        },
        end: {
          line: 33,
          column: 29
        }
      },
      '11': {
        start: {
          line: 41,
          column: 2
        },
        end: {
          line: 43,
          column: 47
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 44,
            column: 1
          }
        }
      },
      '1': {
        name: 'projectNumberCtrl',
        decl: {
          start: {
            line: 16,
            column: 11
          },
          end: {
            line: 16,
            column: 28
          }
        },
        loc: {
          start: {
            line: 16,
            column: 47
          },
          end: {
            line: 39,
            column: 3
          }
        }
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 22,
            column: 22
          },
          end: {
            line: 22,
            column: 23
          }
        },
        loc: {
          start: {
            line: 22,
            column: 38
          },
          end: {
            line: 29,
            column: 5
          }
        }
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 25,
            column: 16
          },
          end: {
            line: 25,
            column: 17
          }
        },
        loc: {
          start: {
            line: 25,
            column: 28
          },
          end: {
            line: 27,
            column: 11
          }
        }
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 31,
            column: 21
          },
          end: {
            line: 31,
            column: 22
          }
        },
        loc: {
          start: {
            line: 31,
            column: 27
          },
          end: {
            line: 35,
            column: 5
          }
        }
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 23,
            column: 6
          },
          end: {
            line: 28,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 23,
            column: 6
          },
          end: {
            line: 28,
            column: 7
          }
        }, {
          start: {
            line: 23,
            column: 6
          },
          end: {
            line: 28,
            column: 7
          }
        }]
      },
      '1': {
        loc: {
          start: {
            line: 23,
            column: 10
          },
          end: {
            line: 23,
            column: 54
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 23,
            column: 10
          },
          end: {
            line: 23,
            column: 23
          }
        }, {
          start: {
            line: 23,
            column: 27
          },
          end: {
            line: 23,
            column: 54
          }
        }]
      },
      '2': {
        loc: {
          start: {
            line: 32,
            column: 6
          },
          end: {
            line: 34,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 32,
            column: 6
          },
          end: {
            line: 34,
            column: 7
          }
        }, {
          start: {
            line: 32,
            column: 6
          },
          end: {
            line: 34,
            column: 7
          }
        }]
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_1qg8ol8hm7.s[0];
(function () {
  ++cov_1qg8ol8hm7.f[0];

  var projectNumber = (++cov_1qg8ol8hm7.s[1], {
    bindings: {
      uid: '<'
    },
    template: '\n      <span ng-if="$ctrl.number && !$ctrl.showLink">#{{$ctrl.number}}</span>\n      <a ui-sref="app.profile.main({username: $ctrl.username})" ng-if="$ctrl.username && $ctrl.showLink"><span class="username">@{{$ctrl.username}}</span>\n    ',
    controller: projectNumberCtrl
  });

  ++cov_1qg8ol8hm7.s[2];
  projectNumberCtrl.$inject = ['Projects', '$attrs'];

  function projectNumberCtrl(Projects, $attrs) {
    ++cov_1qg8ol8hm7.f[1];

    var self = (++cov_1qg8ol8hm7.s[3], this);
    // $onInit
    // self.$onInit = () => {
    // };
    // $onChanges
    ++cov_1qg8ol8hm7.s[4];
    self.$onChanges = function (prev, next) {
      ++cov_1qg8ol8hm7.f[2];
      ++cov_1qg8ol8hm7.s[5];

      if ((++cov_1qg8ol8hm7.b[1][0], next !== prev) && (++cov_1qg8ol8hm7.b[1][1], angular.isDefined(self.uid))) {
        ++cov_1qg8ol8hm7.b[0][0];
        ++cov_1qg8ol8hm7.s[6];

        Projects.$getProjectMetadata(self.uid).then(function (metadata) {
          ++cov_1qg8ol8hm7.f[3];
          ++cov_1qg8ol8hm7.s[7];

          self.number = metadata.number;
        });
      } else {
        ++cov_1qg8ol8hm7.b[0][1];
      }
    };
    // $postLink
    ++cov_1qg8ol8hm7.s[8];
    self.$postLink = function () {
      ++cov_1qg8ol8hm7.f[4];
      ++cov_1qg8ol8hm7.s[9];

      if ('withLink' in $attrs) {
        ++cov_1qg8ol8hm7.b[2][0];
        ++cov_1qg8ol8hm7.s[10];

        self.showLink = true;
      } else {
        ++cov_1qg8ol8hm7.b[2][1];
      }
    };
    // $onDestroy
    // self.$onDestroy = () => {
    // };
  }

  ++cov_1qg8ol8hm7.s[11];
  angular.module('app').component('projectNumber', projectNumber);
})();