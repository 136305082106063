'use strict';

var cov_1jim3mx4q0 = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/services/utils.js',
      hash = 'dd5725ced8ca8160b9d907b142a84fde6bb82693',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/services/utils.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 27,
          column: 5
        }
      },
      '1': {
        start: {
          line: 5,
          column: 4
        },
        end: {
          line: 8,
          column: 6
        }
      },
      '2': {
        start: {
          line: 12,
          column: 16
        },
        end: {
          line: 12,
          column: 55
        }
      },
      '3': {
        start: {
          line: 13,
          column: 6
        },
        end: {
          line: 13,
          column: 15
        }
      },
      '4': {
        start: {
          line: 17,
          column: 6
        },
        end: {
          line: 19,
          column: 7
        }
      },
      '5': {
        start: {
          line: 18,
          column: 8
        },
        end: {
          line: 18,
          column: 17
        }
      },
      '6': {
        start: {
          line: 20,
          column: 6
        },
        end: {
          line: 20,
          column: 37
        }
      },
      '7': {
        start: {
          line: 24,
          column: 2
        },
        end: {
          line: 26,
          column: 29
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 27,
            column: 1
          }
        }
      },
      '1': {
        name: 'Utils',
        decl: {
          start: {
            line: 4,
            column: 11
          },
          end: {
            line: 4,
            column: 16
          }
        },
        loc: {
          start: {
            line: 4,
            column: 19
          },
          end: {
            line: 22,
            column: 3
          }
        }
      },
      '2': {
        name: 'now',
        decl: {
          start: {
            line: 10,
            column: 13
          },
          end: {
            line: 10,
            column: 16
          }
        },
        loc: {
          start: {
            line: 10,
            column: 19
          },
          end: {
            line: 14,
            column: 5
          }
        }
      },
      '3': {
        name: 'objectLength',
        decl: {
          start: {
            line: 16,
            column: 13
          },
          end: {
            line: 16,
            column: 25
          }
        },
        loc: {
          start: {
            line: 16,
            column: 31
          },
          end: {
            line: 21,
            column: 5
          }
        }
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 17,
            column: 6
          },
          end: {
            line: 19,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 17,
            column: 6
          },
          end: {
            line: 19,
            column: 7
          }
        }, {
          start: {
            line: 17,
            column: 6
          },
          end: {
            line: 19,
            column: 7
          }
        }]
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0
    },
    b: {
      '0': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_1jim3mx4q0.s[0];
(function () {
  ++cov_1jim3mx4q0.f[0];

  // define function Utils
  function Utils() {
    ++cov_1jim3mx4q0.f[1];
    ++cov_1jim3mx4q0.s[1];

    return {
      now: now,
      objectLength: objectLength
    };

    function now() {
      ++cov_1jim3mx4q0.f[2];

      // const d = new Date().toISOString();
      var d = (++cov_1jim3mx4q0.s[2], firebase.database.ServerValue.TIMESTAMP);
      ++cov_1jim3mx4q0.s[3];
      return d;
    }

    function objectLength(obj) {
      ++cov_1jim3mx4q0.f[3];
      ++cov_1jim3mx4q0.s[4];

      if (!obj) {
        ++cov_1jim3mx4q0.b[0][0];
        ++cov_1jim3mx4q0.s[5];

        return 0;
      } else {
        ++cov_1jim3mx4q0.b[0][1];
      }
      ++cov_1jim3mx4q0.s[6];
      return Object.keys(obj).length;
    }
  }

  ++cov_1jim3mx4q0.s[7];
  angular.module('app').factory('Utils', Utils);
})();