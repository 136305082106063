'use strict';

var cov_2ite3w08i0 = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/common/common.module.js',
      hash = 'dfbe8e3806d6c8fc397cdbf148eae950e1bb96e8',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/common/common.module.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 17,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 2
        },
        end: {
          line: 16,
          column: 8
        }
      },
      '2': {
        start: {
          line: 15,
          column: 6
        },
        end: {
          line: 15,
          column: 41
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 17,
            column: 1
          }
        }
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 14,
            column: 33
          },
          end: {
            line: 14,
            column: 34
          }
        },
        loc: {
          start: {
            line: 14,
            column: 51
          },
          end: {
            line: 16,
            column: 5
          }
        }
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0
    },
    f: {
      '0': 0,
      '1': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_2ite3w08i0.s[0];
(function () {
  ++cov_2ite3w08i0.f[0];
  ++cov_2ite3w08i0.s[1];

  angular.module('dc-common', ['ui.router', 'dc-auth', 'dc-activity', 'dc-customer', 'dc-contacts', 'dc-comments', 'ui.bootstrap', 'ui.bootstrap-slider', 'ui.select']).config(['uiSelectConfig', function (uiSelectConfig) {
    ++cov_2ite3w08i0.f[1];
    ++cov_2ite3w08i0.s[2];

    uiSelectConfig.theme = 'bootstrap';
  }]);
})();