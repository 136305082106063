'use strict';

var cov_2knm9o6a3a = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/service/ticket/ticket.component.js',
      hash = 'c877db2a1e52eb473ca64e1a1432c7c51ccb3903',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/service/ticket/ticket.component.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 98,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 20
        },
        end: {
          line: 6,
          column: 3
        }
      },
      '2': {
        start: {
          line: 8,
          column: 2
        },
        end: {
          line: 8,
          column: 135
        }
      },
      '3': {
        start: {
          line: 11,
          column: 17
        },
        end: {
          line: 11,
          column: 21
        }
      },
      '4': {
        start: {
          line: 14,
          column: 23
        },
        end: {
          line: 14,
          column: 56
        }
      },
      '5': {
        start: {
          line: 15,
          column: 6
        },
        end: {
          line: 21,
          column: 9
        }
      },
      '6': {
        start: {
          line: 16,
          column: 8
        },
        end: {
          line: 16,
          column: 29
        }
      },
      '7': {
        start: {
          line: 17,
          column: 8
        },
        end: {
          line: 17,
          column: 47
        }
      },
      '8': {
        start: {
          line: 18,
          column: 8
        },
        end: {
          line: 20,
          column: 9
        }
      },
      '9': {
        start: {
          line: 19,
          column: 10
        },
        end: {
          line: 19,
          column: 25
        }
      },
      '10': {
        start: {
          line: 25,
          column: 6
        },
        end: {
          line: 25,
          column: 93
        }
      },
      '11': {
        start: {
          line: 28,
          column: 4
        },
        end: {
          line: 28,
          column: 43
        }
      },
      '12': {
        start: {
          line: 30,
          column: 4
        },
        end: {
          line: 32,
          column: 7
        }
      },
      '13': {
        start: {
          line: 31,
          column: 6
        },
        end: {
          line: 31,
          column: 25
        }
      },
      '14': {
        start: {
          line: 34,
          column: 4
        },
        end: {
          line: 37,
          column: 6
        }
      },
      '15': {
        start: {
          line: 35,
          column: 6
        },
        end: {
          line: 35,
          column: 19
        }
      },
      '16': {
        start: {
          line: 36,
          column: 6
        },
        end: {
          line: 36,
          column: 24
        }
      },
      '17': {
        start: {
          line: 39,
          column: 4
        },
        end: {
          line: 53,
          column: 6
        }
      },
      '18': {
        start: {
          line: 40,
          column: 6
        },
        end: {
          line: 40,
          column: 31
        }
      },
      '19': {
        start: {
          line: 41,
          column: 6
        },
        end: {
          line: 41,
          column: 39
        }
      },
      '20': {
        start: {
          line: 42,
          column: 6
        },
        end: {
          line: 42,
          column: 21
        }
      },
      '21': {
        start: {
          line: 43,
          column: 6
        },
        end: {
          line: 52,
          column: 9
        }
      },
      '22': {
        start: {
          line: 44,
          column: 25
        },
        end: {
          line: 50,
          column: 9
        }
      },
      '23': {
        start: {
          line: 51,
          column: 8
        },
        end: {
          line: 51,
          column: 38
        }
      },
      '24': {
        start: {
          line: 55,
          column: 4
        },
        end: {
          line: 58,
          column: 6
        }
      },
      '25': {
        start: {
          line: 56,
          column: 6
        },
        end: {
          line: 56,
          column: 32
        }
      },
      '26': {
        start: {
          line: 57,
          column: 6
        },
        end: {
          line: 57,
          column: 26
        }
      },
      '27': {
        start: {
          line: 60,
          column: 4
        },
        end: {
          line: 68,
          column: 6
        }
      },
      '28': {
        start: {
          line: 61,
          column: 6
        },
        end: {
          line: 63,
          column: 7
        }
      },
      '29': {
        start: {
          line: 62,
          column: 8
        },
        end: {
          line: 62,
          column: 15
        }
      },
      '30': {
        start: {
          line: 64,
          column: 6
        },
        end: {
          line: 67,
          column: 11
        }
      },
      '31': {
        start: {
          line: 66,
          column: 10
        },
        end: {
          line: 66,
          column: 28
        }
      },
      '32': {
        start: {
          line: 70,
          column: 4
        },
        end: {
          line: 85,
          column: 6
        }
      },
      '33': {
        start: {
          line: 71,
          column: 28
        },
        end: {
          line: 73,
          column: 8
        }
      },
      '34': {
        start: {
          line: 74,
          column: 6
        },
        end: {
          line: 84,
          column: 11
        }
      },
      '35': {
        start: {
          line: 76,
          column: 10
        },
        end: {
          line: 81,
          column: 11
        }
      },
      '36': {
        start: {
          line: 77,
          column: 12
        },
        end: {
          line: 80,
          column: 17
        }
      },
      '37': {
        start: {
          line: 79,
          column: 16
        },
        end: {
          line: 79,
          column: 41
        }
      },
      '38': {
        start: {
          line: 87,
          column: 4
        },
        end: {
          line: 90,
          column: 6
        }
      },
      '39': {
        start: {
          line: 88,
          column: 6
        },
        end: {
          line: 88,
          column: 19
        }
      },
      '40': {
        start: {
          line: 89,
          column: 6
        },
        end: {
          line: 89,
          column: 29
        }
      },
      '41': {
        start: {
          line: 92,
          column: 4
        },
        end: {
          line: 94,
          column: 6
        }
      },
      '42': {
        start: {
          line: 93,
          column: 6
        },
        end: {
          line: 93,
          column: 29
        }
      },
      '43': {
        start: {
          line: 97,
          column: 2
        },
        end: {
          line: 97,
          column: 62
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 98,
            column: 1
          }
        }
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 10,
            column: 11
          },
          end: {
            line: 10,
            column: 21
          }
        },
        loc: {
          start: {
            line: 10,
            column: 115
          },
          end: {
            line: 95,
            column: 3
          }
        }
      },
      '2': {
        name: 'loadTicket',
        decl: {
          start: {
            line: 13,
            column: 13
          },
          end: {
            line: 13,
            column: 23
          }
        },
        loc: {
          start: {
            line: 13,
            column: 26
          },
          end: {
            line: 22,
            column: 5
          }
        }
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 15,
            column: 44
          },
          end: {
            line: 15,
            column: 45
          }
        },
        loc: {
          start: {
            line: 15,
            column: 54
          },
          end: {
            line: 21,
            column: 7
          }
        }
      },
      '4': {
        name: 'calcDuration',
        decl: {
          start: {
            line: 24,
            column: 13
          },
          end: {
            line: 24,
            column: 25
          }
        },
        loc: {
          start: {
            line: 24,
            column: 28
          },
          end: {
            line: 26,
            column: 5
          }
        }
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 30,
            column: 24
          },
          end: {
            line: 30,
            column: 25
          }
        },
        loc: {
          start: {
            line: 30,
            column: 33
          },
          end: {
            line: 32,
            column: 5
          }
        }
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 34,
            column: 19
          },
          end: {
            line: 34,
            column: 20
          }
        },
        loc: {
          start: {
            line: 34,
            column: 25
          },
          end: {
            line: 37,
            column: 5
          }
        }
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 39,
            column: 23
          },
          end: {
            line: 39,
            column: 24
          }
        },
        loc: {
          start: {
            line: 39,
            column: 29
          },
          end: {
            line: 53,
            column: 5
          }
        }
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 43,
            column: 31
          },
          end: {
            line: 43,
            column: 32
          }
        },
        loc: {
          start: {
            line: 43,
            column: 37
          },
          end: {
            line: 52,
            column: 7
          }
        }
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 55,
            column: 22
          },
          end: {
            line: 55,
            column: 23
          }
        },
        loc: {
          start: {
            line: 55,
            column: 28
          },
          end: {
            line: 58,
            column: 5
          }
        }
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 60,
            column: 16
          },
          end: {
            line: 60,
            column: 17
          }
        },
        loc: {
          start: {
            line: 60,
            column: 22
          },
          end: {
            line: 68,
            column: 5
          }
        }
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 65,
            column: 8
          },
          end: {
            line: 65,
            column: 9
          }
        },
        loc: {
          start: {
            line: 65,
            column: 14
          },
          end: {
            line: 67,
            column: 9
          }
        }
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 70,
            column: 18
          },
          end: {
            line: 70,
            column: 19
          }
        },
        loc: {
          start: {
            line: 70,
            column: 24
          },
          end: {
            line: 85,
            column: 5
          }
        }
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 75,
            column: 14
          },
          end: {
            line: 75,
            column: 15
          }
        },
        loc: {
          start: {
            line: 75,
            column: 20
          },
          end: {
            line: 82,
            column: 9
          }
        }
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 78,
            column: 20
          },
          end: {
            line: 78,
            column: 21
          }
        },
        loc: {
          start: {
            line: 78,
            column: 26
          },
          end: {
            line: 80,
            column: 15
          }
        }
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 82,
            column: 11
          },
          end: {
            line: 82,
            column: 12
          }
        },
        loc: {
          start: {
            line: 82,
            column: 17
          },
          end: {
            line: 84,
            column: 9
          }
        }
      },
      '16': {
        name: '(anonymous_16)',
        decl: {
          start: {
            line: 87,
            column: 18
          },
          end: {
            line: 87,
            column: 19
          }
        },
        loc: {
          start: {
            line: 87,
            column: 24
          },
          end: {
            line: 90,
            column: 5
          }
        }
      },
      '17': {
        name: '(anonymous_17)',
        decl: {
          start: {
            line: 92,
            column: 22
          },
          end: {
            line: 92,
            column: 23
          }
        },
        loc: {
          start: {
            line: 92,
            column: 28
          },
          end: {
            line: 94,
            column: 5
          }
        }
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 18,
            column: 8
          },
          end: {
            line: 20,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 18,
            column: 8
          },
          end: {
            line: 20,
            column: 9
          }
        }, {
          start: {
            line: 18,
            column: 8
          },
          end: {
            line: 20,
            column: 9
          }
        }]
      },
      '1': {
        loc: {
          start: {
            line: 61,
            column: 6
          },
          end: {
            line: 63,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 61,
            column: 6
          },
          end: {
            line: 63,
            column: 7
          }
        }, {
          start: {
            line: 61,
            column: 6
          },
          end: {
            line: 63,
            column: 7
          }
        }]
      },
      '2': {
        loc: {
          start: {
            line: 76,
            column: 10
          },
          end: {
            line: 81,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 76,
            column: 10
          },
          end: {
            line: 81,
            column: 11
          }
        }, {
          start: {
            line: 76,
            column: 10
          },
          end: {
            line: 81,
            column: 11
          }
        }]
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_2knm9o6a3a.s[0];
(function () {
  ++cov_2knm9o6a3a.f[0];

  var component = (++cov_2knm9o6a3a.s[1], {
    templateUrl: 'app/service/ticket/ticket.html',
    controller: controller
  });

  ++cov_2knm9o6a3a.s[2];
  controller.$inject = ['ActivityService', '$uibModal', '$log', '$stateParams', 'TicketService', 'Users', '$state', 'Utils', 'moment'];

  function controller(ActivityService, $uibModal, $log, $stateParams, TicketService, Users, $state, Utils, moment) {
    ++cov_2knm9o6a3a.f[1];

    var self = (++cov_2knm9o6a3a.s[3], this);

    function loadTicket() {
      ++cov_2knm9o6a3a.f[2];

      var ticketId = (++cov_2knm9o6a3a.s[4], parseFloat($stateParams.ticketId));
      ++cov_2knm9o6a3a.s[5];
      TicketService.$getByid(ticketId).then(function (ticket) {
        ++cov_2knm9o6a3a.f[3];
        ++cov_2knm9o6a3a.s[6];

        self.ticket = ticket;
        ++cov_2knm9o6a3a.s[7];
        self.lineItems = self.ticket.lineItems;
        ++cov_2knm9o6a3a.s[8];
        if (ticket.closed) {
          ++cov_2knm9o6a3a.b[0][0];
          ++cov_2knm9o6a3a.s[9];

          calcDuration();
        } else {
          ++cov_2knm9o6a3a.b[0][1];
        }
      });
    }

    function calcDuration() {
      ++cov_2knm9o6a3a.f[4];
      ++cov_2knm9o6a3a.s[10];

      self.duration = moment.duration(self.ticket.closed - self.ticket.created).humanize();
    }

    ++cov_2knm9o6a3a.s[11];
    self.roles = Users.currentUser().roles;

    ++cov_2knm9o6a3a.s[12];
    Users.all().$loaded(function (users) {
      ++cov_2knm9o6a3a.f[5];
      ++cov_2knm9o6a3a.s[13];

      self.users = users;
    });

    ++cov_2knm9o6a3a.s[14];
    self.$onInit = function () {
      ++cov_2knm9o6a3a.f[6];
      ++cov_2knm9o6a3a.s[15];

      loadTicket();
      ++cov_2knm9o6a3a.s[16];
      self.edit = false;
    };

    ++cov_2knm9o6a3a.s[17];
    self.closeTicket = function () {
      ++cov_2knm9o6a3a.f[7];
      ++cov_2knm9o6a3a.s[18];

      self.ticket.open = false;
      ++cov_2knm9o6a3a.s[19];
      self.ticket.closed = Utils.now();
      ++cov_2knm9o6a3a.s[20];
      calcDuration();
      ++cov_2knm9o6a3a.s[21];
      self.ticket.$save().then(function () {
        ++cov_2knm9o6a3a.f[8];

        var activity = (++cov_2knm9o6a3a.s[22], {
          userId: Users.currentUser().$id,
          type: 'Ticket',
          action: 'Closed',
          ticket: self.ticket.uid,
          ticketId: self.ticket.id
        });
        ++cov_2knm9o6a3a.s[23];
        ActivityService.add(activity);
      });
    };

    ++cov_2knm9o6a3a.s[24];
    self.billTicket = function () {
      ++cov_2knm9o6a3a.f[9];
      ++cov_2knm9o6a3a.s[25];

      self.ticket.billed = true;
      ++cov_2knm9o6a3a.s[26];
      self.ticket.$save();
    };

    ++cov_2knm9o6a3a.s[27];
    self.save = function () {
      ++cov_2knm9o6a3a.f[10];
      ++cov_2knm9o6a3a.s[28];

      if (self.edit === false) {
        ++cov_2knm9o6a3a.b[1][0];
        ++cov_2knm9o6a3a.s[29];

        return;
      } else {
        ++cov_2knm9o6a3a.b[1][1];
      }
      ++cov_2knm9o6a3a.s[30];
      self.ticket.$save().then(function () {
        ++cov_2knm9o6a3a.f[11];
        ++cov_2knm9o6a3a.s[31];

        self.edit = false;
      });
    };

    ++cov_2knm9o6a3a.s[32];
    self.delete = function () {
      ++cov_2knm9o6a3a.f[12];

      var modalInstance = (++cov_2knm9o6a3a.s[33], $uibModal.open({
        component: 'confirmModal'
      }));
      ++cov_2knm9o6a3a.s[34];
      modalInstance.result.then(function (ok) {
        ++cov_2knm9o6a3a.f[13];
        ++cov_2knm9o6a3a.s[35];

        if (ok === true) {
          ++cov_2knm9o6a3a.b[2][0];
          ++cov_2knm9o6a3a.s[36];

          TicketService.remove(self.ticket).then(function () {
            ++cov_2knm9o6a3a.f[14];
            ++cov_2knm9o6a3a.s[37];

            $state.go('service.all');
          });
        } else {
          ++cov_2knm9o6a3a.b[2][1];
        }
      }, function () {
        // modal dismiss promise resolves here.

        ++cov_2knm9o6a3a.f[15];
      });
    };

    ++cov_2knm9o6a3a.s[38];
    self.cancel = function () {
      ++cov_2knm9o6a3a.f[16];
      ++cov_2knm9o6a3a.s[39];

      loadTicket();
      ++cov_2knm9o6a3a.s[40];
      self.edit = !self.edit;
    };

    ++cov_2knm9o6a3a.s[41];
    self.$onDestroy = function () {
      ++cov_2knm9o6a3a.f[17];
      ++cov_2knm9o6a3a.s[42];

      self.ticket.$destroy();
    };
  }

  ++cov_2knm9o6a3a.s[43];
  angular.module('dc-service').component('ticket', component);
})();