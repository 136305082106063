'use strict';

var cov_1ydrbn1viz = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/common/customer/customer.routes.js',
      hash = '3aa7c77ab7544a98b92e1f269d9a3ac99edc8978',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/common/customer/customer.routes.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 25,
          column: 5
        }
      },
      '1': {
        start: {
          line: 4,
          column: 4
        },
        end: {
          line: 19,
          column: 7
        }
      },
      '2': {
        start: {
          line: 16,
          column: 10
        },
        end: {
          line: 16,
          column: 72
        }
      },
      '3': {
        start: {
          line: 22,
          column: 2
        },
        end: {
          line: 24,
          column: 45
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 25,
            column: 1
          }
        }
      },
      '1': {
        name: 'routeConfig',
        decl: {
          start: {
            line: 3,
            column: 11
          },
          end: {
            line: 3,
            column: 22
          }
        },
        loc: {
          start: {
            line: 3,
            column: 39
          },
          end: {
            line: 20,
            column: 3
          }
        }
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 15,
            column: 54
          },
          end: {
            line: 15,
            column: 55
          }
        },
        loc: {
          start: {
            line: 15,
            column: 95
          },
          end: {
            line: 17,
            column: 9
          }
        }
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_1ydrbn1viz.s[0];
(function () {
  ++cov_1ydrbn1viz.f[0];

  function routeConfig($stateProvider) {
    ++cov_1ydrbn1viz.f[1];
    ++cov_1ydrbn1viz.s[1];

    $stateProvider.state('customers', {
      parent: 'app',
      url: '/customers',
      component: 'customerList'
    }).state('customer', {
      parent: 'app',
      url: '/customers/:customerID',
      component: 'customerDetail',
      resolve: {
        customer: ['CustomerService', '$transition$', function (CustomerService, $transition$) {
          ++cov_1ydrbn1viz.f[2];
          ++cov_1ydrbn1viz.s[2];

          return CustomerService.$get($transition$.params().customerID);
        }]
      }
    });
  }

  ++cov_1ydrbn1viz.s[3];
  angular.module('dc-customer').config(['$stateProvider', routeConfig]);
})();