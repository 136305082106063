'use strict';

var cov_171sznmuco = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/common/customer/sites/site.service.js',
      hash = '8e9a168c7edc9d4eb9764c0fbb6898c3215743a2',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/common/customer/sites/site.service.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 74,
          column: 5
        }
      },
      '1': {
        start: {
          line: 5,
          column: 21
        },
        end: {
          line: 5,
          column: 30
        }
      },
      '2': {
        start: {
          line: 6,
          column: 24
        },
        end: {
          line: 6,
          column: 37
        }
      },
      '3': {
        start: {
          line: 7,
          column: 16
        },
        end: {
          line: 7,
          column: 20
        }
      },
      '4': {
        start: {
          line: 8,
          column: 20
        },
        end: {
          line: 15,
          column: 5
        }
      },
      '5': {
        start: {
          line: 18,
          column: 6
        },
        end: {
          line: 20,
          column: 7
        }
      },
      '6': {
        start: {
          line: 19,
          column: 8
        },
        end: {
          line: 19,
          column: 41
        }
      },
      '7': {
        start: {
          line: 21,
          column: 6
        },
        end: {
          line: 21,
          column: 19
        }
      },
      '8': {
        start: {
          line: 25,
          column: 6
        },
        end: {
          line: 25,
          column: 50
        }
      },
      '9': {
        start: {
          line: 29,
          column: 16
        },
        end: {
          line: 31,
          column: 21
        }
      },
      '10': {
        start: {
          line: 32,
          column: 6
        },
        end: {
          line: 32,
          column: 31
        }
      },
      '11': {
        start: {
          line: 36,
          column: 19
        },
        end: {
          line: 36,
          column: 42
        }
      },
      '12': {
        start: {
          line: 37,
          column: 6
        },
        end: {
          line: 40,
          column: 7
        }
      },
      '13': {
        start: {
          line: 39,
          column: 8
        },
        end: {
          line: 39,
          column: 15
        }
      },
      '14': {
        start: {
          line: 41,
          column: 22
        },
        end: {
          line: 41,
          column: 41
        }
      },
      '15': {
        start: {
          line: 42,
          column: 6
        },
        end: {
          line: 42,
          column: 25
        }
      },
      '16': {
        start: {
          line: 43,
          column: 6
        },
        end: {
          line: 43,
          column: 36
        }
      },
      '17': {
        start: {
          line: 44,
          column: 6
        },
        end: {
          line: 44,
          column: 37
        }
      },
      '18': {
        start: {
          line: 45,
          column: 6
        },
        end: {
          line: 56,
          column: 9
        }
      },
      '19': {
        start: {
          line: 47,
          column: 8
        },
        end: {
          line: 47,
          column: 81
        }
      },
      '20': {
        start: {
          line: 49,
          column: 8
        },
        end: {
          line: 55,
          column: 11
        }
      },
      '21': {
        start: {
          line: 60,
          column: 6
        },
        end: {
          line: 60,
          column: 42
        }
      },
      '22': {
        start: {
          line: 64,
          column: 6
        },
        end: {
          line: 67,
          column: 7
        }
      },
      '23': {
        start: {
          line: 65,
          column: 8
        },
        end: {
          line: 65,
          column: 25
        }
      },
      '24': {
        start: {
          line: 66,
          column: 8
        },
        end: {
          line: 66,
          column: 21
        }
      },
      '25': {
        start: {
          line: 69,
          column: 4
        },
        end: {
          line: 69,
          column: 19
        }
      },
      '26': {
        start: {
          line: 72,
          column: 2
        },
        end: {
          line: 73,
          column: 124
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 74,
            column: 1
          }
        }
      },
      '1': {
        name: 'SiteService',
        decl: {
          start: {
            line: 4,
            column: 11
          },
          end: {
            line: 4,
            column: 22
          }
        },
        loc: {
          start: {
            line: 4,
            column: 92
          },
          end: {
            line: 70,
            column: 3
          }
        }
      },
      '2': {
        name: 'all',
        decl: {
          start: {
            line: 17,
            column: 13
          },
          end: {
            line: 17,
            column: 16
          }
        },
        loc: {
          start: {
            line: 17,
            column: 19
          },
          end: {
            line: 22,
            column: 5
          }
        }
      },
      '3': {
        name: 'getByUid',
        decl: {
          start: {
            line: 24,
            column: 13
          },
          end: {
            line: 24,
            column: 21
          }
        },
        loc: {
          start: {
            line: 24,
            column: 27
          },
          end: {
            line: 26,
            column: 5
          }
        }
      },
      '4': {
        name: 'getByCustomer',
        decl: {
          start: {
            line: 28,
            column: 13
          },
          end: {
            line: 28,
            column: 26
          }
        },
        loc: {
          start: {
            line: 28,
            column: 32
          },
          end: {
            line: 33,
            column: 5
          }
        }
      },
      '5': {
        name: 'create',
        decl: {
          start: {
            line: 35,
            column: 13
          },
          end: {
            line: 35,
            column: 19
          }
        },
        loc: {
          start: {
            line: 35,
            column: 26
          },
          end: {
            line: 57,
            column: 5
          }
        }
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 45,
            column: 47
          },
          end: {
            line: 45,
            column: 48
          }
        },
        loc: {
          start: {
            line: 45,
            column: 53
          },
          end: {
            line: 56,
            column: 7
          }
        }
      },
      '7': {
        name: 'remove',
        decl: {
          start: {
            line: 59,
            column: 13
          },
          end: {
            line: 59,
            column: 19
          }
        },
        loc: {
          start: {
            line: 59,
            column: 25
          },
          end: {
            line: 61,
            column: 5
          }
        }
      },
      '8': {
        name: 'reset',
        decl: {
          start: {
            line: 63,
            column: 13
          },
          end: {
            line: 63,
            column: 18
          }
        },
        loc: {
          start: {
            line: 63,
            column: 21
          },
          end: {
            line: 68,
            column: 5
          }
        }
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 18,
            column: 6
          },
          end: {
            line: 20,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 18,
            column: 6
          },
          end: {
            line: 20,
            column: 7
          }
        }, {
          start: {
            line: 18,
            column: 6
          },
          end: {
            line: 20,
            column: 7
          }
        }]
      },
      '1': {
        loc: {
          start: {
            line: 37,
            column: 6
          },
          end: {
            line: 40,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 37,
            column: 6
          },
          end: {
            line: 40,
            column: 7
          }
        }, {
          start: {
            line: 37,
            column: 6
          },
          end: {
            line: 40,
            column: 7
          }
        }]
      },
      '2': {
        loc: {
          start: {
            line: 43,
            column: 23
          },
          end: {
            line: 43,
            column: 35
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 43,
            column: 23
          },
          end: {
            line: 43,
            column: 27
          }
        }, {
          start: {
            line: 43,
            column: 31
          },
          end: {
            line: 43,
            column: 35
          }
        }]
      },
      '3': {
        loc: {
          start: {
            line: 51,
            column: 20
          },
          end: {
            line: 51,
            column: 41
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 51,
            column: 20
          },
          end: {
            line: 51,
            column: 33
          }
        }, {
          start: {
            line: 51,
            column: 37
          },
          end: {
            line: 51,
            column: 41
          }
        }]
      },
      '4': {
        loc: {
          start: {
            line: 64,
            column: 6
          },
          end: {
            line: 67,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 64,
            column: 6
          },
          end: {
            line: 67,
            column: 7
          }
        }, {
          start: {
            line: 64,
            column: 6
          },
          end: {
            line: 67,
            column: 7
          }
        }]
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_171sznmuco.s[0];
(function () {
  ++cov_171sznmuco.f[0];

  // defines function Projects
  function SiteService($db, Utils, Users, ActivityService, $firebaseArray, $firebaseObject) {
    ++cov_171sznmuco.f[1];

    var sitesRef = (++cov_171sznmuco.s[1], $db.sites);
    var customerRef = (++cov_171sznmuco.s[2], $db.customers);
    var sites = (++cov_171sznmuco.s[3], null);
    var service = (++cov_171sznmuco.s[4], {
      all: all,
      getByUid: getByUid,
      getByCustomer: getByCustomer,
      create: create,
      remove: remove,
      reset: reset
    });

    function all() {
      ++cov_171sznmuco.f[2];
      ++cov_171sznmuco.s[5];

      if (!sites) {
        ++cov_171sznmuco.b[0][0];
        ++cov_171sznmuco.s[6];

        sites = $firebaseArray(sitesRef);
      } else {
        ++cov_171sznmuco.b[0][1];
      }
      ++cov_171sznmuco.s[7];
      return sites;
    }

    function getByUid(uid) {
      ++cov_171sznmuco.f[3];
      ++cov_171sznmuco.s[8];

      return $firebaseObject(sitesRef.child(uid));
    }

    function getByCustomer(uid) {
      ++cov_171sznmuco.f[4];

      var q = (++cov_171sznmuco.s[9], sitesRef.orderByChild('customer').equalTo(uid));
      ++cov_171sznmuco.s[10];
      return $firebaseArray(q);
    }

    function create(site) {
      ++cov_171sznmuco.f[5];

      var user = (++cov_171sznmuco.s[11], Users.currentUser().$id);
      ++cov_171sznmuco.s[12];
      if (!site.customer) {
        ++cov_171sznmuco.b[1][0];
        ++cov_171sznmuco.s[13];

        // TODO: THROW ERROR
        return;
      } else {
        ++cov_171sznmuco.b[1][1];
      }
      var siteRef = (++cov_171sznmuco.s[14], sitesRef.push().key);
      ++cov_171sznmuco.s[15];
      site.uid = siteRef;
      ++cov_171sznmuco.s[16];
      site.createdBy = (++cov_171sznmuco.b[2][0], user) || (++cov_171sznmuco.b[2][1], null);
      ++cov_171sznmuco.s[17];
      site.createdDate = Utils.now();
      ++cov_171sznmuco.s[18];
      return sitesRef.child(siteRef).set(site, function () {
        ++cov_171sznmuco.f[6];
        ++cov_171sznmuco.s[19];

        // sets customerId/sites/SiteId to true
        customerRef.child(site.customer).child('sites').child(siteRef).set(true);
        // track activity
        ++cov_171sznmuco.s[20];
        ActivityService.add({
          userId: user,
          customer: (++cov_171sznmuco.b[3][0], site.customer) || (++cov_171sznmuco.b[3][1], null),
          type: 'Site',
          action: 'Created',
          link: site.uid
        });
      });
    }

    function remove(uid) {
      ++cov_171sznmuco.f[7];
      ++cov_171sznmuco.s[21];

      return sitesRef.child(uid).remove();
    }

    function reset() {
      ++cov_171sznmuco.f[8];
      ++cov_171sznmuco.s[22];

      if (sites) {
        ++cov_171sznmuco.b[4][0];
        ++cov_171sznmuco.s[23];

        sites.$destroy();
        ++cov_171sznmuco.s[24];
        sites = null;
      } else {
        ++cov_171sznmuco.b[4][1];
      }
    }
    ++cov_171sznmuco.s[25];
    return service;
  }

  ++cov_171sznmuco.s[26];
  angular.module('dc-customer').factory('SiteService', ['$db', 'Utils', 'Users', 'ActivityService', '$firebaseArray', '$firebaseObject', SiteService]);
})();