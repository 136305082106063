'use strict';

var cov_250toy2xho = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/service/helpers/priority.filter.js',
      hash = 'bba0f7c8e5c09723bc8a9694ed4966c937e5b5dc',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/service/helpers/priority.filter.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 18,
          column: 5
        }
      },
      '1': {
        start: {
          line: 4,
          column: 4
        },
        end: {
          line: 15,
          column: 6
        }
      },
      '2': {
        start: {
          line: 5,
          column: 18
        },
        end: {
          line: 5,
          column: 20
        }
      },
      '3': {
        start: {
          line: 6,
          column: 6
        },
        end: {
          line: 8,
          column: 7
        }
      },
      '4': {
        start: {
          line: 7,
          column: 8
        },
        end: {
          line: 7,
          column: 16
        }
      },
      '5': {
        start: {
          line: 9,
          column: 6
        },
        end: {
          line: 13,
          column: 9
        }
      },
      '6': {
        start: {
          line: 10,
          column: 8
        },
        end: {
          line: 12,
          column: 9
        }
      },
      '7': {
        start: {
          line: 11,
          column: 10
        },
        end: {
          line: 11,
          column: 25
        }
      },
      '8': {
        start: {
          line: 14,
          column: 6
        },
        end: {
          line: 14,
          column: 17
        }
      },
      '9': {
        start: {
          line: 17,
          column: 2
        },
        end: {
          line: 17,
          column: 61
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 18,
            column: 1
          }
        }
      },
      '1': {
        name: 'filter',
        decl: {
          start: {
            line: 3,
            column: 11
          },
          end: {
            line: 3,
            column: 17
          }
        },
        loc: {
          start: {
            line: 3,
            column: 20
          },
          end: {
            line: 16,
            column: 3
          }
        }
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 4,
            column: 11
          },
          end: {
            line: 4,
            column: 12
          }
        },
        loc: {
          start: {
            line: 4,
            column: 33
          },
          end: {
            line: 15,
            column: 5
          }
        }
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 9,
            column: 29
          },
          end: {
            line: 9,
            column: 30
          }
        },
        loc: {
          start: {
            line: 9,
            column: 37
          },
          end: {
            line: 13,
            column: 7
          }
        }
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 6,
            column: 6
          },
          end: {
            line: 8,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 6,
            column: 6
          },
          end: {
            line: 8,
            column: 7
          }
        }, {
          start: {
            line: 6,
            column: 6
          },
          end: {
            line: 8,
            column: 7
          }
        }]
      },
      '1': {
        loc: {
          start: {
            line: 10,
            column: 8
          },
          end: {
            line: 12,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 10,
            column: 8
          },
          end: {
            line: 12,
            column: 9
          }
        }, {
          start: {
            line: 10,
            column: 8
          },
          end: {
            line: 12,
            column: 9
          }
        }]
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_250toy2xho.s[0];
(function () {
  ++cov_250toy2xho.f[0];

  function filter() {
    ++cov_250toy2xho.f[1];
    ++cov_250toy2xho.s[1];

    return function (array, min) {
      ++cov_250toy2xho.f[2];

      var out = (++cov_250toy2xho.s[2], []);
      ++cov_250toy2xho.s[3];
      if (!min) {
        ++cov_250toy2xho.b[0][0];
        ++cov_250toy2xho.s[4];

        min = 0;
      } else {
        ++cov_250toy2xho.b[0][1];
      }
      ++cov_250toy2xho.s[5];
      angular.forEach(array, function (item) {
        ++cov_250toy2xho.f[3];
        ++cov_250toy2xho.s[6];

        if (item.priority >= min) {
          ++cov_250toy2xho.b[1][0];
          ++cov_250toy2xho.s[7];

          out.push(item);
        } else {
          ++cov_250toy2xho.b[1][1];
        }
      });
      ++cov_250toy2xho.s[8];
      return out;
    };
  }
  ++cov_250toy2xho.s[9];
  angular.module('dc-service').filter('minPriority', filter);
})();