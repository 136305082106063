'use strict';

var cov_1ei9xeijve = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/services/projects.js',
      hash = '3e2ce6d0b435a567f1ed956f65b75fba6a3bea10',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/services/projects.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 166,
          column: 5
        }
      },
      '1': {
        start: {
          line: 5,
          column: 24
        },
        end: {
          line: 5,
          column: 36
        }
      },
      '2': {
        start: {
          line: 6,
          column: 23
        },
        end: {
          line: 6,
          column: 34
        }
      },
      '3': {
        start: {
          line: 7,
          column: 22
        },
        end: {
          line: 7,
          column: 32
        }
      },
      '4': {
        start: {
          line: 9,
          column: 19
        },
        end: {
          line: 9,
          column: 23
        }
      },
      '5': {
        start: {
          line: 10,
          column: 25
        },
        end: {
          line: 10,
          column: 29
        }
      },
      '6': {
        start: {
          line: 11,
          column: 27
        },
        end: {
          line: 11,
          column: 31
        }
      },
      '7': {
        start: {
          line: 13,
          column: 20
        },
        end: {
          line: 27,
          column: 5
        }
      },
      '8': {
        start: {
          line: 30,
          column: 20
        },
        end: {
          line: 32,
          column: 23
        }
      },
      '9': {
        start: {
          line: 33,
          column: 6
        },
        end: {
          line: 33,
          column: 40
        }
      },
      '10': {
        start: {
          line: 37,
          column: 6
        },
        end: {
          line: 40,
          column: 7
        }
      },
      '11': {
        start: {
          line: 38,
          column: 22
        },
        end: {
          line: 38,
          column: 61
        }
      },
      '12': {
        start: {
          line: 39,
          column: 8
        },
        end: {
          line: 39,
          column: 36
        }
      },
      '13': {
        start: {
          line: 41,
          column: 6
        },
        end: {
          line: 41,
          column: 22
        }
      },
      '14': {
        start: {
          line: 45,
          column: 20
        },
        end: {
          line: 45,
          column: 74
        }
      },
      '15': {
        start: {
          line: 46,
          column: 6
        },
        end: {
          line: 46,
          column: 30
        }
      },
      '16': {
        start: {
          line: 50,
          column: 6
        },
        end: {
          line: 58,
          column: 7
        }
      },
      '17': {
        start: {
          line: 51,
          column: 8
        },
        end: {
          line: 56,
          column: 9
        }
      },
      '18': {
        start: {
          line: 52,
          column: 24
        },
        end: {
          line: 54,
          column: 27
        }
      },
      '19': {
        start: {
          line: 55,
          column: 10
        },
        end: {
          line: 55,
          column: 46
        }
      },
      '20': {
        start: {
          line: 57,
          column: 8
        },
        end: {
          line: 57,
          column: 32
        }
      },
      '21': {
        start: {
          line: 59,
          column: 6
        },
        end: {
          line: 64,
          column: 7
        }
      },
      '22': {
        start: {
          line: 60,
          column: 22
        },
        end: {
          line: 62,
          column: 24
        }
      },
      '23': {
        start: {
          line: 63,
          column: 8
        },
        end: {
          line: 63,
          column: 42
        }
      },
      '24': {
        start: {
          line: 65,
          column: 6
        },
        end: {
          line: 65,
          column: 28
        }
      },
      '25': {
        start: {
          line: 69,
          column: 6
        },
        end: {
          line: 69,
          column: 53
        }
      },
      '26': {
        start: {
          line: 73,
          column: 20
        },
        end: {
          line: 75,
          column: 28
        }
      },
      '27': {
        start: {
          line: 76,
          column: 6
        },
        end: {
          line: 76,
          column: 30
        }
      },
      '28': {
        start: {
          line: 80,
          column: 20
        },
        end: {
          line: 81,
          column: 47
        }
      },
      '29': {
        start: {
          line: 82,
          column: 6
        },
        end: {
          line: 82,
          column: 30
        }
      },
      '30': {
        start: {
          line: 86,
          column: 20
        },
        end: {
          line: 86,
          column: 30
        }
      },
      '31': {
        start: {
          line: 87,
          column: 6
        },
        end: {
          line: 94,
          column: 7
        }
      },
      '32': {
        start: {
          line: 88,
          column: 23
        },
        end: {
          line: 88,
          column: 52
        }
      },
      '33': {
        start: {
          line: 89,
          column: 8
        },
        end: {
          line: 91,
          column: 9
        }
      },
      '34': {
        start: {
          line: 90,
          column: 10
        },
        end: {
          line: 90,
          column: 27
        }
      },
      '35': {
        start: {
          line: 92,
          column: 8
        },
        end: {
          line: 92,
          column: 47
        }
      },
      '36': {
        start: {
          line: 93,
          column: 8
        },
        end: {
          line: 93,
          column: 46
        }
      },
      '37': {
        start: {
          line: 95,
          column: 6
        },
        end: {
          line: 103,
          column: 11
        }
      },
      '38': {
        start: {
          line: 98,
          column: 10
        },
        end: {
          line: 101,
          column: 11
        }
      },
      '39': {
        start: {
          line: 99,
          column: 12
        },
        end: {
          line: 99,
          column: 61
        }
      },
      '40': {
        start: {
          line: 100,
          column: 12
        },
        end: {
          line: 100,
          column: 48
        }
      },
      '41': {
        start: {
          line: 102,
          column: 10
        },
        end: {
          line: 102,
          column: 33
        }
      },
      '42': {
        start: {
          line: 104,
          column: 6
        },
        end: {
          line: 104,
          column: 27
        }
      },
      '43': {
        start: {
          line: 108,
          column: 25
        },
        end: {
          line: 108,
          column: 47
        }
      },
      '44': {
        start: {
          line: 109,
          column: 6
        },
        end: {
          line: 109,
          column: 31
        }
      },
      '45': {
        start: {
          line: 110,
          column: 6
        },
        end: {
          line: 110,
          column: 28
        }
      },
      '46': {
        start: {
          line: 111,
          column: 6
        },
        end: {
          line: 111,
          column: 49
        }
      },
      '47': {
        start: {
          line: 112,
          column: 6
        },
        end: {
          line: 115,
          column: 9
        }
      },
      '48': {
        start: {
          line: 114,
          column: 8
        },
        end: {
          line: 114,
          column: 56
        }
      },
      '49': {
        start: {
          line: 119,
          column: 20
        },
        end: {
          line: 119,
          column: 30
        }
      },
      '50': {
        start: {
          line: 120,
          column: 6
        },
        end: {
          line: 131,
          column: 11
        }
      },
      '51': {
        start: {
          line: 125,
          column: 10
        },
        end: {
          line: 127,
          column: 11
        }
      },
      '52': {
        start: {
          line: 126,
          column: 12
        },
        end: {
          line: 126,
          column: 43
        }
      },
      '53': {
        start: {
          line: 128,
          column: 10
        },
        end: {
          line: 130,
          column: 13
        }
      },
      '54': {
        start: {
          line: 129,
          column: 12
        },
        end: {
          line: 129,
          column: 62
        }
      },
      '55': {
        start: {
          line: 132,
          column: 6
        },
        end: {
          line: 132,
          column: 27
        }
      },
      '56': {
        start: {
          line: 136,
          column: 21
        },
        end: {
          line: 138,
          column: 24
        }
      },
      '57': {
        start: {
          line: 139,
          column: 6
        },
        end: {
          line: 139,
          column: 31
        }
      },
      '58': {
        start: {
          line: 143,
          column: 6
        },
        end: {
          line: 143,
          column: 37
        }
      },
      '59': {
        start: {
          line: 144,
          column: 6
        },
        end: {
          line: 144,
          column: 45
        }
      },
      '60': {
        start: {
          line: 148,
          column: 6
        },
        end: {
          line: 151,
          column: 7
        }
      },
      '61': {
        start: {
          line: 149,
          column: 8
        },
        end: {
          line: 149,
          column: 28
        }
      },
      '62': {
        start: {
          line: 150,
          column: 8
        },
        end: {
          line: 150,
          column: 24
        }
      },
      '63': {
        start: {
          line: 152,
          column: 6
        },
        end: {
          line: 155,
          column: 7
        }
      },
      '64': {
        start: {
          line: 153,
          column: 8
        },
        end: {
          line: 153,
          column: 34
        }
      },
      '65': {
        start: {
          line: 154,
          column: 8
        },
        end: {
          line: 154,
          column: 30
        }
      },
      '66': {
        start: {
          line: 156,
          column: 6
        },
        end: {
          line: 159,
          column: 7
        }
      },
      '67': {
        start: {
          line: 157,
          column: 8
        },
        end: {
          line: 157,
          column: 36
        }
      },
      '68': {
        start: {
          line: 158,
          column: 8
        },
        end: {
          line: 158,
          column: 32
        }
      },
      '69': {
        start: {
          line: 161,
          column: 4
        },
        end: {
          line: 161,
          column: 19
        }
      },
      '70': {
        start: {
          line: 164,
          column: 2
        },
        end: {
          line: 165,
          column: 102
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 166,
            column: 1
          }
        }
      },
      '1': {
        name: 'Projects',
        decl: {
          start: {
            line: 4,
            column: 11
          },
          end: {
            line: 4,
            column: 19
          }
        },
        loc: {
          start: {
            line: 4,
            column: 73
          },
          end: {
            line: 162,
            column: 3
          }
        }
      },
      '2': {
        name: '$getNextProjectNumber',
        decl: {
          start: {
            line: 29,
            column: 13
          },
          end: {
            line: 29,
            column: 34
          }
        },
        loc: {
          start: {
            line: 29,
            column: 37
          },
          end: {
            line: 34,
            column: 5
          }
        }
      },
      '3': {
        name: 'all',
        decl: {
          start: {
            line: 36,
            column: 13
          },
          end: {
            line: 36,
            column: 16
          }
        },
        loc: {
          start: {
            line: 36,
            column: 19
          },
          end: {
            line: 42,
            column: 5
          }
        }
      },
      '4': {
        name: '$getRecent',
        decl: {
          start: {
            line: 44,
            column: 13
          },
          end: {
            line: 44,
            column: 23
          }
        },
        loc: {
          start: {
            line: 44,
            column: 26
          },
          end: {
            line: 47,
            column: 5
          }
        }
      },
      '5': {
        name: '$getByStatus',
        decl: {
          start: {
            line: 49,
            column: 13
          },
          end: {
            line: 49,
            column: 25
          }
        },
        loc: {
          start: {
            line: 49,
            column: 34
          },
          end: {
            line: 66,
            column: 5
          }
        }
      },
      '6': {
        name: 'getProject',
        decl: {
          start: {
            line: 68,
            column: 13
          },
          end: {
            line: 68,
            column: 23
          }
        },
        loc: {
          start: {
            line: 68,
            column: 29
          },
          end: {
            line: 70,
            column: 5
          }
        }
      },
      '7': {
        name: '$getByCustomer',
        decl: {
          start: {
            line: 72,
            column: 13
          },
          end: {
            line: 72,
            column: 27
          }
        },
        loc: {
          start: {
            line: 72,
            column: 40
          },
          end: {
            line: 77,
            column: 5
          }
        }
      },
      '8': {
        name: '$getByPriority',
        decl: {
          start: {
            line: 79,
            column: 13
          },
          end: {
            line: 79,
            column: 27
          }
        },
        loc: {
          start: {
            line: 79,
            column: 38
          },
          end: {
            line: 83,
            column: 5
          }
        }
      },
      '9': {
        name: '$getProjectMetadata',
        decl: {
          start: {
            line: 85,
            column: 13
          },
          end: {
            line: 85,
            column: 32
          }
        },
        loc: {
          start: {
            line: 85,
            column: 38
          },
          end: {
            line: 105,
            column: 5
          }
        }
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 97,
            column: 14
          },
          end: {
            line: 97,
            column: 15
          }
        },
        loc: {
          start: {
            line: 97,
            column: 29
          },
          end: {
            line: 103,
            column: 9
          }
        }
      },
      '11': {
        name: 'create',
        decl: {
          start: {
            line: 107,
            column: 13
          },
          end: {
            line: 107,
            column: 19
          }
        },
        loc: {
          start: {
            line: 107,
            column: 29
          },
          end: {
            line: 116,
            column: 5
          }
        }
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 112,
            column: 56
          },
          end: {
            line: 112,
            column: 57
          }
        },
        loc: {
          start: {
            line: 112,
            column: 62
          },
          end: {
            line: 115,
            column: 7
          }
        }
      },
      '13': {
        name: 'getProjectByNumber',
        decl: {
          start: {
            line: 118,
            column: 13
          },
          end: {
            line: 118,
            column: 31
          }
        },
        loc: {
          start: {
            line: 118,
            column: 47
          },
          end: {
            line: 133,
            column: 5
          }
        }
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 124,
            column: 14
          },
          end: {
            line: 124,
            column: 15
          }
        },
        loc: {
          start: {
            line: 124,
            column: 26
          },
          end: {
            line: 131,
            column: 9
          }
        }
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 128,
            column: 27
          },
          end: {
            line: 128,
            column: 28
          }
        },
        loc: {
          start: {
            line: 128,
            column: 41
          },
          end: {
            line: 130,
            column: 11
          }
        }
      },
      '16': {
        name: 'getByUser',
        decl: {
          start: {
            line: 135,
            column: 13
          },
          end: {
            line: 135,
            column: 22
          }
        },
        loc: {
          start: {
            line: 135,
            column: 31
          },
          end: {
            line: 140,
            column: 5
          }
        }
      },
      '17': {
        name: 'remove',
        decl: {
          start: {
            line: 142,
            column: 13
          },
          end: {
            line: 142,
            column: 19
          }
        },
        loc: {
          start: {
            line: 142,
            column: 25
          },
          end: {
            line: 145,
            column: 5
          }
        }
      },
      '18': {
        name: 'reset',
        decl: {
          start: {
            line: 147,
            column: 13
          },
          end: {
            line: 147,
            column: 18
          }
        },
        loc: {
          start: {
            line: 147,
            column: 21
          },
          end: {
            line: 160,
            column: 5
          }
        }
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 37,
            column: 6
          },
          end: {
            line: 40,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 37,
            column: 6
          },
          end: {
            line: 40,
            column: 7
          }
        }, {
          start: {
            line: 37,
            column: 6
          },
          end: {
            line: 40,
            column: 7
          }
        }]
      },
      '1': {
        loc: {
          start: {
            line: 50,
            column: 6
          },
          end: {
            line: 58,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 50,
            column: 6
          },
          end: {
            line: 58,
            column: 7
          }
        }, {
          start: {
            line: 50,
            column: 6
          },
          end: {
            line: 58,
            column: 7
          }
        }]
      },
      '2': {
        loc: {
          start: {
            line: 51,
            column: 8
          },
          end: {
            line: 56,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 51,
            column: 8
          },
          end: {
            line: 56,
            column: 9
          }
        }, {
          start: {
            line: 51,
            column: 8
          },
          end: {
            line: 56,
            column: 9
          }
        }]
      },
      '3': {
        loc: {
          start: {
            line: 59,
            column: 6
          },
          end: {
            line: 64,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 59,
            column: 6
          },
          end: {
            line: 64,
            column: 7
          }
        }, {
          start: {
            line: 59,
            column: 6
          },
          end: {
            line: 64,
            column: 7
          }
        }]
      },
      '4': {
        loc: {
          start: {
            line: 87,
            column: 6
          },
          end: {
            line: 94,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 87,
            column: 6
          },
          end: {
            line: 94,
            column: 7
          }
        }, {
          start: {
            line: 87,
            column: 6
          },
          end: {
            line: 94,
            column: 7
          }
        }]
      },
      '5': {
        loc: {
          start: {
            line: 89,
            column: 8
          },
          end: {
            line: 91,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 89,
            column: 8
          },
          end: {
            line: 91,
            column: 9
          }
        }, {
          start: {
            line: 89,
            column: 8
          },
          end: {
            line: 91,
            column: 9
          }
        }]
      },
      '6': {
        loc: {
          start: {
            line: 98,
            column: 10
          },
          end: {
            line: 101,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 98,
            column: 10
          },
          end: {
            line: 101,
            column: 11
          }
        }, {
          start: {
            line: 98,
            column: 10
          },
          end: {
            line: 101,
            column: 11
          }
        }]
      },
      '7': {
        loc: {
          start: {
            line: 125,
            column: 10
          },
          end: {
            line: 127,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 125,
            column: 10
          },
          end: {
            line: 127,
            column: 11
          }
        }, {
          start: {
            line: 125,
            column: 10
          },
          end: {
            line: 127,
            column: 11
          }
        }]
      },
      '8': {
        loc: {
          start: {
            line: 148,
            column: 6
          },
          end: {
            line: 151,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 148,
            column: 6
          },
          end: {
            line: 151,
            column: 7
          }
        }, {
          start: {
            line: 148,
            column: 6
          },
          end: {
            line: 151,
            column: 7
          }
        }]
      },
      '9': {
        loc: {
          start: {
            line: 152,
            column: 6
          },
          end: {
            line: 155,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 152,
            column: 6
          },
          end: {
            line: 155,
            column: 7
          }
        }, {
          start: {
            line: 152,
            column: 6
          },
          end: {
            line: 155,
            column: 7
          }
        }]
      },
      '10': {
        loc: {
          start: {
            line: 156,
            column: 6
          },
          end: {
            line: 159,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 156,
            column: 6
          },
          end: {
            line: 159,
            column: 7
          }
        }, {
          start: {
            line: 156,
            column: 6
          },
          end: {
            line: 159,
            column: 7
          }
        }]
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_1ei9xeijve.s[0];
(function () {
  ++cov_1ei9xeijve.f[0];

  // defines function Projects
  function Projects($db, Utils, Numbers, $q, $projects, $firebaseObject) {
    ++cov_1ei9xeijve.f[1];

    var projectsRef = (++cov_1ei9xeijve.s[1], $db.projects);
    var numbersRef = (++cov_1ei9xeijve.s[2], $db.numbers);
    var queuesRef = (++cov_1ei9xeijve.s[3], $db.queues);

    var projects = (++cov_1ei9xeijve.s[4], null);
    var activeProjects = (++cov_1ei9xeijve.s[5], null);
    var inactiveProjects = (++cov_1ei9xeijve.s[6], null);

    var service = (++cov_1ei9xeijve.s[7], {
      all: all,
      getByUser: getByUser,
      $getRecent: $getRecent,
      $getByCustomer: $getByCustomer,
      $getByStatus: $getByStatus,
      $getByPriority: $getByPriority,
      $getProjectMetadata: $getProjectMetadata,
      $getNextProjectNumber: $getNextProjectNumber,
      create: create,
      remove: remove,
      getProjectByNumber: getProjectByNumber,
      getProject: getProject,
      reset: reset
    });

    function $getNextProjectNumber() {
      ++cov_1ei9xeijve.f[2];

      var query = (++cov_1ei9xeijve.s[8], projectsRef.orderByChild('numberFixed').limitToLast(1));
      ++cov_1ei9xeijve.s[9];
      return $projects(query).$loaded();
    }

    function all() {
      ++cov_1ei9xeijve.f[3];
      ++cov_1ei9xeijve.s[10];

      if (!projects) {
        ++cov_1ei9xeijve.b[0][0];

        var query = (++cov_1ei9xeijve.s[11], projectsRef.orderByChild('numberFixed'));
        ++cov_1ei9xeijve.s[12];
        projects = $projects(query);
      } else {
        ++cov_1ei9xeijve.b[0][1];
      }
      ++cov_1ei9xeijve.s[13];
      return projects;
    }

    function $getRecent() {
      ++cov_1ei9xeijve.f[4];

      var query = (++cov_1ei9xeijve.s[14], projectsRef.orderByChild('numberFixed').limitToLast(8));
      ++cov_1ei9xeijve.s[15];
      return $projects(query);
    }

    function $getByStatus(status) {
      ++cov_1ei9xeijve.f[5];
      ++cov_1ei9xeijve.s[16];

      if (status === 'inactive') {
        ++cov_1ei9xeijve.b[1][0];
        ++cov_1ei9xeijve.s[17];

        if (!inactiveProjects) {
          ++cov_1ei9xeijve.b[2][0];

          var query = (++cov_1ei9xeijve.s[18], projectsRef.orderByChild('active').equalTo(false));
          ++cov_1ei9xeijve.s[19];
          inactiveProjects = $projects(query);
        } else {
          ++cov_1ei9xeijve.b[2][1];
        }
        ++cov_1ei9xeijve.s[20];
        return inactiveProjects;
      } else {
        ++cov_1ei9xeijve.b[1][1];
      }
      ++cov_1ei9xeijve.s[21];
      if (!activeProjects) {
        ++cov_1ei9xeijve.b[3][0];

        var _query = (++cov_1ei9xeijve.s[22], projectsRef.orderByChild('active').equalTo(true));
        ++cov_1ei9xeijve.s[23];
        activeProjects = $projects(_query);
      } else {
        ++cov_1ei9xeijve.b[3][1];
      }
      ++cov_1ei9xeijve.s[24];
      return activeProjects;
    }

    function getProject(uid) {
      ++cov_1ei9xeijve.f[6];
      ++cov_1ei9xeijve.s[25];

      return $firebaseObject(projectsRef.child(uid));
    }

    function $getByCustomer(customerID) {
      ++cov_1ei9xeijve.f[7];

      var query = (++cov_1ei9xeijve.s[26], projectsRef.orderByChild('customer').equalTo(customerID));
      ++cov_1ei9xeijve.s[27];
      return $projects(query);
    }

    function $getByPriority(priority) {
      ++cov_1ei9xeijve.f[8];

      var query = (++cov_1ei9xeijve.s[28], projectsRef.orderByChild('priorities/' + priority));
      ++cov_1ei9xeijve.s[29];
      return $projects(query);
    }

    function $getProjectMetadata(uid) {
      ++cov_1ei9xeijve.f[9];

      var defer = (++cov_1ei9xeijve.s[30], $q.defer());
      ++cov_1ei9xeijve.s[31];
      if (projects) {
        ++cov_1ei9xeijve.b[4][0];

        var record = (++cov_1ei9xeijve.s[32], service.all().$getRecord(uid));
        ++cov_1ei9xeijve.s[33];
        if (!record) {
          ++cov_1ei9xeijve.b[5][0];
          ++cov_1ei9xeijve.s[34];

          return 'removed';
        } else {
          ++cov_1ei9xeijve.b[5][1];
        }
        ++cov_1ei9xeijve.s[35];
        record.metadata.number = record.number;
        ++cov_1ei9xeijve.s[36];
        return defer.resolve(record.metadata);
      } else {
        ++cov_1ei9xeijve.b[4][1];
      }
      ++cov_1ei9xeijve.s[37];
      $firebaseObject(projectsRef.child(uid)).$loaded().then(function (projectData) {
        ++cov_1ei9xeijve.f[10];
        ++cov_1ei9xeijve.s[38];

        if (projectData) {
          ++cov_1ei9xeijve.b[6][0];
          ++cov_1ei9xeijve.s[39];

          projectData.metadata.number = projectData.number;
          ++cov_1ei9xeijve.s[40];
          defer.resolve(projectData.metadata);
        } else {
          ++cov_1ei9xeijve.b[6][1];
        }
        ++cov_1ei9xeijve.s[41];
        projectData.$destroy();
      });
      ++cov_1ei9xeijve.s[42];
      return defer.promise;
    }

    function create(project) {
      ++cov_1ei9xeijve.f[11];

      var projectRef = (++cov_1ei9xeijve.s[43], projectsRef.push().key);
      ++cov_1ei9xeijve.s[44];
      project.uid = projectRef;
      ++cov_1ei9xeijve.s[45];
      project.active = true;
      ++cov_1ei9xeijve.s[46];
      project.metadata.createdDate = Utils.now();
      ++cov_1ei9xeijve.s[47];
      return projectsRef.child(projectRef).set(project, function () {
        ++cov_1ei9xeijve.f[12];
        ++cov_1ei9xeijve.s[48];

        // Numbers.create(projectRef);
        queuesRef.child('create-project').push(project);
      });
    }

    function getProjectByNumber(projectNumber) {
      ++cov_1ei9xeijve.f[13];

      var defer = (++cov_1ei9xeijve.s[49], $q.defer());
      ++cov_1ei9xeijve.s[50];
      projectsRef.orderByChild('number').equalTo(projectNumber).once('value').then(function (snapshot) {
        ++cov_1ei9xeijve.f[14];
        ++cov_1ei9xeijve.s[51];

        if (!snapshot.val()) {
          ++cov_1ei9xeijve.b[7][0];
          ++cov_1ei9xeijve.s[52];

          defer.reject('Does Not Exist');
        } else {
          ++cov_1ei9xeijve.b[7][1];
        }
        ++cov_1ei9xeijve.s[53];
        snapshot.forEach(function (projectObj) {
          ++cov_1ei9xeijve.f[15];
          ++cov_1ei9xeijve.s[54];

          defer.resolve(service.getProject(projectObj.key));
        });
      });
      ++cov_1ei9xeijve.s[55];
      return defer.promise;
    }

    function getByUser(userId) {
      ++cov_1ei9xeijve.f[16];

      var myProj = (++cov_1ei9xeijve.s[56], projectsRef.orderByChild('metadata/projectManager').equalTo(userId));
      ++cov_1ei9xeijve.s[57];
      return $projects(myProj);
    }

    function remove(uid) {
      ++cov_1ei9xeijve.f[17];
      ++cov_1ei9xeijve.s[58];

      numbersRef.child(uid).remove();
      ++cov_1ei9xeijve.s[59];
      return projectsRef.child(uid).remove();
    }

    function reset() {
      ++cov_1ei9xeijve.f[18];
      ++cov_1ei9xeijve.s[60];

      if (projects) {
        ++cov_1ei9xeijve.b[8][0];
        ++cov_1ei9xeijve.s[61];

        projects.$destroy();
        ++cov_1ei9xeijve.s[62];
        projects = null;
      } else {
        ++cov_1ei9xeijve.b[8][1];
      }
      ++cov_1ei9xeijve.s[63];
      if (activeProjects) {
        ++cov_1ei9xeijve.b[9][0];
        ++cov_1ei9xeijve.s[64];

        activeProjects.$destroy();
        ++cov_1ei9xeijve.s[65];
        activeProjects = null;
      } else {
        ++cov_1ei9xeijve.b[9][1];
      }
      ++cov_1ei9xeijve.s[66];
      if (inactiveProjects) {
        ++cov_1ei9xeijve.b[10][0];
        ++cov_1ei9xeijve.s[67];

        inactiveProjects.$destroy();
        ++cov_1ei9xeijve.s[68];
        inactiveProjects = null;
      } else {
        ++cov_1ei9xeijve.b[10][1];
      }
    }
    ++cov_1ei9xeijve.s[69];
    return service;
  }

  ++cov_1ei9xeijve.s[70];
  angular.module('app').factory('Projects', ['$db', 'Utils', 'Numbers', '$q', '$projects', '$firebaseObject', Projects]);
})();