'use strict';

var cov_1cm43zulnn = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/common/activity/activity.service.js',
      hash = 'e2ff2f0a04b3c140ba0567f83dc02e1d6bc4daac',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/common/activity/activity.service.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 78,
          column: 5
        }
      },
      '1': {
        start: {
          line: 5,
          column: 24
        },
        end: {
          line: 5,
          column: 36
        }
      },
      '2': {
        start: {
          line: 6,
          column: 27
        },
        end: {
          line: 6,
          column: 31
        }
      },
      '3': {
        start: {
          line: 8,
          column: 20
        },
        end: {
          line: 16,
          column: 5
        }
      },
      '4': {
        start: {
          line: 18,
          column: 4
        },
        end: {
          line: 18,
          column: 19
        }
      },
      '5': {
        start: {
          line: 21,
          column: 22
        },
        end: {
          line: 21,
          column: 44
        }
      },
      '6': {
        start: {
          line: 22,
          column: 6
        },
        end: {
          line: 22,
          column: 29
        }
      },
      '7': {
        start: {
          line: 23,
          column: 6
        },
        end: {
          line: 23,
          column: 39
        }
      },
      '8': {
        start: {
          line: 24,
          column: 6
        },
        end: {
          line: 24,
          column: 55
        }
      },
      '9': {
        start: {
          line: 28,
          column: 20
        },
        end: {
          line: 28,
          column: 22
        }
      },
      '10': {
        start: {
          line: 29,
          column: 6
        },
        end: {
          line: 33,
          column: 7
        }
      },
      '11': {
        start: {
          line: 30,
          column: 18
        },
        end: {
          line: 31,
          column: 29
        }
      },
      '12': {
        start: {
          line: 32,
          column: 8
        },
        end: {
          line: 32,
          column: 45
        }
      },
      '13': {
        start: {
          line: 34,
          column: 6
        },
        end: {
          line: 34,
          column: 30
        }
      },
      '14': {
        start: {
          line: 38,
          column: 16
        },
        end: {
          line: 41,
          column: 27
        }
      },
      '15': {
        start: {
          line: 42,
          column: 6
        },
        end: {
          line: 42,
          column: 31
        }
      },
      '16': {
        start: {
          line: 46,
          column: 16
        },
        end: {
          line: 49,
          column: 27
        }
      },
      '17': {
        start: {
          line: 50,
          column: 6
        },
        end: {
          line: 50,
          column: 31
        }
      },
      '18': {
        start: {
          line: 54,
          column: 16
        },
        end: {
          line: 57,
          column: 27
        }
      },
      '19': {
        start: {
          line: 58,
          column: 6
        },
        end: {
          line: 58,
          column: 31
        }
      },
      '20': {
        start: {
          line: 62,
          column: 20
        },
        end: {
          line: 62,
          column: 23
        }
      },
      '21': {
        start: {
          line: 63,
          column: 16
        },
        end: {
          line: 64,
          column: 27
        }
      },
      '22': {
        start: {
          line: 65,
          column: 6
        },
        end: {
          line: 65,
          column: 31
        }
      },
      '23': {
        start: {
          line: 69,
          column: 6
        },
        end: {
          line: 72,
          column: 7
        }
      },
      '24': {
        start: {
          line: 70,
          column: 8
        },
        end: {
          line: 70,
          column: 36
        }
      },
      '25': {
        start: {
          line: 71,
          column: 8
        },
        end: {
          line: 71,
          column: 32
        }
      },
      '26': {
        start: {
          line: 76,
          column: 2
        },
        end: {
          line: 77,
          column: 85
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 78,
            column: 1
          }
        }
      },
      '1': {
        name: 'ActivityService',
        decl: {
          start: {
            line: 4,
            column: 11
          },
          end: {
            line: 4,
            column: 26
          }
        },
        loc: {
          start: {
            line: 4,
            column: 55
          },
          end: {
            line: 74,
            column: 3
          }
        }
      },
      '2': {
        name: 'add',
        decl: {
          start: {
            line: 20,
            column: 13
          },
          end: {
            line: 20,
            column: 16
          }
        },
        loc: {
          start: {
            line: 20,
            column: 27
          },
          end: {
            line: 25,
            column: 5
          }
        }
      },
      '3': {
        name: 'recent',
        decl: {
          start: {
            line: 27,
            column: 13
          },
          end: {
            line: 27,
            column: 19
          }
        },
        loc: {
          start: {
            line: 27,
            column: 22
          },
          end: {
            line: 35,
            column: 5
          }
        }
      },
      '4': {
        name: 'getActivitiesByProject',
        decl: {
          start: {
            line: 37,
            column: 13
          },
          end: {
            line: 37,
            column: 35
          }
        },
        loc: {
          start: {
            line: 37,
            column: 59
          },
          end: {
            line: 43,
            column: 5
          }
        }
      },
      '5': {
        name: 'getActivitiesByTicket',
        decl: {
          start: {
            line: 45,
            column: 13
          },
          end: {
            line: 45,
            column: 34
          }
        },
        loc: {
          start: {
            line: 45,
            column: 57
          },
          end: {
            line: 51,
            column: 5
          }
        }
      },
      '6': {
        name: 'getActivitiesByUser',
        decl: {
          start: {
            line: 53,
            column: 13
          },
          end: {
            line: 53,
            column: 32
          }
        },
        loc: {
          start: {
            line: 53,
            column: 54
          },
          end: {
            line: 59,
            column: 5
          }
        }
      },
      '7': {
        name: 'getActivities',
        decl: {
          start: {
            line: 61,
            column: 13
          },
          end: {
            line: 61,
            column: 26
          }
        },
        loc: {
          start: {
            line: 61,
            column: 29
          },
          end: {
            line: 66,
            column: 5
          }
        }
      },
      '8': {
        name: 'reset',
        decl: {
          start: {
            line: 68,
            column: 13
          },
          end: {
            line: 68,
            column: 18
          }
        },
        loc: {
          start: {
            line: 68,
            column: 21
          },
          end: {
            line: 73,
            column: 5
          }
        }
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 29,
            column: 6
          },
          end: {
            line: 33,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 29,
            column: 6
          },
          end: {
            line: 33,
            column: 7
          }
        }, {
          start: {
            line: 29,
            column: 6
          },
          end: {
            line: 33,
            column: 7
          }
        }]
      },
      '1': {
        loc: {
          start: {
            line: 37,
            column: 47
          },
          end: {
            line: 37,
            column: 57
          }
        },
        type: 'default-arg',
        locations: [{
          start: {
            line: 37,
            column: 55
          },
          end: {
            line: 37,
            column: 57
          }
        }]
      },
      '2': {
        loc: {
          start: {
            line: 45,
            column: 45
          },
          end: {
            line: 45,
            column: 55
          }
        },
        type: 'default-arg',
        locations: [{
          start: {
            line: 45,
            column: 53
          },
          end: {
            line: 45,
            column: 55
          }
        }]
      },
      '3': {
        loc: {
          start: {
            line: 53,
            column: 41
          },
          end: {
            line: 53,
            column: 52
          }
        },
        type: 'default-arg',
        locations: [{
          start: {
            line: 53,
            column: 49
          },
          end: {
            line: 53,
            column: 52
          }
        }]
      },
      '4': {
        loc: {
          start: {
            line: 69,
            column: 6
          },
          end: {
            line: 72,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 69,
            column: 6
          },
          end: {
            line: 72,
            column: 7
          }
        }, {
          start: {
            line: 69,
            column: 6
          },
          end: {
            line: 72,
            column: 7
          }
        }]
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0
    },
    b: {
      '0': [0, 0],
      '1': [0],
      '2': [0],
      '3': [0],
      '4': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_1cm43zulnn.s[0];
(function () {
  ++cov_1cm43zulnn.f[0];

  // define function for Activity
  function ActivityService($db, Utils, $firebaseArray) {
    ++cov_1cm43zulnn.f[1];

    var activityRef = (++cov_1cm43zulnn.s[1], $db.activity);
    var recentActivities = (++cov_1cm43zulnn.s[2], null);
    // service definition
    var service = (++cov_1cm43zulnn.s[3], {
      add: add,
      recent: recent,
      getActivitiesByProject: getActivitiesByProject,
      getActivitiesByTicket: getActivitiesByTicket,
      getActivitiesByUser: getActivitiesByUser,
      getActivities: getActivities,
      reset: reset
    });

    ++cov_1cm43zulnn.s[4];
    return service;

    function add(activity) {
      ++cov_1cm43zulnn.f[2];

      var pushRef = (++cov_1cm43zulnn.s[5], activityRef.push().key);
      ++cov_1cm43zulnn.s[6];
      activity.uid = pushRef;
      ++cov_1cm43zulnn.s[7];
      activity.timestamp = Utils.now();
      ++cov_1cm43zulnn.s[8];
      return $db.activity.child(pushRef).set(activity);
    }

    function recent() {
      ++cov_1cm43zulnn.f[3];

      var limit = (++cov_1cm43zulnn.s[9], 20);
      ++cov_1cm43zulnn.s[10];
      if (!recentActivities) {
        ++cov_1cm43zulnn.b[0][0];

        var q = (++cov_1cm43zulnn.s[11], activityRef.limitToLast(limit));
        ++cov_1cm43zulnn.s[12];
        recentActivities = $firebaseArray(q);
      } else {
        ++cov_1cm43zulnn.b[0][1];
      }
      ++cov_1cm43zulnn.s[13];
      return recentActivities;
    }

    function getActivitiesByProject(projectID) {
      var limit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : (++cov_1cm43zulnn.b[1][0], 30);
      ++cov_1cm43zulnn.f[4];

      var q = (++cov_1cm43zulnn.s[14], activityRef.orderByChild('project').equalTo(projectID).limitToLast(limit));
      ++cov_1cm43zulnn.s[15];
      return $firebaseArray(q);
    }

    function getActivitiesByTicket(ticketID) {
      var limit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : (++cov_1cm43zulnn.b[2][0], 30);
      ++cov_1cm43zulnn.f[5];

      var q = (++cov_1cm43zulnn.s[16], activityRef.orderByChild('ticket').equalTo(ticketID).limitToLast(limit));
      ++cov_1cm43zulnn.s[17];
      return $firebaseArray(q);
    }

    function getActivitiesByUser(UserID) {
      var limit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : (++cov_1cm43zulnn.b[3][0], 100);
      ++cov_1cm43zulnn.f[6];

      var q = (++cov_1cm43zulnn.s[18], activityRef.orderByChild('userId').equalTo(UserID).limitToLast(limit));
      ++cov_1cm43zulnn.s[19];
      return $firebaseArray(q);
    }

    function getActivities() {
      ++cov_1cm43zulnn.f[7];

      var limit = (++cov_1cm43zulnn.s[20], 100);
      var q = (++cov_1cm43zulnn.s[21], activityRef.limitToLast(limit));
      ++cov_1cm43zulnn.s[22];
      return $firebaseArray(q);
    }

    function reset() {
      ++cov_1cm43zulnn.f[8];
      ++cov_1cm43zulnn.s[23];

      if (recentActivities) {
        ++cov_1cm43zulnn.b[4][0];
        ++cov_1cm43zulnn.s[24];

        recentActivities.$destroy();
        ++cov_1cm43zulnn.s[25];
        recentActivities = null;
      } else {
        ++cov_1cm43zulnn.b[4][1];
      }
    }
  }

  ++cov_1cm43zulnn.s[26];
  angular.module('dc-activity').factory('ActivityService', ['$db', 'Utils', '$firebaseArray', ActivityService]);
})();