'use strict';

var cov_2q33eoqqj5 = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/common/customer/sites/site-list-widget/site-list-widget.component.js',
      hash = '956354f60d1cfb577c810e26576886dbd5bcfe4c',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/common/customer/sites/site-list-widget/site-list-widget.component.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 42,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 25
        },
        end: {
          line: 10,
          column: 3
        }
      },
      '2': {
        start: {
          line: 12,
          column: 2
        },
        end: {
          line: 12,
          column: 39
        }
      },
      '3': {
        start: {
          line: 15,
          column: 17
        },
        end: {
          line: 15,
          column: 21
        }
      },
      '4': {
        start: {
          line: 17,
          column: 4
        },
        end: {
          line: 17,
          column: 25
        }
      },
      '5': {
        start: {
          line: 18,
          column: 4
        },
        end: {
          line: 18,
          column: 40
        }
      },
      '6': {
        start: {
          line: 19,
          column: 4
        },
        end: {
          line: 19,
          column: 39
        }
      },
      '7': {
        start: {
          line: 20,
          column: 4
        },
        end: {
          line: 20,
          column: 32
        }
      },
      '8': {
        start: {
          line: 21,
          column: 4
        },
        end: {
          line: 21,
          column: 32
        }
      },
      '9': {
        start: {
          line: 24,
          column: 6
        },
        end: {
          line: 24,
          column: 53
        }
      },
      '10': {
        start: {
          line: 28,
          column: 6
        },
        end: {
          line: 33,
          column: 7
        }
      },
      '11': {
        start: {
          line: 29,
          column: 8
        },
        end: {
          line: 32,
          column: 13
        }
      },
      '12': {
        start: {
          line: 31,
          column: 12
        },
        end: {
          line: 31,
          column: 31
        }
      },
      '13': {
        start: {
          line: 37,
          column: 6
        },
        end: {
          line: 37,
          column: 28
        }
      },
      '14': {
        start: {
          line: 41,
          column: 2
        },
        end: {
          line: 41,
          column: 76
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 42,
            column: 1
          }
        }
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 14,
            column: 11
          },
          end: {
            line: 14,
            column: 21
          }
        },
        loc: {
          start: {
            line: 14,
            column: 35
          },
          end: {
            line: 39,
            column: 3
          }
        }
      },
      '2': {
        name: 'numberOfPages',
        decl: {
          start: {
            line: 23,
            column: 13
          },
          end: {
            line: 23,
            column: 26
          }
        },
        loc: {
          start: {
            line: 23,
            column: 34
          },
          end: {
            line: 25,
            column: 5
          }
        }
      },
      '3': {
        name: 'onChanges',
        decl: {
          start: {
            line: 27,
            column: 13
          },
          end: {
            line: 27,
            column: 22
          }
        },
        loc: {
          start: {
            line: 27,
            column: 35
          },
          end: {
            line: 34,
            column: 5
          }
        }
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 30,
            column: 19
          },
          end: {
            line: 30,
            column: 20
          }
        },
        loc: {
          start: {
            line: 30,
            column: 28
          },
          end: {
            line: 32,
            column: 11
          }
        }
      },
      '5': {
        name: 'onDestroy',
        decl: {
          start: {
            line: 36,
            column: 13
          },
          end: {
            line: 36,
            column: 22
          }
        },
        loc: {
          start: {
            line: 36,
            column: 25
          },
          end: {
            line: 38,
            column: 5
          }
        }
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 18,
            column: 20
          },
          end: {
            line: 18,
            column: 39
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 18,
            column: 20
          },
          end: {
            line: 18,
            column: 33
          }
        }, {
          start: {
            line: 18,
            column: 37
          },
          end: {
            line: 18,
            column: 39
          }
        }]
      },
      '1': {
        loc: {
          start: {
            line: 28,
            column: 6
          },
          end: {
            line: 33,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 28,
            column: 6
          },
          end: {
            line: 33,
            column: 7
          }
        }, {
          start: {
            line: 28,
            column: 6
          },
          end: {
            line: 33,
            column: 7
          }
        }]
      },
      '2': {
        loc: {
          start: {
            line: 28,
            column: 10
          },
          end: {
            line: 28,
            column: 61
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 28,
            column: 10
          },
          end: {
            line: 28,
            column: 23
          }
        }, {
          start: {
            line: 28,
            column: 27
          },
          end: {
            line: 28,
            column: 61
          }
        }]
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_2q33eoqqj5.s[0];
(function () {
  ++cov_2q33eoqqj5.f[0];

  var siteListWidget = (++cov_2q33eoqqj5.s[1], {
    bindings: {
      customerId: '<',
      pageSize: '<'
    },
    templateUrl: 'app/common/customer/sites/site-list-widget/site-list-widget.html',
    controller: controller
  });

  ++cov_2q33eoqqj5.s[2];
  controller.$inject = ['SiteService'];

  function controller(SiteService) {
    ++cov_2q33eoqqj5.f[1];

    var self = (++cov_2q33eoqqj5.s[3], this);

    ++cov_2q33eoqqj5.s[4];
    self.currentPage = 0;
    ++cov_2q33eoqqj5.s[5];
    self.pageSize = (++cov_2q33eoqqj5.b[0][0], self.pageSize) || (++cov_2q33eoqqj5.b[0][1], 10);
    ++cov_2q33eoqqj5.s[6];
    self.numberOfPages = numberOfPages;
    ++cov_2q33eoqqj5.s[7];
    self.$onChanges = onChanges;
    ++cov_2q33eoqqj5.s[8];
    self.$onDestroy = onDestroy;

    function numberOfPages(array) {
      ++cov_2q33eoqqj5.f[2];
      ++cov_2q33eoqqj5.s[9];

      return Math.ceil(array.length / self.pageSize);
    }

    function onChanges(prev, next) {
      ++cov_2q33eoqqj5.f[3];
      ++cov_2q33eoqqj5.s[10];

      if ((++cov_2q33eoqqj5.b[2][0], next !== prev) && (++cov_2q33eoqqj5.b[2][1], angular.isDefined(self.customerId))) {
        ++cov_2q33eoqqj5.b[1][0];
        ++cov_2q33eoqqj5.s[11];

        SiteService.getByCustomer(self.customerId).$loaded(function (sites) {
          ++cov_2q33eoqqj5.f[4];
          ++cov_2q33eoqqj5.s[12];

          self.sites = sites;
        });
      } else {
        ++cov_2q33eoqqj5.b[1][1];
      }
    }

    function onDestroy() {
      ++cov_2q33eoqqj5.f[5];
      ++cov_2q33eoqqj5.s[13];

      self.sites.$destroy();
    }
  }

  ++cov_2q33eoqqj5.s[14];
  angular.module('dc-customer').component('siteListWidget', siteListWidget);
})();