'use strict';

var cov_2gy1qchxh5 = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/services/storage.js',
      hash = 'c2ba78e21aceb19ce3cbeba8f1a1978efa1cedf6',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/services/storage.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 23,
          column: 5
        }
      },
      '1': {
        start: {
          line: 4,
          column: 23
        },
        end: {
          line: 4,
          column: 47
        }
      },
      '2': {
        start: {
          line: 5,
          column: 18
        },
        end: {
          line: 5,
          column: 22
        }
      },
      '3': {
        start: {
          line: 7,
          column: 20
        },
        end: {
          line: 9,
          column: 5
        }
      },
      '4': {
        start: {
          line: 11,
          column: 4
        },
        end: {
          line: 11,
          column: 19
        }
      },
      '5': {
        start: {
          line: 14,
          column: 6
        },
        end: {
          line: 16,
          column: 7
        }
      },
      '6': {
        start: {
          line: 15,
          column: 8
        },
        end: {
          line: 15,
          column: 74
        }
      },
      '7': {
        start: {
          line: 17,
          column: 6
        },
        end: {
          line: 17,
          column: 21
        }
      },
      '8': {
        start: {
          line: 21,
          column: 2
        },
        end: {
          line: 22,
          column: 55
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 23,
            column: 1
          }
        }
      },
      '1': {
        name: 'Storage',
        decl: {
          start: {
            line: 3,
            column: 11
          },
          end: {
            line: 3,
            column: 18
          }
        },
        loc: {
          start: {
            line: 3,
            column: 37
          },
          end: {
            line: 19,
            column: 3
          }
        }
      },
      '2': {
        name: 'getStorage',
        decl: {
          start: {
            line: 13,
            column: 13
          },
          end: {
            line: 13,
            column: 23
          }
        },
        loc: {
          start: {
            line: 13,
            column: 39
          },
          end: {
            line: 18,
            column: 5
          }
        }
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 14,
            column: 6
          },
          end: {
            line: 16,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 14,
            column: 6
          },
          end: {
            line: 16,
            column: 7
          }
        }, {
          start: {
            line: 14,
            column: 6
          },
          end: {
            line: 16,
            column: 7
          }
        }]
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0
    },
    b: {
      '0': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_2gy1qchxh5.s[0];
(function () {
  ++cov_2gy1qchxh5.f[0];

  function Storage($firebaseStorage) {
    ++cov_2gy1qchxh5.f[1];

    var storageRef = (++cov_2gy1qchxh5.s[1], firebase.storage().ref());
    var storage = (++cov_2gy1qchxh5.s[2], null);

    var service = (++cov_2gy1qchxh5.s[3], {
      getStorage: getStorage
    });

    ++cov_2gy1qchxh5.s[4];
    return service;

    function getStorage(uid, fileName) {
      ++cov_2gy1qchxh5.f[2];
      ++cov_2gy1qchxh5.s[5];

      if (!storage) {
        ++cov_2gy1qchxh5.b[0][0];
        ++cov_2gy1qchxh5.s[6];

        storage = $firebaseStorage(storageRef.child(uid).child(fileName));
      } else {
        ++cov_2gy1qchxh5.b[0][1];
      }
      ++cov_2gy1qchxh5.s[7];
      return storage;
    }
  }

  ++cov_2gy1qchxh5.s[8];
  angular.module('app').factory('Storage', ['$firebaseStorage', Storage]);
})();