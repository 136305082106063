'use strict';

var cov_280sipggqj = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/service/ticket.service.js',
      hash = 'b183c88da5646216178855442fc4190d2732435a',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/service/ticket.service.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 190,
          column: 5
        }
      },
      '1': {
        start: {
          line: 14,
          column: 22
        },
        end: {
          line: 14,
          column: 33
        }
      },
      '2': {
        start: {
          line: 15,
          column: 22
        },
        end: {
          line: 15,
          column: 32
        }
      },
      '3': {
        start: {
          line: 16,
          column: 24
        },
        end: {
          line: 16,
          column: 59
        }
      },
      '4': {
        start: {
          line: 18,
          column: 18
        },
        end: {
          line: 18,
          column: 22
        }
      },
      '5': {
        start: {
          line: 19,
          column: 22
        },
        end: {
          line: 19,
          column: 26
        }
      },
      '6': {
        start: {
          line: 20,
          column: 24
        },
        end: {
          line: 20,
          column: 28
        }
      },
      '7': {
        start: {
          line: 22,
          column: 20
        },
        end: {
          line: 34,
          column: 5
        }
      },
      '8': {
        start: {
          line: 37,
          column: 6
        },
        end: {
          line: 41,
          column: 20
        }
      },
      '9': {
        start: {
          line: 39,
          column: 10
        },
        end: {
          line: 39,
          column: 39
        }
      },
      '10': {
        start: {
          line: 45,
          column: 6
        },
        end: {
          line: 48,
          column: 7
        }
      },
      '11': {
        start: {
          line: 46,
          column: 18
        },
        end: {
          line: 46,
          column: 46
        }
      },
      '12': {
        start: {
          line: 47,
          column: 8
        },
        end: {
          line: 47,
          column: 36
        }
      },
      '13': {
        start: {
          line: 49,
          column: 6
        },
        end: {
          line: 49,
          column: 21
        }
      },
      '14': {
        start: {
          line: 53,
          column: 16
        },
        end: {
          line: 53,
          column: 68
        }
      },
      '15': {
        start: {
          line: 54,
          column: 6
        },
        end: {
          line: 54,
          column: 31
        }
      },
      '16': {
        start: {
          line: 58,
          column: 16
        },
        end: {
          line: 58,
          column: 70
        }
      },
      '17': {
        start: {
          line: 59,
          column: 6
        },
        end: {
          line: 59,
          column: 31
        }
      },
      '18': {
        start: {
          line: 63,
          column: 16
        },
        end: {
          line: 63,
          column: 68
        }
      },
      '19': {
        start: {
          line: 64,
          column: 6
        },
        end: {
          line: 64,
          column: 31
        }
      },
      '20': {
        start: {
          line: 74,
          column: 6
        },
        end: {
          line: 80,
          column: 7
        }
      },
      '21': {
        start: {
          line: 75,
          column: 8
        },
        end: {
          line: 78,
          column: 9
        }
      },
      '22': {
        start: {
          line: 76,
          column: 20
        },
        end: {
          line: 76,
          column: 65
        }
      },
      '23': {
        start: {
          line: 77,
          column: 10
        },
        end: {
          line: 77,
          column: 44
        }
      },
      '24': {
        start: {
          line: 79,
          column: 8
        },
        end: {
          line: 79,
          column: 29
        }
      },
      '25': {
        start: {
          line: 81,
          column: 16
        },
        end: {
          line: 81,
          column: 60
        }
      },
      '26': {
        start: {
          line: 82,
          column: 6
        },
        end: {
          line: 82,
          column: 38
        }
      },
      '27': {
        start: {
          line: 83,
          column: 6
        },
        end: {
          line: 83,
          column: 25
        }
      },
      '28': {
        start: {
          line: 87,
          column: 6
        },
        end: {
          line: 87,
          column: 51
        }
      },
      '29': {
        start: {
          line: 91,
          column: 20
        },
        end: {
          line: 91,
          column: 30
        }
      },
      '30': {
        start: {
          line: 92,
          column: 6
        },
        end: {
          line: 99,
          column: 9
        }
      },
      '31': {
        start: {
          line: 93,
          column: 8
        },
        end: {
          line: 95,
          column: 9
        }
      },
      '32': {
        start: {
          line: 94,
          column: 10
        },
        end: {
          line: 94,
          column: 41
        }
      },
      '33': {
        start: {
          line: 96,
          column: 8
        },
        end: {
          line: 98,
          column: 11
        }
      },
      '34': {
        start: {
          line: 97,
          column: 10
        },
        end: {
          line: 97,
          column: 55
        }
      },
      '35': {
        start: {
          line: 100,
          column: 6
        },
        end: {
          line: 100,
          column: 27
        }
      },
      '36': {
        start: {
          line: 104,
          column: 6
        },
        end: {
          line: 104,
          column: 21
        }
      },
      '37': {
        start: {
          line: 105,
          column: 20
        },
        end: {
          line: 105,
          column: 30
        }
      },
      '38': {
        start: {
          line: 106,
          column: 21
        },
        end: {
          line: 119,
          column: 7
        }
      },
      '39': {
        start: {
          line: 120,
          column: 6
        },
        end: {
          line: 123,
          column: 7
        }
      },
      '40': {
        start: {
          line: 121,
          column: 8
        },
        end: {
          line: 121,
          column: 44
        }
      },
      '41': {
        start: {
          line: 122,
          column: 8
        },
        end: {
          line: 122,
          column: 31
        }
      },
      '42': {
        start: {
          line: 125,
          column: 6
        },
        end: {
          line: 144,
          column: 9
        }
      },
      '43': {
        start: {
          line: 126,
          column: 8
        },
        end: {
          line: 126,
          column: 47
        }
      },
      '44': {
        start: {
          line: 127,
          column: 24
        },
        end: {
          line: 127,
          column: 44
        }
      },
      '45': {
        start: {
          line: 128,
          column: 8
        },
        end: {
          line: 128,
          column: 29
        }
      },
      '46': {
        start: {
          line: 129,
          column: 8
        },
        end: {
          line: 129,
          column: 54
        }
      },
      '47': {
        start: {
          line: 130,
          column: 8
        },
        end: {
          line: 143,
          column: 11
        }
      },
      '48': {
        start: {
          line: 131,
          column: 10
        },
        end: {
          line: 133,
          column: 11
        }
      },
      '49': {
        start: {
          line: 132,
          column: 12
        },
        end: {
          line: 132,
          column: 37
        }
      },
      '50': {
        start: {
          line: 134,
          column: 27
        },
        end: {
          line: 140,
          column: 11
        }
      },
      '51': {
        start: {
          line: 141,
          column: 10
        },
        end: {
          line: 141,
          column: 40
        }
      },
      '52': {
        start: {
          line: 142,
          column: 10
        },
        end: {
          line: 142,
          column: 39
        }
      },
      '53': {
        start: {
          line: 145,
          column: 6
        },
        end: {
          line: 145,
          column: 27
        }
      },
      '54': {
        start: {
          line: 149,
          column: 23
        },
        end: {
          line: 155,
          column: 7
        }
      },
      '55': {
        start: {
          line: 156,
          column: 6
        },
        end: {
          line: 156,
          column: 36
        }
      },
      '56': {
        start: {
          line: 157,
          column: 6
        },
        end: {
          line: 157,
          column: 50
        }
      },
      '57': {
        start: {
          line: 161,
          column: 6
        },
        end: {
          line: 164,
          column: 7
        }
      },
      '58': {
        start: {
          line: 162,
          column: 8
        },
        end: {
          line: 162,
          column: 27
        }
      },
      '59': {
        start: {
          line: 163,
          column: 8
        },
        end: {
          line: 163,
          column: 23
        }
      },
      '60': {
        start: {
          line: 165,
          column: 6
        },
        end: {
          line: 168,
          column: 7
        }
      },
      '61': {
        start: {
          line: 166,
          column: 8
        },
        end: {
          line: 166,
          column: 31
        }
      },
      '62': {
        start: {
          line: 167,
          column: 8
        },
        end: {
          line: 167,
          column: 27
        }
      },
      '63': {
        start: {
          line: 169,
          column: 6
        },
        end: {
          line: 172,
          column: 7
        }
      },
      '64': {
        start: {
          line: 170,
          column: 8
        },
        end: {
          line: 170,
          column: 33
        }
      },
      '65': {
        start: {
          line: 171,
          column: 8
        },
        end: {
          line: 171,
          column: 29
        }
      },
      '66': {
        start: {
          line: 174,
          column: 4
        },
        end: {
          line: 174,
          column: 19
        }
      },
      '67': {
        start: {
          line: 177,
          column: 2
        },
        end: {
          line: 189,
          column: 7
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 190,
            column: 1
          }
        }
      },
      '1': {
        name: 'TicketService',
        decl: {
          start: {
            line: 4,
            column: 11
          },
          end: {
            line: 4,
            column: 24
          }
        },
        loc: {
          start: {
            line: 13,
            column: 4
          },
          end: {
            line: 175,
            column: 3
          }
        }
      },
      '2': {
        name: 'ticketID',
        decl: {
          start: {
            line: 36,
            column: 13
          },
          end: {
            line: 36,
            column: 21
          }
        },
        loc: {
          start: {
            line: 36,
            column: 28
          },
          end: {
            line: 42,
            column: 5
          }
        }
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 38,
            column: 21
          },
          end: {
            line: 38,
            column: 22
          }
        },
        loc: {
          start: {
            line: 38,
            column: 32
          },
          end: {
            line: 40,
            column: 9
          }
        }
      },
      '4': {
        name: '$getAll',
        decl: {
          start: {
            line: 44,
            column: 13
          },
          end: {
            line: 44,
            column: 20
          }
        },
        loc: {
          start: {
            line: 44,
            column: 23
          },
          end: {
            line: 50,
            column: 5
          }
        }
      },
      '5': {
        name: '$getByUser',
        decl: {
          start: {
            line: 52,
            column: 13
          },
          end: {
            line: 52,
            column: 23
          }
        },
        loc: {
          start: {
            line: 52,
            column: 32
          },
          end: {
            line: 55,
            column: 5
          }
        }
      },
      '6': {
        name: '$getByCustomer',
        decl: {
          start: {
            line: 57,
            column: 13
          },
          end: {
            line: 57,
            column: 27
          }
        },
        loc: {
          start: {
            line: 57,
            column: 40
          },
          end: {
            line: 60,
            column: 5
          }
        }
      },
      '7': {
        name: '$getByProject',
        decl: {
          start: {
            line: 62,
            column: 13
          },
          end: {
            line: 62,
            column: 26
          }
        },
        loc: {
          start: {
            line: 62,
            column: 38
          },
          end: {
            line: 65,
            column: 5
          }
        }
      },
      '8': {
        name: '$getByStatus',
        decl: {
          start: {
            line: 73,
            column: 13
          },
          end: {
            line: 73,
            column: 25
          }
        },
        loc: {
          start: {
            line: 73,
            column: 34
          },
          end: {
            line: 84,
            column: 5
          }
        }
      },
      '9': {
        name: '$getByUid',
        decl: {
          start: {
            line: 86,
            column: 13
          },
          end: {
            line: 86,
            column: 22
          }
        },
        loc: {
          start: {
            line: 86,
            column: 28
          },
          end: {
            line: 88,
            column: 5
          }
        }
      },
      '10': {
        name: '$getByid',
        decl: {
          start: {
            line: 90,
            column: 13
          },
          end: {
            line: 90,
            column: 21
          }
        },
        loc: {
          start: {
            line: 90,
            column: 26
          },
          end: {
            line: 101,
            column: 5
          }
        }
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 92,
            column: 66
          },
          end: {
            line: 92,
            column: 67
          }
        },
        loc: {
          start: {
            line: 92,
            column: 74
          },
          end: {
            line: 99,
            column: 7
          }
        }
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 96,
            column: 21
          },
          end: {
            line: 96,
            column: 22
          }
        },
        loc: {
          start: {
            line: 96,
            column: 31
          },
          end: {
            line: 98,
            column: 9
          }
        }
      },
      '13': {
        name: 'create',
        decl: {
          start: {
            line: 103,
            column: 13
          },
          end: {
            line: 103,
            column: 19
          }
        },
        loc: {
          start: {
            line: 103,
            column: 26
          },
          end: {
            line: 146,
            column: 5
          }
        }
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 125,
            column: 15
          },
          end: {
            line: 125,
            column: 16
          }
        },
        loc: {
          start: {
            line: 125,
            column: 30
          },
          end: {
            line: 144,
            column: 7
          }
        }
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 130,
            column: 45
          },
          end: {
            line: 130,
            column: 46
          }
        },
        loc: {
          start: {
            line: 130,
            column: 52
          },
          end: {
            line: 143,
            column: 9
          }
        }
      },
      '16': {
        name: 'remove',
        decl: {
          start: {
            line: 148,
            column: 13
          },
          end: {
            line: 148,
            column: 19
          }
        },
        loc: {
          start: {
            line: 148,
            column: 28
          },
          end: {
            line: 158,
            column: 5
          }
        }
      },
      '17': {
        name: 'reset',
        decl: {
          start: {
            line: 160,
            column: 13
          },
          end: {
            line: 160,
            column: 18
          }
        },
        loc: {
          start: {
            line: 160,
            column: 21
          },
          end: {
            line: 173,
            column: 5
          }
        }
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 39,
            column: 18
          },
          end: {
            line: 39,
            column: 33
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 39,
            column: 18
          },
          end: {
            line: 39,
            column: 25
          }
        }, {
          start: {
            line: 39,
            column: 29
          },
          end: {
            line: 39,
            column: 33
          }
        }]
      },
      '1': {
        loc: {
          start: {
            line: 45,
            column: 6
          },
          end: {
            line: 48,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 45,
            column: 6
          },
          end: {
            line: 48,
            column: 7
          }
        }, {
          start: {
            line: 45,
            column: 6
          },
          end: {
            line: 48,
            column: 7
          }
        }]
      },
      '2': {
        loc: {
          start: {
            line: 74,
            column: 6
          },
          end: {
            line: 80,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 74,
            column: 6
          },
          end: {
            line: 80,
            column: 7
          }
        }, {
          start: {
            line: 74,
            column: 6
          },
          end: {
            line: 80,
            column: 7
          }
        }]
      },
      '3': {
        loc: {
          start: {
            line: 75,
            column: 8
          },
          end: {
            line: 78,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 75,
            column: 8
          },
          end: {
            line: 78,
            column: 9
          }
        }, {
          start: {
            line: 75,
            column: 8
          },
          end: {
            line: 78,
            column: 9
          }
        }]
      },
      '4': {
        loc: {
          start: {
            line: 93,
            column: 8
          },
          end: {
            line: 95,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 93,
            column: 8
          },
          end: {
            line: 95,
            column: 9
          }
        }, {
          start: {
            line: 93,
            column: 8
          },
          end: {
            line: 95,
            column: 9
          }
        }]
      },
      '5': {
        loc: {
          start: {
            line: 107,
            column: 14
          },
          end: {
            line: 107,
            column: 31
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 107,
            column: 14
          },
          end: {
            line: 107,
            column: 23
          }
        }, {
          start: {
            line: 107,
            column: 27
          },
          end: {
            line: 107,
            column: 31
          }
        }]
      },
      '6': {
        loc: {
          start: {
            line: 108,
            column: 21
          },
          end: {
            line: 108,
            column: 45
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 108,
            column: 21
          },
          end: {
            line: 108,
            column: 37
          }
        }, {
          start: {
            line: 108,
            column: 41
          },
          end: {
            line: 108,
            column: 45
          }
        }]
      },
      '7': {
        loc: {
          start: {
            line: 114,
            column: 18
          },
          end: {
            line: 114,
            column: 41
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 114,
            column: 18
          },
          end: {
            line: 114,
            column: 33
          }
        }, {
          start: {
            line: 114,
            column: 37
          },
          end: {
            line: 114,
            column: 41
          }
        }]
      },
      '8': {
        loc: {
          start: {
            line: 115,
            column: 17
          },
          end: {
            line: 115,
            column: 39
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 115,
            column: 17
          },
          end: {
            line: 115,
            column: 31
          }
        }, {
          start: {
            line: 115,
            column: 35
          },
          end: {
            line: 115,
            column: 39
          }
        }]
      },
      '9': {
        loc: {
          start: {
            line: 116,
            column: 14
          },
          end: {
            line: 116,
            column: 31
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 116,
            column: 14
          },
          end: {
            line: 116,
            column: 23
          }
        }, {
          start: {
            line: 116,
            column: 27
          },
          end: {
            line: 116,
            column: 31
          }
        }]
      },
      '10': {
        loc: {
          start: {
            line: 118,
            column: 19
          },
          end: {
            line: 118,
            column: 41
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 118,
            column: 19
          },
          end: {
            line: 118,
            column: 33
          }
        }, {
          start: {
            line: 118,
            column: 37
          },
          end: {
            line: 118,
            column: 41
          }
        }]
      },
      '11': {
        loc: {
          start: {
            line: 120,
            column: 6
          },
          end: {
            line: 123,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 120,
            column: 6
          },
          end: {
            line: 123,
            column: 7
          }
        }, {
          start: {
            line: 120,
            column: 6
          },
          end: {
            line: 123,
            column: 7
          }
        }]
      },
      '12': {
        loc: {
          start: {
            line: 131,
            column: 10
          },
          end: {
            line: 133,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 131,
            column: 10
          },
          end: {
            line: 133,
            column: 11
          }
        }, {
          start: {
            line: 131,
            column: 10
          },
          end: {
            line: 133,
            column: 11
          }
        }]
      },
      '13': {
        loc: {
          start: {
            line: 161,
            column: 6
          },
          end: {
            line: 164,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 161,
            column: 6
          },
          end: {
            line: 164,
            column: 7
          }
        }, {
          start: {
            line: 161,
            column: 6
          },
          end: {
            line: 164,
            column: 7
          }
        }]
      },
      '14': {
        loc: {
          start: {
            line: 165,
            column: 6
          },
          end: {
            line: 168,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 165,
            column: 6
          },
          end: {
            line: 168,
            column: 7
          }
        }, {
          start: {
            line: 165,
            column: 6
          },
          end: {
            line: 168,
            column: 7
          }
        }]
      },
      '15': {
        loc: {
          start: {
            line: 169,
            column: 6
          },
          end: {
            line: 172,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 169,
            column: 6
          },
          end: {
            line: 172,
            column: 7
          }
        }, {
          start: {
            line: 169,
            column: 6
          },
          end: {
            line: 172,
            column: 7
          }
        }]
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_280sipggqj.s[0];
(function () {
  ++cov_280sipggqj.f[0];

  // defines function Tickets
  function TicketService($db, Utils, $firebaseArray, $firebaseObject, $q, $log, ActivityService, Users) {
    ++cov_280sipggqj.f[1];

    var ticketRef = (++cov_280sipggqj.s[1], $db.tickets);
    var queuesRef = (++cov_280sipggqj.s[2], $db.queues);
    var ticketIdRef = (++cov_280sipggqj.s[3], $db.root.child('tracked/ticket-id'));

    var tickets = (++cov_280sipggqj.s[4], null);
    var openTickets = (++cov_280sipggqj.s[5], null);
    var closedTickets = (++cov_280sipggqj.s[6], null);

    var service = (++cov_280sipggqj.s[7], {
      $getAll: $getAll,
      $getByUser: $getByUser,
      $getByCustomer: $getByCustomer,
      $getByProject: $getByProject,
      $getByStatus: $getByStatus,
      // $getTicketMetadata,
      $getByUid: $getByUid,
      $getByid: $getByid,
      create: create,
      remove: remove,
      reset: reset
    });

    function ticketID(done) {
      ++cov_280sipggqj.f[2];
      ++cov_280sipggqj.s[8];

      ticketIdRef.transaction(function (current) {
        ++cov_280sipggqj.f[3];
        ++cov_280sipggqj.s[9];

        return ((++cov_280sipggqj.b[0][0], current) || (++cov_280sipggqj.b[0][1], 2000)) + 1;
      }).then(done);
    }

    function $getAll() {
      ++cov_280sipggqj.f[4];
      ++cov_280sipggqj.s[10];

      if (!tickets) {
        ++cov_280sipggqj.b[1][0];

        var q = (++cov_280sipggqj.s[11], ticketRef.orderByChild('id'));
        ++cov_280sipggqj.s[12];
        tickets = $firebaseArray(q);
      } else {
        ++cov_280sipggqj.b[1][1];
      }
      ++cov_280sipggqj.s[13];
      return tickets;
    }

    function $getByUser(userId) {
      ++cov_280sipggqj.f[5];

      var q = (++cov_280sipggqj.s[14], ticketRef.orderByChild('assignedTo').equalTo(userId));
      ++cov_280sipggqj.s[15];
      return $firebaseArray(q);
    }

    function $getByCustomer(customerID) {
      ++cov_280sipggqj.f[6];

      var q = (++cov_280sipggqj.s[16], ticketRef.orderByChild('customer').equalTo(customerID));
      ++cov_280sipggqj.s[17];
      return $firebaseArray(q);
    }

    function $getByProject(projectID) {
      ++cov_280sipggqj.f[7];

      var q = (++cov_280sipggqj.s[18], ticketRef.orderByChild('project').equalTo(projectID));
      ++cov_280sipggqj.s[19];
      return $firebaseArray(q);
    }

    // function $getRecent() {
    //   const limit = 20;
    //   const q = ticketRef.orderByChild('opened').limitToLast(limit);
    //   return $firebaseArray(q);
    // }

    function $getByStatus(status) {
      ++cov_280sipggqj.f[8];
      ++cov_280sipggqj.s[20];

      if (!status) {
        ++cov_280sipggqj.b[2][0];
        ++cov_280sipggqj.s[21];

        if (!closedTickets) {
          ++cov_280sipggqj.b[3][0];

          var _q = (++cov_280sipggqj.s[22], ticketRef.orderByChild('open').equalTo(false));
          ++cov_280sipggqj.s[23];
          closedTickets = $firebaseArray(_q);
        } else {
          ++cov_280sipggqj.b[3][1];
        }
        ++cov_280sipggqj.s[24];
        return closedTickets;
      } else {
        ++cov_280sipggqj.b[2][1];
      }
      var q = (++cov_280sipggqj.s[25], ticketRef.orderByChild('open').equalTo(true));
      ++cov_280sipggqj.s[26];
      openTickets = $firebaseArray(q);
      ++cov_280sipggqj.s[27];
      return openTickets;
    }

    function $getByUid(uid) {
      ++cov_280sipggqj.f[9];
      ++cov_280sipggqj.s[28];

      return $firebaseObject(ticketRef.child(uid));
    }

    function $getByid(id) {
      ++cov_280sipggqj.f[10];

      var defer = (++cov_280sipggqj.s[29], $q.defer());
      ++cov_280sipggqj.s[30];
      ticketRef.orderByChild('id').equalTo(id).once('value').then(function (snap) {
        ++cov_280sipggqj.f[11];
        ++cov_280sipggqj.s[31];

        if (!snap.val()) {
          ++cov_280sipggqj.b[4][0];
          ++cov_280sipggqj.s[32];

          defer.reject('Does Not Exist');
        } else {
          ++cov_280sipggqj.b[4][1];
        }
        ++cov_280sipggqj.s[33];
        snap.forEach(function (ticket) {
          ++cov_280sipggqj.f[12];
          ++cov_280sipggqj.s[34];

          defer.resolve(service.$getByUid(ticket.key));
        });
      });
      ++cov_280sipggqj.s[35];
      return defer.promise;
    }

    function create(data) {
      ++cov_280sipggqj.f[13];
      ++cov_280sipggqj.s[36];

      $log.log(data);
      var defer = (++cov_280sipggqj.s[37], $q.defer());
      var ticket = (++cov_280sipggqj.s[38], {
        name: (++cov_280sipggqj.b[5][0], data.name) || (++cov_280sipggqj.b[5][1], null),
        description: (++cov_280sipggqj.b[6][0], data.description) || (++cov_280sipggqj.b[6][1], null),
        created: Utils.now(),
        createdBy: Users.currentUser().$id,
        assigned: false,
        open: true,
        hours: 0,
        customer: (++cov_280sipggqj.b[7][0], data.customerId) || (++cov_280sipggqj.b[7][1], null),
        project: (++cov_280sipggqj.b[8][0], data.projectId) || (++cov_280sipggqj.b[8][1], null),
        site: (++cov_280sipggqj.b[9][0], data.site) || (++cov_280sipggqj.b[9][1], null),
        priority: data.priority,
        reference: (++cov_280sipggqj.b[10][0], data.reference) || (++cov_280sipggqj.b[10][1], null)
      });
      ++cov_280sipggqj.s[39];
      if (data.assignedTo) {
        ++cov_280sipggqj.b[11][0];
        ++cov_280sipggqj.s[40];

        ticket.assignedTo = data.assignedTo;
        ++cov_280sipggqj.s[41];
        ticket.assigned = true;
      } else {
        ++cov_280sipggqj.b[11][1];
      }

      ++cov_280sipggqj.s[42];
      ticketID(function (transaction) {
        ++cov_280sipggqj.f[14];
        ++cov_280sipggqj.s[43];

        ticket.id = transaction.snapshot.val();
        var pushRef = (++cov_280sipggqj.s[44], ticketRef.push().key);
        ++cov_280sipggqj.s[45];
        ticket.uid = pushRef;
        ++cov_280sipggqj.s[46];
        queuesRef.child('create-ticket').push(ticket);
        ++cov_280sipggqj.s[47];
        ticketRef.child(pushRef).set(ticket, function (err) {
          ++cov_280sipggqj.f[15];
          ++cov_280sipggqj.s[48];

          if (err) {
            ++cov_280sipggqj.b[12][0];
            ++cov_280sipggqj.s[49];

            return defer.reject(err);
          } else {
            ++cov_280sipggqj.b[12][1];
          }
          var activity = (++cov_280sipggqj.s[50], {
            userId: Users.currentUser().$id,
            type: 'Ticket',
            action: 'Created',
            ticket: ticket.uid,
            ticketId: ticket.id
          });
          ++cov_280sipggqj.s[51];
          ActivityService.add(activity);
          ++cov_280sipggqj.s[52];
          return defer.resolve(ticket);
        });
      });
      ++cov_280sipggqj.s[53];
      return defer.promise;
    }

    function remove(ticket) {
      ++cov_280sipggqj.f[16];

      var activity = (++cov_280sipggqj.s[54], {
        userId: Users.currentUser().$id,
        type: 'Ticket',
        action: 'Deleted',
        ticket: ticket.uid,
        ticketId: ticket.id
      });
      ++cov_280sipggqj.s[55];
      ActivityService.add(activity);
      ++cov_280sipggqj.s[56];
      return ticketRef.child(ticket.$id).remove();
    }

    function reset() {
      ++cov_280sipggqj.f[17];
      ++cov_280sipggqj.s[57];

      if (tickets) {
        ++cov_280sipggqj.b[13][0];
        ++cov_280sipggqj.s[58];

        tickets.$destroy();
        ++cov_280sipggqj.s[59];
        tickets = null;
      } else {
        ++cov_280sipggqj.b[13][1];
      }
      ++cov_280sipggqj.s[60];
      if (openTickets) {
        ++cov_280sipggqj.b[14][0];
        ++cov_280sipggqj.s[61];

        openTickets.$destroy();
        ++cov_280sipggqj.s[62];
        openTickets = null;
      } else {
        ++cov_280sipggqj.b[14][1];
      }
      ++cov_280sipggqj.s[63];
      if (closedTickets) {
        ++cov_280sipggqj.b[15][0];
        ++cov_280sipggqj.s[64];

        closedTickets.$destroy();
        ++cov_280sipggqj.s[65];
        closedTickets = null;
      } else {
        ++cov_280sipggqj.b[15][1];
      }
    }
    ++cov_280sipggqj.s[66];
    return service;
  }

  ++cov_280sipggqj.s[67];
  angular.module('dc-service').factory('TicketService', ['$db', 'Utils', '$firebaseArray', '$firebaseObject', '$q', '$log', 'ActivityService', 'Users', TicketService]);
})();