'use strict';

var cov_29j38ujxbe = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/common/contacts/contact.service.js',
      hash = '14d5925ca9ce1efb47ad28819dc315cb3ae2022c',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/common/contacts/contact.service.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 62,
          column: 5
        }
      },
      '1': {
        start: {
          line: 5,
          column: 24
        },
        end: {
          line: 5,
          column: 36
        }
      },
      '2': {
        start: {
          line: 6,
          column: 24
        },
        end: {
          line: 6,
          column: 37
        }
      },
      '3': {
        start: {
          line: 8,
          column: 20
        },
        end: {
          line: 14,
          column: 5
        }
      },
      '4': {
        start: {
          line: 17,
          column: 16
        },
        end: {
          line: 17,
          column: 38
        }
      },
      '5': {
        start: {
          line: 18,
          column: 6
        },
        end: {
          line: 18,
          column: 32
        }
      },
      '6': {
        start: {
          line: 22,
          column: 16
        },
        end: {
          line: 24,
          column: 21
        }
      },
      '7': {
        start: {
          line: 25,
          column: 6
        },
        end: {
          line: 25,
          column: 31
        }
      },
      '8': {
        start: {
          line: 29,
          column: 20
        },
        end: {
          line: 29,
          column: 30
        }
      },
      '9': {
        start: {
          line: 30,
          column: 25
        },
        end: {
          line: 30,
          column: 47
        }
      },
      '10': {
        start: {
          line: 31,
          column: 6
        },
        end: {
          line: 31,
          column: 58
        }
      },
      '11': {
        start: {
          line: 32,
          column: 6
        },
        end: {
          line: 32,
          column: 31
        }
      },
      '12': {
        start: {
          line: 33,
          column: 6
        },
        end: {
          line: 33,
          column: 40
        }
      },
      '13': {
        start: {
          line: 34,
          column: 6
        },
        end: {
          line: 47,
          column: 9
        }
      },
      '14': {
        start: {
          line: 35,
          column: 8
        },
        end: {
          line: 37,
          column: 9
        }
      },
      '15': {
        start: {
          line: 36,
          column: 10
        },
        end: {
          line: 36,
          column: 35
        }
      },
      '16': {
        start: {
          line: 38,
          column: 8
        },
        end: {
          line: 38,
          column: 90
        }
      },
      '17': {
        start: {
          line: 39,
          column: 8
        },
        end: {
          line: 45,
          column: 11
        }
      },
      '18': {
        start: {
          line: 46,
          column: 8
        },
        end: {
          line: 46,
          column: 41
        }
      },
      '19': {
        start: {
          line: 48,
          column: 6
        },
        end: {
          line: 48,
          column: 27
        }
      },
      '20': {
        start: {
          line: 52,
          column: 6
        },
        end: {
          line: 52,
          column: 45
        }
      },
      '21': {
        start: {
          line: 57,
          column: 4
        },
        end: {
          line: 57,
          column: 19
        }
      },
      '22': {
        start: {
          line: 60,
          column: 2
        },
        end: {
          line: 61,
          column: 136
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 62,
            column: 1
          }
        }
      },
      '1': {
        name: 'ContactService',
        decl: {
          start: {
            line: 4,
            column: 11
          },
          end: {
            line: 4,
            column: 25
          }
        },
        loc: {
          start: {
            line: 4,
            column: 99
          },
          end: {
            line: 58,
            column: 3
          }
        }
      },
      '2': {
        name: '$get',
        decl: {
          start: {
            line: 16,
            column: 13
          },
          end: {
            line: 16,
            column: 17
          }
        },
        loc: {
          start: {
            line: 16,
            column: 23
          },
          end: {
            line: 19,
            column: 5
          }
        }
      },
      '3': {
        name: '$getByCustomer',
        decl: {
          start: {
            line: 21,
            column: 13
          },
          end: {
            line: 21,
            column: 27
          }
        },
        loc: {
          start: {
            line: 21,
            column: 33
          },
          end: {
            line: 26,
            column: 5
          }
        }
      },
      '4': {
        name: 'create',
        decl: {
          start: {
            line: 28,
            column: 13
          },
          end: {
            line: 28,
            column: 19
          }
        },
        loc: {
          start: {
            line: 28,
            column: 29
          },
          end: {
            line: 49,
            column: 5
          }
        }
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 34,
            column: 49
          },
          end: {
            line: 34,
            column: 50
          }
        },
        loc: {
          start: {
            line: 34,
            column: 56
          },
          end: {
            line: 47,
            column: 7
          }
        }
      },
      '6': {
        name: 'remove',
        decl: {
          start: {
            line: 51,
            column: 13
          },
          end: {
            line: 51,
            column: 19
          }
        },
        loc: {
          start: {
            line: 51,
            column: 25
          },
          end: {
            line: 53,
            column: 5
          }
        }
      },
      '7': {
        name: 'reset',
        decl: {
          start: {
            line: 55,
            column: 13
          },
          end: {
            line: 55,
            column: 18
          }
        },
        loc: {
          start: {
            line: 55,
            column: 21
          },
          end: {
            line: 56,
            column: 5
          }
        }
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 31,
            column: 26
          },
          end: {
            line: 31,
            column: 57
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 31,
            column: 26
          },
          end: {
            line: 31,
            column: 49
          }
        }, {
          start: {
            line: 31,
            column: 53
          },
          end: {
            line: 31,
            column: 57
          }
        }]
      },
      '1': {
        loc: {
          start: {
            line: 35,
            column: 8
          },
          end: {
            line: 37,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 35,
            column: 8
          },
          end: {
            line: 37,
            column: 9
          }
        }, {
          start: {
            line: 35,
            column: 8
          },
          end: {
            line: 37,
            column: 9
          }
        }]
      },
      '2': {
        loc: {
          start: {
            line: 41,
            column: 20
          },
          end: {
            line: 41,
            column: 44
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 41,
            column: 20
          },
          end: {
            line: 41,
            column: 36
          }
        }, {
          start: {
            line: 41,
            column: 40
          },
          end: {
            line: 41,
            column: 44
          }
        }]
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_29j38ujxbe.s[0];
(function () {
  ++cov_29j38ujxbe.f[0];

  // defines function Projects
  function ContactService($db, Utils, Users, ActivityService, $firebaseArray, $firebaseObject, $q) {
    ++cov_29j38ujxbe.f[1];

    var contactsRef = (++cov_29j38ujxbe.s[1], $db.contacts);
    var customerRef = (++cov_29j38ujxbe.s[2], $db.customers);

    var service = (++cov_29j38ujxbe.s[3], {
      $get: $get,
      $getByCustomer: $getByCustomer,
      create: create,
      remove: remove,
      reset: reset
    });

    function $get(uid) {
      ++cov_29j38ujxbe.f[2];

      var q = (++cov_29j38ujxbe.s[4], contactsRef.child(uid));
      ++cov_29j38ujxbe.s[5];
      return $firebaseObject(q);
    }

    function $getByCustomer(uid) {
      ++cov_29j38ujxbe.f[3];

      var q = (++cov_29j38ujxbe.s[6], contactsRef.orderByChild('customer').equalTo(uid));
      ++cov_29j38ujxbe.s[7];
      return $firebaseArray(q);
    }

    function create(contact) {
      ++cov_29j38ujxbe.f[4];

      var defer = (++cov_29j38ujxbe.s[8], $q.defer());
      var contactRef = (++cov_29j38ujxbe.s[9], contactsRef.push().key);
      ++cov_29j38ujxbe.s[10];
      contact.createdBy = (++cov_29j38ujxbe.b[0][0], Users.currentUser().$id) || (++cov_29j38ujxbe.b[0][1], null);
      ++cov_29j38ujxbe.s[11];
      contact.uid = contactRef;
      ++cov_29j38ujxbe.s[12];
      contact.createdDate = Utils.now();
      ++cov_29j38ujxbe.s[13];
      contactsRef.child(contactRef).set(contact, function (err) {
        ++cov_29j38ujxbe.f[5];
        ++cov_29j38ujxbe.s[14];

        if (err) {
          ++cov_29j38ujxbe.b[1][0];
          ++cov_29j38ujxbe.s[15];

          return defer.reject(err);
        } else {
          ++cov_29j38ujxbe.b[1][1];
        }
        ++cov_29j38ujxbe.s[16];
        customerRef.child(contact.customer).child('contacts').child(contactRef).set(true);
        ++cov_29j38ujxbe.s[17];
        ActivityService.add({
          userId: Users.currentUser().$id,
          customer: (++cov_29j38ujxbe.b[2][0], contact.customer) || (++cov_29j38ujxbe.b[2][1], null),
          type: 'Contact',
          action: 'Created',
          link: contact.uid
        });
        ++cov_29j38ujxbe.s[18];
        return defer.resolve(contactRef);
      });
      ++cov_29j38ujxbe.s[19];
      return defer.promise;
    }

    function remove(uid) {
      ++cov_29j38ujxbe.f[6];
      ++cov_29j38ujxbe.s[20];

      return contactsRef.child(uid).remove();
    }

    function reset() {
      ++cov_29j38ujxbe.f[7];
    }
    ++cov_29j38ujxbe.s[21];
    return service;
  }

  ++cov_29j38ujxbe.s[22];
  angular.module('dc-contacts').factory('ContactService', ['$db', 'Utils', 'Users', 'ActivityService', '$firebaseArray', '$firebaseObject', '$q', ContactService]);
})();