'use strict';

var cov_2omm0ckd6u = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/common/comment/comment.service.js',
      hash = '86b2e494a68f51cb7ed6da3817a1596c61be13e7',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/common/comment/comment.service.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 144,
          column: 5
        }
      },
      '1': {
        start: {
          line: 5,
          column: 24
        },
        end: {
          line: 5,
          column: 36
        }
      },
      '2': {
        start: {
          line: 6,
          column: 22
        },
        end: {
          line: 6,
          column: 33
        }
      },
      '3': {
        start: {
          line: 7,
          column: 23
        },
        end: {
          line: 7,
          column: 35
        }
      },
      '4': {
        start: {
          line: 9,
          column: 19
        },
        end: {
          line: 9,
          column: 23
        }
      },
      '5': {
        start: {
          line: 10,
          column: 25
        },
        end: {
          line: 10,
          column: 29
        }
      },
      '6': {
        start: {
          line: 13,
          column: 20
        },
        end: {
          line: 21,
          column: 5
        }
      },
      '7': {
        start: {
          line: 23,
          column: 4
        },
        end: {
          line: 23,
          column: 19
        }
      },
      '8': {
        start: {
          line: 26,
          column: 25
        },
        end: {
          line: 26,
          column: 47
        }
      },
      '9': {
        start: {
          line: 27,
          column: 6
        },
        end: {
          line: 27,
          column: 31
        }
      },
      '10': {
        start: {
          line: 28,
          column: 6
        },
        end: {
          line: 28,
          column: 40
        }
      },
      '11': {
        start: {
          line: 29,
          column: 6
        },
        end: {
          line: 52,
          column: 11
        }
      },
      '12': {
        start: {
          line: 31,
          column: 27
        },
        end: {
          line: 38,
          column: 11
        }
      },
      '13': {
        start: {
          line: 39,
          column: 10
        },
        end: {
          line: 39,
          column: 40
        }
      },
      '14': {
        start: {
          line: 40,
          column: 10
        },
        end: {
          line: 45,
          column: 11
        }
      },
      '15': {
        start: {
          line: 41,
          column: 12
        },
        end: {
          line: 44,
          column: 17
        }
      },
      '16': {
        start: {
          line: 43,
          column: 16
        },
        end: {
          line: 43,
          column: 42
        }
      },
      '17': {
        start: {
          line: 46,
          column: 10
        },
        end: {
          line: 51,
          column: 11
        }
      },
      '18': {
        start: {
          line: 47,
          column: 12
        },
        end: {
          line: 50,
          column: 17
        }
      },
      '19': {
        start: {
          line: 49,
          column: 16
        },
        end: {
          line: 49,
          column: 42
        }
      },
      '20': {
        start: {
          line: 56,
          column: 19
        },
        end: {
          line: 56,
          column: 42
        }
      },
      '21': {
        start: {
          line: 58,
          column: 6
        },
        end: {
          line: 61,
          column: 17
        }
      },
      '22': {
        start: {
          line: 74,
          column: 23
        },
        end: {
          line: 81,
          column: 7
        }
      },
      '23': {
        start: {
          line: 82,
          column: 6
        },
        end: {
          line: 105,
          column: 11
        }
      },
      '24': {
        start: {
          line: 84,
          column: 10
        },
        end: {
          line: 93,
          column: 11
        }
      },
      '25': {
        start: {
          line: 85,
          column: 12
        },
        end: {
          line: 92,
          column: 17
        }
      },
      '26': {
        start: {
          line: 87,
          column: 26
        },
        end: {
          line: 87,
          column: 37
        }
      },
      '27': {
        start: {
          line: 88,
          column: 16
        },
        end: {
          line: 90,
          column: 17
        }
      },
      '28': {
        start: {
          line: 89,
          column: 18
        },
        end: {
          line: 89,
          column: 26
        }
      },
      '29': {
        start: {
          line: 91,
          column: 16
        },
        end: {
          line: 91,
          column: 27
        }
      },
      '30': {
        start: {
          line: 94,
          column: 10
        },
        end: {
          line: 103,
          column: 11
        }
      },
      '31': {
        start: {
          line: 95,
          column: 12
        },
        end: {
          line: 102,
          column: 17
        }
      },
      '32': {
        start: {
          line: 97,
          column: 26
        },
        end: {
          line: 97,
          column: 37
        }
      },
      '33': {
        start: {
          line: 98,
          column: 16
        },
        end: {
          line: 100,
          column: 17
        }
      },
      '34': {
        start: {
          line: 99,
          column: 18
        },
        end: {
          line: 99,
          column: 26
        }
      },
      '35': {
        start: {
          line: 101,
          column: 16
        },
        end: {
          line: 101,
          column: 27
        }
      },
      '36': {
        start: {
          line: 104,
          column: 10
        },
        end: {
          line: 104,
          column: 40
        }
      },
      '37': {
        start: {
          line: 109,
          column: 20
        },
        end: {
          line: 109,
          column: 22
        }
      },
      '38': {
        start: {
          line: 110,
          column: 6
        },
        end: {
          line: 112,
          column: 7
        }
      },
      '39': {
        start: {
          line: 111,
          column: 8
        },
        end: {
          line: 111,
          column: 73
        }
      },
      '40': {
        start: {
          line: 113,
          column: 6
        },
        end: {
          line: 113,
          column: 28
        }
      },
      '41': {
        start: {
          line: 117,
          column: 16
        },
        end: {
          line: 119,
          column: 21
        }
      },
      '42': {
        start: {
          line: 120,
          column: 6
        },
        end: {
          line: 120,
          column: 31
        }
      },
      '43': {
        start: {
          line: 124,
          column: 16
        },
        end: {
          line: 126,
          column: 21
        }
      },
      '44': {
        start: {
          line: 127,
          column: 6
        },
        end: {
          line: 127,
          column: 31
        }
      },
      '45': {
        start: {
          line: 131,
          column: 6
        },
        end: {
          line: 134,
          column: 7
        }
      },
      '46': {
        start: {
          line: 132,
          column: 8
        },
        end: {
          line: 132,
          column: 28
        }
      },
      '47': {
        start: {
          line: 133,
          column: 8
        },
        end: {
          line: 133,
          column: 24
        }
      },
      '48': {
        start: {
          line: 135,
          column: 6
        },
        end: {
          line: 138,
          column: 7
        }
      },
      '49': {
        start: {
          line: 136,
          column: 8
        },
        end: {
          line: 136,
          column: 34
        }
      },
      '50': {
        start: {
          line: 137,
          column: 8
        },
        end: {
          line: 137,
          column: 30
        }
      },
      '51': {
        start: {
          line: 142,
          column: 2
        },
        end: {
          line: 143,
          column: 111
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 144,
            column: 1
          }
        }
      },
      '1': {
        name: 'CommentService',
        decl: {
          start: {
            line: 4,
            column: 11
          },
          end: {
            line: 4,
            column: 25
          }
        },
        loc: {
          start: {
            line: 4,
            column: 78
          },
          end: {
            line: 140,
            column: 3
          }
        }
      },
      '2': {
        name: 'add',
        decl: {
          start: {
            line: 25,
            column: 13
          },
          end: {
            line: 25,
            column: 16
          }
        },
        loc: {
          start: {
            line: 25,
            column: 26
          },
          end: {
            line: 53,
            column: 5
          }
        }
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 30,
            column: 22
          },
          end: {
            line: 30,
            column: 23
          }
        },
        loc: {
          start: {
            line: 30,
            column: 28
          },
          end: {
            line: 52,
            column: 9
          }
        }
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 42,
            column: 27
          },
          end: {
            line: 42,
            column: 28
          }
        },
        loc: {
          start: {
            line: 42,
            column: 38
          },
          end: {
            line: 44,
            column: 15
          }
        }
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 48,
            column: 27
          },
          end: {
            line: 48,
            column: 28
          }
        },
        loc: {
          start: {
            line: 48,
            column: 38
          },
          end: {
            line: 50,
            column: 15
          }
        }
      },
      '6': {
        name: 'like',
        decl: {
          start: {
            line: 55,
            column: 13
          },
          end: {
            line: 55,
            column: 17
          }
        },
        loc: {
          start: {
            line: 55,
            column: 30
          },
          end: {
            line: 71,
            column: 5
          }
        }
      },
      '7': {
        name: 'remove',
        decl: {
          start: {
            line: 73,
            column: 13
          },
          end: {
            line: 73,
            column: 19
          }
        },
        loc: {
          start: {
            line: 73,
            column: 29
          },
          end: {
            line: 106,
            column: 5
          }
        }
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 83,
            column: 16
          },
          end: {
            line: 83,
            column: 17
          }
        },
        loc: {
          start: {
            line: 83,
            column: 22
          },
          end: {
            line: 105,
            column: 9
          }
        }
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 86,
            column: 27
          },
          end: {
            line: 86,
            column: 28
          }
        },
        loc: {
          start: {
            line: 86,
            column: 38
          },
          end: {
            line: 92,
            column: 15
          }
        }
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 96,
            column: 27
          },
          end: {
            line: 96,
            column: 28
          }
        },
        loc: {
          start: {
            line: 96,
            column: 38
          },
          end: {
            line: 102,
            column: 15
          }
        }
      },
      '11': {
        name: 'recent',
        decl: {
          start: {
            line: 108,
            column: 13
          },
          end: {
            line: 108,
            column: 19
          }
        },
        loc: {
          start: {
            line: 108,
            column: 22
          },
          end: {
            line: 114,
            column: 5
          }
        }
      },
      '12': {
        name: 'getByProject',
        decl: {
          start: {
            line: 116,
            column: 13
          },
          end: {
            line: 116,
            column: 25
          }
        },
        loc: {
          start: {
            line: 116,
            column: 31
          },
          end: {
            line: 121,
            column: 5
          }
        }
      },
      '13': {
        name: 'getByTicket',
        decl: {
          start: {
            line: 123,
            column: 13
          },
          end: {
            line: 123,
            column: 24
          }
        },
        loc: {
          start: {
            line: 123,
            column: 30
          },
          end: {
            line: 128,
            column: 5
          }
        }
      },
      '14': {
        name: 'reset',
        decl: {
          start: {
            line: 130,
            column: 13
          },
          end: {
            line: 130,
            column: 18
          }
        },
        loc: {
          start: {
            line: 130,
            column: 21
          },
          end: {
            line: 139,
            column: 5
          }
        }
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 33,
            column: 21
          },
          end: {
            line: 33,
            column: 44
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 33,
            column: 21
          },
          end: {
            line: 33,
            column: 36
          }
        }, {
          start: {
            line: 33,
            column: 40
          },
          end: {
            line: 33,
            column: 44
          }
        }]
      },
      '1': {
        loc: {
          start: {
            line: 34,
            column: 20
          },
          end: {
            line: 34,
            column: 42
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 34,
            column: 20
          },
          end: {
            line: 34,
            column: 34
          }
        }, {
          start: {
            line: 34,
            column: 38
          },
          end: {
            line: 34,
            column: 42
          }
        }]
      },
      '2': {
        loc: {
          start: {
            line: 40,
            column: 10
          },
          end: {
            line: 45,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 40,
            column: 10
          },
          end: {
            line: 45,
            column: 11
          }
        }, {
          start: {
            line: 40,
            column: 10
          },
          end: {
            line: 45,
            column: 11
          }
        }]
      },
      '3': {
        loc: {
          start: {
            line: 43,
            column: 24
          },
          end: {
            line: 43,
            column: 36
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 43,
            column: 24
          },
          end: {
            line: 43,
            column: 31
          }
        }, {
          start: {
            line: 43,
            column: 35
          },
          end: {
            line: 43,
            column: 36
          }
        }]
      },
      '4': {
        loc: {
          start: {
            line: 46,
            column: 10
          },
          end: {
            line: 51,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 46,
            column: 10
          },
          end: {
            line: 51,
            column: 11
          }
        }, {
          start: {
            line: 46,
            column: 10
          },
          end: {
            line: 51,
            column: 11
          }
        }]
      },
      '5': {
        loc: {
          start: {
            line: 49,
            column: 24
          },
          end: {
            line: 49,
            column: 36
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 49,
            column: 24
          },
          end: {
            line: 49,
            column: 31
          }
        }, {
          start: {
            line: 49,
            column: 35
          },
          end: {
            line: 49,
            column: 36
          }
        }]
      },
      '6': {
        loc: {
          start: {
            line: 76,
            column: 17
          },
          end: {
            line: 76,
            column: 40
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 76,
            column: 17
          },
          end: {
            line: 76,
            column: 32
          }
        }, {
          start: {
            line: 76,
            column: 36
          },
          end: {
            line: 76,
            column: 40
          }
        }]
      },
      '7': {
        loc: {
          start: {
            line: 77,
            column: 16
          },
          end: {
            line: 77,
            column: 38
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 77,
            column: 16
          },
          end: {
            line: 77,
            column: 30
          }
        }, {
          start: {
            line: 77,
            column: 34
          },
          end: {
            line: 77,
            column: 38
          }
        }]
      },
      '8': {
        loc: {
          start: {
            line: 84,
            column: 10
          },
          end: {
            line: 93,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 84,
            column: 10
          },
          end: {
            line: 93,
            column: 11
          }
        }, {
          start: {
            line: 84,
            column: 10
          },
          end: {
            line: 93,
            column: 11
          }
        }]
      },
      '9': {
        loc: {
          start: {
            line: 88,
            column: 16
          },
          end: {
            line: 90,
            column: 17
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 88,
            column: 16
          },
          end: {
            line: 90,
            column: 17
          }
        }, {
          start: {
            line: 88,
            column: 16
          },
          end: {
            line: 90,
            column: 17
          }
        }]
      },
      '10': {
        loc: {
          start: {
            line: 94,
            column: 10
          },
          end: {
            line: 103,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 94,
            column: 10
          },
          end: {
            line: 103,
            column: 11
          }
        }, {
          start: {
            line: 94,
            column: 10
          },
          end: {
            line: 103,
            column: 11
          }
        }]
      },
      '11': {
        loc: {
          start: {
            line: 98,
            column: 16
          },
          end: {
            line: 100,
            column: 17
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 98,
            column: 16
          },
          end: {
            line: 100,
            column: 17
          }
        }, {
          start: {
            line: 98,
            column: 16
          },
          end: {
            line: 100,
            column: 17
          }
        }]
      },
      '12': {
        loc: {
          start: {
            line: 110,
            column: 6
          },
          end: {
            line: 112,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 110,
            column: 6
          },
          end: {
            line: 112,
            column: 7
          }
        }, {
          start: {
            line: 110,
            column: 6
          },
          end: {
            line: 112,
            column: 7
          }
        }]
      },
      '13': {
        loc: {
          start: {
            line: 131,
            column: 6
          },
          end: {
            line: 134,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 131,
            column: 6
          },
          end: {
            line: 134,
            column: 7
          }
        }, {
          start: {
            line: 131,
            column: 6
          },
          end: {
            line: 134,
            column: 7
          }
        }]
      },
      '14': {
        loc: {
          start: {
            line: 135,
            column: 6
          },
          end: {
            line: 138,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 135,
            column: 6
          },
          end: {
            line: 138,
            column: 7
          }
        }, {
          start: {
            line: 135,
            column: 6
          },
          end: {
            line: 138,
            column: 7
          }
        }]
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_2omm0ckd6u.s[0];
(function () {
  ++cov_2omm0ckd6u.f[0];

  // define function Messages
  function CommentService($db, Utils, ActivityService, $firebaseArray, Users) {
    ++cov_2omm0ckd6u.f[1];

    var messagesRef = (++cov_2omm0ckd6u.s[1], $db.messages);
    var ticketRef = (++cov_2omm0ckd6u.s[2], $db.tickets);
    var projectRef = (++cov_2omm0ckd6u.s[3], $db.projects);

    var messages = (++cov_2omm0ckd6u.s[4], null);
    var recentMessages = (++cov_2omm0ckd6u.s[5], null);

    // service definition
    var service = (++cov_2omm0ckd6u.s[6], {
      add: add,
      like: like,
      remove: remove,
      recent: recent,
      getByProject: getByProject,
      getByTicket: getByTicket,
      reset: reset
    });

    ++cov_2omm0ckd6u.s[7];
    return service;

    function add(message) {
      ++cov_2omm0ckd6u.f[2];

      var messageRef = (++cov_2omm0ckd6u.s[8], messagesRef.push().key);
      ++cov_2omm0ckd6u.s[9];
      message.uid = messageRef;
      ++cov_2omm0ckd6u.s[10];
      message.createdDate = Utils.now();
      ++cov_2omm0ckd6u.s[11];
      return $db.messages.child(messageRef).set(message, function () {
        ++cov_2omm0ckd6u.f[3];

        var activity = (++cov_2omm0ckd6u.s[12], {
          userId: message.senderId,
          project: (++cov_2omm0ckd6u.b[0][0], message.project) || (++cov_2omm0ckd6u.b[0][1], null),
          ticket: (++cov_2omm0ckd6u.b[1][0], message.ticket) || (++cov_2omm0ckd6u.b[1][1], null),
          type: 'Message',
          action: 'Created',
          link: message.uid
        });
        ++cov_2omm0ckd6u.s[13];
        ActivityService.add(activity);
        ++cov_2omm0ckd6u.s[14];
        if (activity.project) {
          ++cov_2omm0ckd6u.b[2][0];
          ++cov_2omm0ckd6u.s[15];

          projectRef.child(message.project).child('comments').transaction(function (current) {
            ++cov_2omm0ckd6u.f[4];
            ++cov_2omm0ckd6u.s[16];

            return ((++cov_2omm0ckd6u.b[3][0], current) || (++cov_2omm0ckd6u.b[3][1], 0)) + 1;
          });
        } else {
          ++cov_2omm0ckd6u.b[2][1];
        }
        ++cov_2omm0ckd6u.s[17];
        if (activity.ticket) {
          ++cov_2omm0ckd6u.b[4][0];
          ++cov_2omm0ckd6u.s[18];

          ticketRef.child(message.ticket).child('comments').transaction(function (current) {
            ++cov_2omm0ckd6u.f[5];
            ++cov_2omm0ckd6u.s[19];

            return ((++cov_2omm0ckd6u.b[5][0], current) || (++cov_2omm0ckd6u.b[5][1], 0)) + 1;
          });
        } else {
          ++cov_2omm0ckd6u.b[4][1];
        }
      });
    }

    function like(messageRef) {
      ++cov_2omm0ckd6u.f[6];

      var user = (++cov_2omm0ckd6u.s[20], Users.currentUser().$id);
      // $db.messages.child(messageRef).child('likes').child()
      ++cov_2omm0ckd6u.s[21];
      return $db.messages.child(messageRef).child('likes').child(user).set(true);
      // .transaction(current => {
      //   // const activity = {
      //   //   type: 'Message',
      //   //   action: 'Liked',
      //   //   link: message.uid
      //   // };
      //   // ActivityService.add(activity);
      //   return (current || 0) + 1;
      // });
    }

    function remove(message) {
      ++cov_2omm0ckd6u.f[7];

      var activity = (++cov_2omm0ckd6u.s[22], {
        userId: message.senderId,
        project: (++cov_2omm0ckd6u.b[6][0], message.project) || (++cov_2omm0ckd6u.b[6][1], null),
        ticket: (++cov_2omm0ckd6u.b[7][0], message.ticket) || (++cov_2omm0ckd6u.b[7][1], null),
        type: 'Message',
        action: 'Deleted',
        link: message.uid
      });
      ++cov_2omm0ckd6u.s[23];
      return $db.messages.child(message.uid).remove(function () {
        ++cov_2omm0ckd6u.f[8];
        ++cov_2omm0ckd6u.s[24];

        if (activity.project) {
          ++cov_2omm0ckd6u.b[8][0];
          ++cov_2omm0ckd6u.s[25];

          projectRef.child(message.project).child('comments').transaction(function (current) {
            ++cov_2omm0ckd6u.f[9];

            var val = (++cov_2omm0ckd6u.s[26], current - 1);
            ++cov_2omm0ckd6u.s[27];
            if (val <= 0) {
              ++cov_2omm0ckd6u.b[9][0];
              ++cov_2omm0ckd6u.s[28];

              val = 0;
            } else {
              ++cov_2omm0ckd6u.b[9][1];
            }
            ++cov_2omm0ckd6u.s[29];
            return val;
          });
        } else {
          ++cov_2omm0ckd6u.b[8][1];
        }
        ++cov_2omm0ckd6u.s[30];
        if (activity.ticket) {
          ++cov_2omm0ckd6u.b[10][0];
          ++cov_2omm0ckd6u.s[31];

          ticketRef.child(message.ticket).child('comments').transaction(function (current) {
            ++cov_2omm0ckd6u.f[10];

            var val = (++cov_2omm0ckd6u.s[32], current - 1);
            ++cov_2omm0ckd6u.s[33];
            if (val <= 0) {
              ++cov_2omm0ckd6u.b[11][0];
              ++cov_2omm0ckd6u.s[34];

              val = 0;
            } else {
              ++cov_2omm0ckd6u.b[11][1];
            }
            ++cov_2omm0ckd6u.s[35];
            return val;
          });
        } else {
          ++cov_2omm0ckd6u.b[10][1];
        }
        ++cov_2omm0ckd6u.s[36];
        ActivityService.add(activity);
      });
    }

    function recent() {
      ++cov_2omm0ckd6u.f[11];

      var limit = (++cov_2omm0ckd6u.s[37], 10);
      ++cov_2omm0ckd6u.s[38];
      if (!recentMessages) {
        ++cov_2omm0ckd6u.b[12][0];
        ++cov_2omm0ckd6u.s[39];

        recentMessages = $firebaseArray($db.messages.limitToLast(limit));
      } else {
        ++cov_2omm0ckd6u.b[12][1];
      }
      ++cov_2omm0ckd6u.s[40];
      return recentMessages;
    }

    function getByProject(uid) {
      ++cov_2omm0ckd6u.f[12];

      var q = (++cov_2omm0ckd6u.s[41], messagesRef.orderByChild('project').equalTo(uid));
      ++cov_2omm0ckd6u.s[42];
      return $firebaseArray(q);
    }

    function getByTicket(uid) {
      ++cov_2omm0ckd6u.f[13];

      var q = (++cov_2omm0ckd6u.s[43], messagesRef.orderByChild('ticket').equalTo(uid));
      ++cov_2omm0ckd6u.s[44];
      return $firebaseArray(q);
    }

    function reset() {
      ++cov_2omm0ckd6u.f[14];
      ++cov_2omm0ckd6u.s[45];

      if (messages) {
        ++cov_2omm0ckd6u.b[13][0];
        ++cov_2omm0ckd6u.s[46];

        messages.$destroy();
        ++cov_2omm0ckd6u.s[47];
        messages = null;
      } else {
        ++cov_2omm0ckd6u.b[13][1];
      }
      ++cov_2omm0ckd6u.s[48];
      if (recentMessages) {
        ++cov_2omm0ckd6u.b[14][0];
        ++cov_2omm0ckd6u.s[49];

        recentMessages.$destroy();
        ++cov_2omm0ckd6u.s[50];
        recentMessages = null;
      } else {
        ++cov_2omm0ckd6u.b[14][1];
      }
    }
  }

  ++cov_2omm0ckd6u.s[51];
  angular.module('dc-comments').factory('CommentService', ['$db', 'Utils', 'ActivityService', '$firebaseArray', 'Users', CommentService]);
})();