'use strict';

var cov_1d1kkre1b9 = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/common/comment/comment.module.js',
      hash = 'e26c6d63fedd489359c25e4054587549f0773cfe',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/common/comment/comment.module.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 5,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 2
        },
        end: {
          line: 4,
          column: 31
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 5,
            column: 1
          }
        }
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0
    },
    f: {
      '0': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_1d1kkre1b9.s[0];
(function () {
  ++cov_1d1kkre1b9.f[0];
  ++cov_1d1kkre1b9.s[1];

  angular.module('dc-comments', []);
})();