'use strict';

var cov_8jwzicmap = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/service/helpers/priority-rating.component.js',
      hash = '13292a48b01e4b4b6386a1c82d59d6bc916fc9a8',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/service/helpers/priority-rating.component.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 30,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 25
        },
        end: {
          line: 18,
          column: 3
        }
      },
      '2': {
        start: {
          line: 20,
          column: 2
        },
        end: {
          line: 20,
          column: 26
        }
      },
      '3': {
        start: {
          line: 23,
          column: 17
        },
        end: {
          line: 23,
          column: 21
        }
      },
      '4': {
        start: {
          line: 24,
          column: 4
        },
        end: {
          line: 24,
          column: 28
        }
      },
      '5': {
        start: {
          line: 27,
          column: 2
        },
        end: {
          line: 29,
          column: 49
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 30,
            column: 1
          }
        }
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 22,
            column: 11
          },
          end: {
            line: 22,
            column: 21
          }
        },
        loc: {
          start: {
            line: 22,
            column: 24
          },
          end: {
            line: 25,
            column: 3
          }
        }
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0
    },
    f: {
      '0': 0,
      '1': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_8jwzicmap.s[0];
(function () {
  ++cov_8jwzicmap.f[0];

  var priorityRating = (++cov_8jwzicmap.s[1], {
    bindings: {
      priority: '<'
    },
    template: '\n      <span ng-class="{0: \'text-success\', 1: \'text-success\', 5: \'text-warning\', 10: \'text-danger\'}[$ctrl.priority]">\n      <strong>\n        <!-- <span ng-if="$ctrl.priority == 0">!</span> -->\n        <span ng-if="$ctrl.priority == 1" uib-tooltip="Normal" tooltip-placement="left"><i class="ion {{$ctrl.icon}}"></i> </span>\n        <span ng-if="$ctrl.priority == 5" uib-tooltip="High" tooltip-placement="left"><i class="ion {{$ctrl.icon}}"></i> <i class="ion {{$ctrl.icon}}"></i></span>\n        <span ng-if="$ctrl.priority == 10" uib-tooltip="Urgent" tooltip-placement="left"><i class="ion {{$ctrl.icon}}"></i> <i class="ion {{$ctrl.icon}}"></i> <i class="ion {{$ctrl.icon}}"></i></span>\n      </strong>\n      </span>\n    ',
    controller: controller
  });

  ++cov_8jwzicmap.s[2];
  controller.$inject = [];

  function controller() {
    ++cov_8jwzicmap.f[1];

    var self = (++cov_8jwzicmap.s[3], this);
    ++cov_8jwzicmap.s[4];
    self.icon = 'ion-alert';
  }

  ++cov_8jwzicmap.s[5];
  angular.module('dc-service').component('priorityRating', priorityRating);
})();