'use strict';

var cov_1ux1bsto0u = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/common/file.service.js',
      hash = 'c0ab1cbc0ca08566eff61ce6d2802002eebc8865',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/common/file.service.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 80,
          column: 5
        }
      },
      '1': {
        start: {
          line: 4,
          column: 20
        },
        end: {
          line: 7,
          column: 5
        }
      },
      '2': {
        start: {
          line: 9,
          column: 4
        },
        end: {
          line: 9,
          column: 19
        }
      },
      '3': {
        start: {
          line: 46,
          column: 20
        },
        end: {
          line: 46,
          column: 30
        }
      },
      '4': {
        start: {
          line: 48,
          column: 27
        },
        end: {
          line: 48,
          column: 53
        }
      },
      '5': {
        start: {
          line: 50,
          column: 25
        },
        end: {
          line: 50,
          column: 60
        }
      },
      '6': {
        start: {
          line: 51,
          column: 19
        },
        end: {
          line: 51,
          column: 64
        }
      },
      '7': {
        start: {
          line: 52,
          column: 6
        },
        end: {
          line: 52,
          column: 64
        }
      },
      '8': {
        start: {
          line: 54,
          column: 6
        },
        end: {
          line: 58,
          column: 9
        }
      },
      '9': {
        start: {
          line: 55,
          column: 25
        },
        end: {
          line: 55,
          column: 80
        }
      },
      '10': {
        start: {
          line: 56,
          column: 8
        },
        end: {
          line: 56,
          column: 48
        }
      },
      '11': {
        start: {
          line: 57,
          column: 8
        },
        end: {
          line: 57,
          column: 71
        }
      },
      '12': {
        start: {
          line: 60,
          column: 6
        },
        end: {
          line: 64,
          column: 9
        }
      },
      '13': {
        start: {
          line: 61,
          column: 8
        },
        end: {
          line: 61,
          column: 66
        }
      },
      '14': {
        start: {
          line: 62,
          column: 8
        },
        end: {
          line: 62,
          column: 67
        }
      },
      '15': {
        start: {
          line: 63,
          column: 8
        },
        end: {
          line: 63,
          column: 32
        }
      },
      '16': {
        start: {
          line: 66,
          column: 6
        },
        end: {
          line: 73,
          column: 9
        }
      },
      '17': {
        start: {
          line: 67,
          column: 23
        },
        end: {
          line: 71,
          column: 9
        }
      },
      '18': {
        start: {
          line: 72,
          column: 8
        },
        end: {
          line: 72,
          column: 63
        }
      },
      '19': {
        start: {
          line: 74,
          column: 6
        },
        end: {
          line: 74,
          column: 27
        }
      },
      '20': {
        start: {
          line: 78,
          column: 2
        },
        end: {
          line: 79,
          column: 82
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 80,
            column: 1
          }
        }
      },
      '1': {
        name: 'FileService',
        decl: {
          start: {
            line: 3,
            column: 11
          },
          end: {
            line: 3,
            column: 22
          }
        },
        loc: {
          start: {
            line: 3,
            column: 54
          },
          end: {
            line: 76,
            column: 3
          }
        }
      },
      '2': {
        name: 'putSignature',
        decl: {
          start: {
            line: 45,
            column: 13
          },
          end: {
            line: 45,
            column: 25
          }
        },
        loc: {
          start: {
            line: 45,
            column: 44
          },
          end: {
            line: 75,
            column: 5
          }
        }
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 54,
            column: 21
          },
          end: {
            line: 54,
            column: 22
          }
        },
        loc: {
          start: {
            line: 54,
            column: 33
          },
          end: {
            line: 58,
            column: 7
          }
        }
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 60,
            column: 21
          },
          end: {
            line: 60,
            column: 22
          }
        },
        loc: {
          start: {
            line: 60,
            column: 33
          },
          end: {
            line: 64,
            column: 7
          }
        }
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 66,
            column: 18
          },
          end: {
            line: 66,
            column: 19
          }
        },
        loc: {
          start: {
            line: 66,
            column: 27
          },
          end: {
            line: 73,
            column: 7
          }
        }
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 72,
            column: 21
          },
          end: {
            line: 72,
            column: 61
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 72,
            column: 21
          },
          end: {
            line: 72,
            column: 39
          }
        }, {
          start: {
            line: 72,
            column: 43
          },
          end: {
            line: 72,
            column: 61
          }
        }]
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0
    },
    b: {
      '0': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_1ux1bsto0u.s[0];
(function () {
  ++cov_1ux1bsto0u.f[0];

  function FileService($q, StorageService, $db, $log) {
    ++cov_1ux1bsto0u.f[1];

    var service = (++cov_1ux1bsto0u.s[1], {
      // put,
      putSignature: putSignature
    });

    ++cov_1ux1bsto0u.s[2];
    return service;

    // function put(uid, file) {
    //   const defer = $q.defer();
    //   const storageRef = Storage.getStorage(Users.currentUser().$id, uid);
    //   const task = storageRef.$put(file);
    //   // on progress
    //   task.$progress(snapshot => {
    //     const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    //     $log.log(`Upload is ${progress}% done`);
    //   });
    //   // on complete
    //   task.$complete(snapshot => {
    //     defer.resolve(snapshot);
    //   });
    //   // on error
    //   task.$error(error => {
    //     let msg = '';
    //     switch (error.code) {
    //       case 'storage/unauthorized':
    //         msg = 'User does not have permission to access the object.';
    //         break;
    //       case 'storage/canceled':
    //         msg = 'User canceled the upload.';
    //         break;
    //       case 'storage/unknown':
    //         msg = ' Unknown error occurred, Please try later.';
    //         break;
    //       default:
    //         msg = '';
    //     }
    //     defer.reject(msg);
    //   });
    //   return defer.promise;
    // }

    function putSignature(filename, string) {
      ++cov_1ux1bsto0u.f[2];

      var defer = (++cov_1ux1bsto0u.s[3], $q.defer());
      // firebase reserves names with a . in them
      var safeFileName = (++cov_1ux1bsto0u.s[4], filename.replace('.', '-'));
      // so filename.png needs to be converted to filename-png
      var storageRef = (++cov_1ux1bsto0u.s[5], StorageService.signatures(filename));
      var task = (++cov_1ux1bsto0u.s[6], storageRef.$putString(string, 'data_url', {}));
      ++cov_1ux1bsto0u.s[7];
      $db.status.child(safeFileName).child('loading').set(true);
      // on progress
      ++cov_1ux1bsto0u.s[8];
      task.$progress(function (snapshot) {
        ++cov_1ux1bsto0u.f[3];

        var progress = (++cov_1ux1bsto0u.s[9], snapshot.bytesTransferred / snapshot.totalBytes * 100);
        ++cov_1ux1bsto0u.s[10];
        $log.log('Upload is ' + progress + '% done');
        ++cov_1ux1bsto0u.s[11];
        $db.status.child(safeFileName).child('progress').set(progress);
      });
      // on complete
      ++cov_1ux1bsto0u.s[12];
      task.$complete(function (snapshot) {
        ++cov_1ux1bsto0u.f[4];
        ++cov_1ux1bsto0u.s[13];

        $db.status.child(safeFileName).child('loading').set(null);
        ++cov_1ux1bsto0u.s[14];
        $db.status.child(safeFileName).child('progress').set(null);
        ++cov_1ux1bsto0u.s[15];
        defer.resolve(snapshot);
      });
      // on error
      ++cov_1ux1bsto0u.s[16];
      task.$error(function (error) {
        ++cov_1ux1bsto0u.f[5];

        var errors = (++cov_1ux1bsto0u.s[17], {
          'storage/unauthorized': 'User does not have permission to access the object.',
          'storage/canceled': 'User canceled the upload.',
          'storage/unknown': 'Unknown error occurred, Please try later.'
        });
        ++cov_1ux1bsto0u.s[18];
        defer.reject((++cov_1ux1bsto0u.b[0][0], errors[error.code]) || (++cov_1ux1bsto0u.b[0][1], 'Something broke.'));
      });
      ++cov_1ux1bsto0u.s[19];
      return defer.promise;
    }
  }

  ++cov_1ux1bsto0u.s[20];
  angular.module('dc-common').factory('FileService', ['$q', 'StorageService', '$db', '$log', FileService]);
})();