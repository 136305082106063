'use strict';

var cov_kr1ifm6ae = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/projects/project-priorities/project-priorities.js',
      hash = 'eb3059965cfa5571882e253a07a0efd17fbaf259',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/projects/project-priorities/project-priorities.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 55,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 28
        },
        end: {
          line: 6,
          column: 3
        }
      },
      '2': {
        start: {
          line: 8,
          column: 2
        },
        end: {
          line: 8,
          column: 43
        }
      },
      '3': {
        start: {
          line: 11,
          column: 17
        },
        end: {
          line: 11,
          column: 21
        }
      },
      '4': {
        start: {
          line: 12,
          column: 4
        },
        end: {
          line: 16,
          column: 6
        }
      },
      '5': {
        start: {
          line: 13,
          column: 6
        },
        end: {
          line: 13,
          column: 45
        }
      },
      '6': {
        start: {
          line: 14,
          column: 6
        },
        end: {
          line: 14,
          column: 26
        }
      },
      '7': {
        start: {
          line: 15,
          column: 6
        },
        end: {
          line: 15,
          column: 33
        }
      },
      '8': {
        start: {
          line: 18,
          column: 4
        },
        end: {
          line: 18,
          column: 21
        }
      },
      '9': {
        start: {
          line: 19,
          column: 4
        },
        end: {
          line: 25,
          column: 6
        }
      },
      '10': {
        start: {
          line: 20,
          column: 6
        },
        end: {
          line: 23,
          column: 7
        }
      },
      '11': {
        start: {
          line: 21,
          column: 8
        },
        end: {
          line: 21,
          column: 34
        }
      },
      '12': {
        start: {
          line: 22,
          column: 8
        },
        end: {
          line: 22,
          column: 15
        }
      },
      '13': {
        start: {
          line: 24,
          column: 6
        },
        end: {
          line: 24,
          column: 23
        }
      },
      '14': {
        start: {
          line: 26,
          column: 4
        },
        end: {
          line: 37,
          column: 6
        }
      },
      '15': {
        start: {
          line: 27,
          column: 6
        },
        end: {
          line: 36,
          column: 7
        }
      },
      '16': {
        start: {
          line: 28,
          column: 8
        },
        end: {
          line: 28,
          column: 56
        }
      },
      '17': {
        start: {
          line: 29,
          column: 8
        },
        end: {
          line: 29,
          column: 37
        }
      },
      '18': {
        start: {
          line: 30,
          column: 13
        },
        end: {
          line: 36,
          column: 7
        }
      },
      '19': {
        start: {
          line: 31,
          column: 8
        },
        end: {
          line: 31,
          column: 55
        }
      },
      '20': {
        start: {
          line: 32,
          column: 8
        },
        end: {
          line: 32,
          column: 36
        }
      },
      '21': {
        start: {
          line: 33,
          column: 13
        },
        end: {
          line: 36,
          column: 7
        }
      },
      '22': {
        start: {
          line: 34,
          column: 8
        },
        end: {
          line: 34,
          column: 54
        }
      },
      '23': {
        start: {
          line: 35,
          column: 8
        },
        end: {
          line: 35,
          column: 35
        }
      },
      '24': {
        start: {
          line: 38,
          column: 4
        },
        end: {
          line: 49,
          column: 6
        }
      },
      '25': {
        start: {
          line: 39,
          column: 6
        },
        end: {
          line: 48,
          column: 7
        }
      },
      '26': {
        start: {
          line: 40,
          column: 8
        },
        end: {
          line: 40,
          column: 55
        }
      },
      '27': {
        start: {
          line: 41,
          column: 8
        },
        end: {
          line: 41,
          column: 36
        }
      },
      '28': {
        start: {
          line: 42,
          column: 13
        },
        end: {
          line: 48,
          column: 7
        }
      },
      '29': {
        start: {
          line: 43,
          column: 8
        },
        end: {
          line: 43,
          column: 54
        }
      },
      '30': {
        start: {
          line: 44,
          column: 8
        },
        end: {
          line: 44,
          column: 35
        }
      },
      '31': {
        start: {
          line: 45,
          column: 13
        },
        end: {
          line: 48,
          column: 7
        }
      },
      '32': {
        start: {
          line: 46,
          column: 8
        },
        end: {
          line: 46,
          column: 54
        }
      },
      '33': {
        start: {
          line: 47,
          column: 8
        },
        end: {
          line: 47,
          column: 35
        }
      },
      '34': {
        start: {
          line: 52,
          column: 2
        },
        end: {
          line: 54,
          column: 55
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 55,
            column: 1
          }
        }
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 10,
            column: 11
          },
          end: {
            line: 10,
            column: 21
          }
        },
        loc: {
          start: {
            line: 10,
            column: 37
          },
          end: {
            line: 50,
            column: 3
          }
        }
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 12,
            column: 19
          },
          end: {
            line: 12,
            column: 20
          }
        },
        loc: {
          start: {
            line: 12,
            column: 25
          },
          end: {
            line: 16,
            column: 5
          }
        }
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 19,
            column: 24
          },
          end: {
            line: 19,
            column: 25
          }
        },
        loc: {
          start: {
            line: 19,
            column: 30
          },
          end: {
            line: 25,
            column: 5
          }
        }
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 26,
            column: 22
          },
          end: {
            line: 26,
            column: 23
          }
        },
        loc: {
          start: {
            line: 26,
            column: 28
          },
          end: {
            line: 37,
            column: 5
          }
        }
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 38,
            column: 21
          },
          end: {
            line: 38,
            column: 22
          }
        },
        loc: {
          start: {
            line: 38,
            column: 27
          },
          end: {
            line: 49,
            column: 5
          }
        }
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 20,
            column: 6
          },
          end: {
            line: 23,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 20,
            column: 6
          },
          end: {
            line: 23,
            column: 7
          }
        }, {
          start: {
            line: 20,
            column: 6
          },
          end: {
            line: 23,
            column: 7
          }
        }]
      },
      '1': {
        loc: {
          start: {
            line: 27,
            column: 6
          },
          end: {
            line: 36,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 27,
            column: 6
          },
          end: {
            line: 36,
            column: 7
          }
        }, {
          start: {
            line: 27,
            column: 6
          },
          end: {
            line: 36,
            column: 7
          }
        }]
      },
      '2': {
        loc: {
          start: {
            line: 30,
            column: 13
          },
          end: {
            line: 36,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 30,
            column: 13
          },
          end: {
            line: 36,
            column: 7
          }
        }, {
          start: {
            line: 30,
            column: 13
          },
          end: {
            line: 36,
            column: 7
          }
        }]
      },
      '3': {
        loc: {
          start: {
            line: 33,
            column: 13
          },
          end: {
            line: 36,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 33,
            column: 13
          },
          end: {
            line: 36,
            column: 7
          }
        }, {
          start: {
            line: 33,
            column: 13
          },
          end: {
            line: 36,
            column: 7
          }
        }]
      },
      '4': {
        loc: {
          start: {
            line: 39,
            column: 6
          },
          end: {
            line: 48,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 39,
            column: 6
          },
          end: {
            line: 48,
            column: 7
          }
        }, {
          start: {
            line: 39,
            column: 6
          },
          end: {
            line: 48,
            column: 7
          }
        }]
      },
      '5': {
        loc: {
          start: {
            line: 42,
            column: 13
          },
          end: {
            line: 48,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 42,
            column: 13
          },
          end: {
            line: 48,
            column: 7
          }
        }, {
          start: {
            line: 42,
            column: 13
          },
          end: {
            line: 48,
            column: 7
          }
        }]
      },
      '6': {
        loc: {
          start: {
            line: 45,
            column: 13
          },
          end: {
            line: 48,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 45,
            column: 13
          },
          end: {
            line: 48,
            column: 7
          }
        }, {
          start: {
            line: 45,
            column: 13
          },
          end: {
            line: 48,
            column: 7
          }
        }]
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_kr1ifm6ae.s[0];
(function () {
  ++cov_kr1ifm6ae.f[0];

  var projectPriorities = (++cov_kr1ifm6ae.s[1], {
    templateUrl: 'app/projects/project-priorities/project-priorities.html',
    controller: controller
  });

  ++cov_kr1ifm6ae.s[2];
  controller.$inject = ['$state', 'Users'];

  function controller($state, Users) {
    ++cov_kr1ifm6ae.f[1];

    var self = (++cov_kr1ifm6ae.s[3], this);
    ++cov_kr1ifm6ae.s[4];
    self.$onInit = function () {
      ++cov_kr1ifm6ae.f[2];
      ++cov_kr1ifm6ae.s[5];

      self.roles = Users.currentUser().roles;
      ++cov_kr1ifm6ae.s[6];
      self.state = $state;
      ++cov_kr1ifm6ae.s[7];
      self.toggleVisible = false;
    };

    ++cov_kr1ifm6ae.s[8];
    self.filter = '';
    ++cov_kr1ifm6ae.s[9];
    self.toggleFilter = function () {
      ++cov_kr1ifm6ae.f[3];
      ++cov_kr1ifm6ae.s[10];

      if (self.filter === '') {
        ++cov_kr1ifm6ae.b[0][0];
        ++cov_kr1ifm6ae.s[11];

        self.filter = 'timestamp';
        ++cov_kr1ifm6ae.s[12];
        return;
      } else {
        ++cov_kr1ifm6ae.b[0][1];
      }
      ++cov_kr1ifm6ae.s[13];
      self.filter = '';
    };
    ++cov_kr1ifm6ae.s[14];
    self.rightSwipe = function () {
      ++cov_kr1ifm6ae.f[4];
      ++cov_kr1ifm6ae.s[15];

      if ($state.current.name === 'app.projects.priorities.databases') {
        ++cov_kr1ifm6ae.b[1][0];
        ++cov_kr1ifm6ae.s[16];

        $state.go('app.projects.priorities.submittals');
        ++cov_kr1ifm6ae.s[17];
        self.priority = 'submittals';
      } else {
          ++cov_kr1ifm6ae.b[1][1];
          ++cov_kr1ifm6ae.s[18];
          if ($state.current.name === 'app.projects.priorities.graphics') {
            ++cov_kr1ifm6ae.b[2][0];
            ++cov_kr1ifm6ae.s[19];

            $state.go('app.projects.priorities.databases');
            ++cov_kr1ifm6ae.s[20];
            self.priority = 'databases';
          } else {
              ++cov_kr1ifm6ae.b[2][1];
              ++cov_kr1ifm6ae.s[21];
              if ($state.current.name === 'app.projects.priorities.asbuilts') {
                ++cov_kr1ifm6ae.b[3][0];
                ++cov_kr1ifm6ae.s[22];

                $state.go('app.projects.priorities.graphics');
                ++cov_kr1ifm6ae.s[23];
                self.priority = 'graphics';
              } else {
                ++cov_kr1ifm6ae.b[3][1];
              }
            }
        }
    };
    ++cov_kr1ifm6ae.s[24];
    self.leftSwipe = function () {
      ++cov_kr1ifm6ae.f[5];
      ++cov_kr1ifm6ae.s[25];

      if ($state.current.name === 'app.projects.priorities.submittals') {
        ++cov_kr1ifm6ae.b[4][0];
        ++cov_kr1ifm6ae.s[26];

        $state.go('app.projects.priorities.databases');
        ++cov_kr1ifm6ae.s[27];
        self.priority = 'databases';
      } else {
          ++cov_kr1ifm6ae.b[4][1];
          ++cov_kr1ifm6ae.s[28];
          if ($state.current.name === 'app.projects.priorities.databases') {
            ++cov_kr1ifm6ae.b[5][0];
            ++cov_kr1ifm6ae.s[29];

            $state.go('app.projects.priorities.graphics');
            ++cov_kr1ifm6ae.s[30];
            self.priority = 'graphics';
          } else {
              ++cov_kr1ifm6ae.b[5][1];
              ++cov_kr1ifm6ae.s[31];
              if ($state.current.name === 'app.projects.priorities.graphics') {
                ++cov_kr1ifm6ae.b[6][0];
                ++cov_kr1ifm6ae.s[32];

                $state.go('app.projects.priorities.asbuilts');
                ++cov_kr1ifm6ae.s[33];
                self.priority = 'asbuilts';
              } else {
                ++cov_kr1ifm6ae.b[6][1];
              }
            }
        }
    };
  }

  ++cov_kr1ifm6ae.s[34];
  angular.module('app').component('projectPriorities', projectPriorities);
})();