'use strict';

var cov_ggp5vitdo = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/components/date-picker/date-picker.js',
      hash = 'e7abcef06e5a193dcb621e99922f6e746e41f7f7',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/components/date-picker/date-picker.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 39,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 21
        },
        end: {
          line: 10,
          column: 3
        }
      },
      '2': {
        start: {
          line: 13,
          column: 17
        },
        end: {
          line: 13,
          column: 21
        }
      },
      '3': {
        start: {
          line: 15,
          column: 4
        },
        end: {
          line: 15,
          column: 24
        }
      },
      '4': {
        start: {
          line: 16,
          column: 4
        },
        end: {
          line: 18,
          column: 6
        }
      },
      '5': {
        start: {
          line: 17,
          column: 6
        },
        end: {
          line: 17,
          column: 33
        }
      },
      '6': {
        start: {
          line: 19,
          column: 4
        },
        end: {
          line: 19,
          column: 28
        }
      },
      '7': {
        start: {
          line: 20,
          column: 4
        },
        end: {
          line: 23,
          column: 6
        }
      },
      '8': {
        start: {
          line: 25,
          column: 4
        },
        end: {
          line: 31,
          column: 6
        }
      },
      '9': {
        start: {
          line: 26,
          column: 6
        },
        end: {
          line: 26,
          column: 21
        }
      },
      '10': {
        start: {
          line: 27,
          column: 6
        },
        end: {
          line: 27,
          column: 37
        }
      },
      '11': {
        start: {
          line: 28,
          column: 6
        },
        end: {
          line: 30,
          column: 9
        }
      },
      '12': {
        start: {
          line: 29,
          column: 8
        },
        end: {
          line: 29,
          column: 24
        }
      },
      '13': {
        start: {
          line: 34,
          column: 2
        },
        end: {
          line: 34,
          column: 44
        }
      },
      '14': {
        start: {
          line: 36,
          column: 2
        },
        end: {
          line: 38,
          column: 41
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 39,
            column: 1
          }
        }
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 12,
            column: 11
          },
          end: {
            line: 12,
            column: 21
          }
        },
        loc: {
          start: {
            line: 12,
            column: 38
          },
          end: {
            line: 32,
            column: 3
          }
        }
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 16,
            column: 16
          },
          end: {
            line: 16,
            column: 17
          }
        },
        loc: {
          start: {
            line: 16,
            column: 22
          },
          end: {
            line: 18,
            column: 5
          }
        }
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 25,
            column: 17
          },
          end: {
            line: 25,
            column: 18
          }
        },
        loc: {
          start: {
            line: 25,
            column: 25
          },
          end: {
            line: 31,
            column: 5
          }
        }
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 28,
            column: 15
          },
          end: {
            line: 28,
            column: 16
          }
        },
        loc: {
          start: {
            line: 28,
            column: 21
          },
          end: {
            line: 30,
            column: 7
          }
        }
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_ggp5vitdo.s[0];
(function () {
  ++cov_ggp5vitdo.f[0];

  var datePicker = (++cov_ggp5vitdo.s[1], {
    bindings: {
      date: '=',
      callback: '&'
    },
    templateUrl: 'app/components/date-picker/date-picker.html',
    controller: controller
  });

  function controller($log, $timeout) {
    ++cov_ggp5vitdo.f[1];

    var self = (++cov_ggp5vitdo.s[2], this);

    ++cov_ggp5vitdo.s[3];
    self.isOpen = false;
    ++cov_ggp5vitdo.s[4];
    self.open = function () {
      ++cov_ggp5vitdo.f[2];
      ++cov_ggp5vitdo.s[5];

      self.isOpen = !self.isOpen;
    };
    ++cov_ggp5vitdo.s[6];
    self.today = new Date();
    ++cov_ggp5vitdo.s[7];
    self.dateOptions = {
      minDate: self.today,
      showWeeks: false
    };

    ++cov_ggp5vitdo.s[8];
    self.toIso = function (date) {
      ++cov_ggp5vitdo.f[3];
      ++cov_ggp5vitdo.s[9];

      $log.log(date);
      ++cov_ggp5vitdo.s[10];
      self.date = date.toISOString();
      ++cov_ggp5vitdo.s[11];
      $timeout(function () {
        ++cov_ggp5vitdo.f[4];
        ++cov_ggp5vitdo.s[12];

        self.callback();
      });
    };
  }

  ++cov_ggp5vitdo.s[13];
  controller.$inject = ['$log', '$timeout'];

  ++cov_ggp5vitdo.s[14];
  angular.module('app').component('datePicker', datePicker);
})();