'use strict';

var cov_28rpt1u8rk = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/project/project-detail/project-detail.js',
      hash = 'f350a66cb2c633d5a38fa6f08b5d9bad2b498b26',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/project/project-detail/project-detail.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 120,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 25
        },
        end: {
          line: 9,
          column: 3
        }
      },
      '2': {
        start: {
          line: 11,
          column: 2
        },
        end: {
          line: 11,
          column: 102
        }
      },
      '3': {
        start: {
          line: 14,
          column: 17
        },
        end: {
          line: 14,
          column: 21
        }
      },
      '4': {
        start: {
          line: 16,
          column: 4
        },
        end: {
          line: 16,
          column: 40
        }
      },
      '5': {
        start: {
          line: 18,
          column: 4
        },
        end: {
          line: 29,
          column: 6
        }
      },
      '6': {
        start: {
          line: 31,
          column: 4
        },
        end: {
          line: 108,
          column: 6
        }
      },
      '7': {
        start: {
          line: 32,
          column: 19
        },
        end: {
          line: 32,
          column: 38
        }
      },
      '8': {
        start: {
          line: 33,
          column: 23
        },
        end: {
          line: 33,
          column: 44
        }
      },
      '9': {
        start: {
          line: 35,
          column: 6
        },
        end: {
          line: 67,
          column: 7
        }
      },
      '10': {
        start: {
          line: 37,
          column: 10
        },
        end: {
          line: 37,
          column: 44
        }
      },
      '11': {
        start: {
          line: 38,
          column: 10
        },
        end: {
          line: 38,
          column: 16
        }
      },
      '12': {
        start: {
          line: 40,
          column: 10
        },
        end: {
          line: 40,
          column: 45
        }
      },
      '13': {
        start: {
          line: 41,
          column: 10
        },
        end: {
          line: 41,
          column: 16
        }
      },
      '14': {
        start: {
          line: 43,
          column: 10
        },
        end: {
          line: 43,
          column: 45
        }
      },
      '15': {
        start: {
          line: 44,
          column: 10
        },
        end: {
          line: 44,
          column: 16
        }
      },
      '16': {
        start: {
          line: 46,
          column: 10
        },
        end: {
          line: 46,
          column: 43
        }
      },
      '17': {
        start: {
          line: 47,
          column: 10
        },
        end: {
          line: 47,
          column: 16
        }
      },
      '18': {
        start: {
          line: 49,
          column: 10
        },
        end: {
          line: 49,
          column: 47
        }
      },
      '19': {
        start: {
          line: 50,
          column: 10
        },
        end: {
          line: 50,
          column: 16
        }
      },
      '20': {
        start: {
          line: 52,
          column: 10
        },
        end: {
          line: 52,
          column: 50
        }
      },
      '21': {
        start: {
          line: 53,
          column: 10
        },
        end: {
          line: 53,
          column: 16
        }
      },
      '22': {
        start: {
          line: 55,
          column: 10
        },
        end: {
          line: 55,
          column: 44
        }
      },
      '23': {
        start: {
          line: 56,
          column: 10
        },
        end: {
          line: 56,
          column: 16
        }
      },
      '24': {
        start: {
          line: 58,
          column: 10
        },
        end: {
          line: 58,
          column: 36
        }
      },
      '25': {
        start: {
          line: 59,
          column: 10
        },
        end: {
          line: 59,
          column: 16
        }
      },
      '26': {
        start: {
          line: 61,
          column: 10
        },
        end: {
          line: 61,
          column: 45
        }
      },
      '27': {
        start: {
          line: 62,
          column: 10
        },
        end: {
          line: 62,
          column: 16
        }
      },
      '28': {
        start: {
          line: 64,
          column: 10
        },
        end: {
          line: 64,
          column: 38
        }
      },
      '29': {
        start: {
          line: 65,
          column: 10
        },
        end: {
          line: 65,
          column: 16
        }
      },
      '30': {
        start: {
          line: 70,
          column: 6
        },
        end: {
          line: 107,
          column: 7
        }
      },
      '31': {
        start: {
          line: 72,
          column: 8
        },
        end: {
          line: 74,
          column: 9
        }
      },
      '32': {
        start: {
          line: 73,
          column: 10
        },
        end: {
          line: 73,
          column: 39
        }
      },
      '33': {
        start: {
          line: 76,
          column: 8
        },
        end: {
          line: 90,
          column: 9
        }
      },
      '34': {
        start: {
          line: 77,
          column: 10
        },
        end: {
          line: 77,
          column: 52
        }
      },
      '35': {
        start: {
          line: 78,
          column: 10
        },
        end: {
          line: 88,
          column: 13
        }
      },
      '36': {
        start: {
          line: 79,
          column: 29
        },
        end: {
          line: 86,
          column: 13
        }
      },
      '37': {
        start: {
          line: 87,
          column: 12
        },
        end: {
          line: 87,
          column: 42
        }
      },
      '38': {
        start: {
          line: 89,
          column: 10
        },
        end: {
          line: 89,
          column: 17
        }
      },
      '39': {
        start: {
          line: 92,
          column: 8
        },
        end: {
          line: 95,
          column: 10
        }
      },
      '40': {
        start: {
          line: 96,
          column: 8
        },
        end: {
          line: 106,
          column: 11
        }
      },
      '41': {
        start: {
          line: 97,
          column: 27
        },
        end: {
          line: 104,
          column: 11
        }
      },
      '42': {
        start: {
          line: 105,
          column: 10
        },
        end: {
          line: 105,
          column: 40
        }
      },
      '43': {
        start: {
          line: 110,
          column: 4
        },
        end: {
          line: 110,
          column: 28
        }
      },
      '44': {
        start: {
          line: 112,
          column: 4
        },
        end: {
          line: 114,
          column: 6
        }
      },
      '45': {
        start: {
          line: 113,
          column: 6
        },
        end: {
          line: 113,
          column: 30
        }
      },
      '46': {
        start: {
          line: 117,
          column: 2
        },
        end: {
          line: 119,
          column: 49
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 120,
            column: 1
          }
        }
      },
      '1': {
        name: 'projectDetailsCtrl',
        decl: {
          start: {
            line: 13,
            column: 11
          },
          end: {
            line: 13,
            column: 29
          }
        },
        loc: {
          start: {
            line: 13,
            column: 90
          },
          end: {
            line: 115,
            column: 3
          }
        }
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 31,
            column: 27
          },
          end: {
            line: 31,
            column: 28
          }
        },
        loc: {
          start: {
            line: 31,
            column: 40
          },
          end: {
            line: 108,
            column: 5
          }
        }
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 78,
            column: 36
          },
          end: {
            line: 78,
            column: 37
          }
        },
        loc: {
          start: {
            line: 78,
            column: 42
          },
          end: {
            line: 88,
            column: 11
          }
        }
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 96,
            column: 34
          },
          end: {
            line: 96,
            column: 35
          }
        },
        loc: {
          start: {
            line: 96,
            column: 40
          },
          end: {
            line: 106,
            column: 9
          }
        }
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 110,
            column: 19
          },
          end: {
            line: 110,
            column: 20
          }
        },
        loc: {
          start: {
            line: 110,
            column: 25
          },
          end: {
            line: 110,
            column: 27
          }
        }
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 112,
            column: 22
          },
          end: {
            line: 112,
            column: 23
          }
        },
        loc: {
          start: {
            line: 112,
            column: 28
          },
          end: {
            line: 114,
            column: 5
          }
        }
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 35,
            column: 6
          },
          end: {
            line: 67,
            column: 7
          }
        },
        type: 'switch',
        locations: [{
          start: {
            line: 36,
            column: 8
          },
          end: {
            line: 38,
            column: 16
          }
        }, {
          start: {
            line: 39,
            column: 8
          },
          end: {
            line: 41,
            column: 16
          }
        }, {
          start: {
            line: 42,
            column: 8
          },
          end: {
            line: 44,
            column: 16
          }
        }, {
          start: {
            line: 45,
            column: 8
          },
          end: {
            line: 47,
            column: 16
          }
        }, {
          start: {
            line: 48,
            column: 8
          },
          end: {
            line: 50,
            column: 16
          }
        }, {
          start: {
            line: 51,
            column: 8
          },
          end: {
            line: 53,
            column: 16
          }
        }, {
          start: {
            line: 54,
            column: 8
          },
          end: {
            line: 56,
            column: 16
          }
        }, {
          start: {
            line: 57,
            column: 8
          },
          end: {
            line: 59,
            column: 16
          }
        }, {
          start: {
            line: 60,
            column: 8
          },
          end: {
            line: 62,
            column: 16
          }
        }, {
          start: {
            line: 63,
            column: 8
          },
          end: {
            line: 65,
            column: 16
          }
        }, {
          start: {
            line: 66,
            column: 8
          },
          end: {
            line: 66,
            column: 16
          }
        }]
      },
      '1': {
        loc: {
          start: {
            line: 70,
            column: 6
          },
          end: {
            line: 107,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 70,
            column: 6
          },
          end: {
            line: 107,
            column: 7
          }
        }, {
          start: {
            line: 70,
            column: 6
          },
          end: {
            line: 107,
            column: 7
          }
        }]
      },
      '2': {
        loc: {
          start: {
            line: 70,
            column: 10
          },
          end: {
            line: 70,
            column: 43
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 70,
            column: 10
          },
          end: {
            line: 70,
            column: 26
          }
        }, {
          start: {
            line: 70,
            column: 30
          },
          end: {
            line: 70,
            column: 43
          }
        }]
      },
      '3': {
        loc: {
          start: {
            line: 72,
            column: 8
          },
          end: {
            line: 74,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 72,
            column: 8
          },
          end: {
            line: 74,
            column: 9
          }
        }, {
          start: {
            line: 72,
            column: 8
          },
          end: {
            line: 74,
            column: 9
          }
        }]
      },
      '4': {
        loc: {
          start: {
            line: 76,
            column: 8
          },
          end: {
            line: 90,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 76,
            column: 8
          },
          end: {
            line: 90,
            column: 9
          }
        }, {
          start: {
            line: 76,
            column: 8
          },
          end: {
            line: 90,
            column: 9
          }
        }]
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0
    },
    b: {
      '0': [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_28rpt1u8rk.s[0];
(function () {
  ++cov_28rpt1u8rk.f[0];

  var projectDetails = (++cov_28rpt1u8rk.s[1], {
    bindings: {
      project: '<'
    },
    templateUrl: 'app/project/project-detail/project-detail.html',
    controller: projectDetailsCtrl
  });

  ++cov_28rpt1u8rk.s[2];
  projectDetailsCtrl.$inject = ['$log', 'responsiveBreakpoints', 'Utils', 'Users', 'ActivityService'];

  function projectDetailsCtrl($log, responsiveBreakpoints, Utils, Users, ActivityService) {
    ++cov_28rpt1u8rk.f[1];

    var self = (++cov_28rpt1u8rk.s[3], this);

    ++cov_28rpt1u8rk.s[4];
    self.screen = responsiveBreakpoints;

    ++cov_28rpt1u8rk.s[5];
    self.milestones = [{ id: 'materialOrdered', description: 'Material Ordered', order: 4 }, { id: 'materialReceived', description: 'Material Received', order: 5 }, { id: 'panelsFabricated', description: 'Panels Fabricated', order: 6 }, { id: 'submittalsSent', description: 'Submittals Sent', order: 2 }, { id: 'submittalsApproved', description: 'Submittals Approved', order: 3 }, { id: 'subsReleased', description: 'SubContractor Released', order: 7 }, { id: 'projectTurnover', description: 'Project Turnover', order: 1 }, { id: 'training', description: 'Training Complete', order: 8 }, { id: 'redlines', description: 'Redlines Received', order: 9 }, { id: 'omsent', description: 'O & M Sent', order: 10 }];

    ++cov_28rpt1u8rk.s[6];
    self.toggleMilestone = function (milestone) {
      ++cov_28rpt1u8rk.f[2];

      var user = (++cov_28rpt1u8rk.s[7], Users.currentUser());
      var username = (++cov_28rpt1u8rk.s[8], user.profile.username);
      var friendlyName = void 0;
      ++cov_28rpt1u8rk.s[9];
      switch (milestone) {
        case 'materialOrdered':
          ++cov_28rpt1u8rk.b[0][0];
          ++cov_28rpt1u8rk.s[10];

          friendlyName = 'Material Ordered';
          ++cov_28rpt1u8rk.s[11];
          break;
        case 'materialReceived':
          ++cov_28rpt1u8rk.b[0][1];
          ++cov_28rpt1u8rk.s[12];

          friendlyName = 'Material Received';
          ++cov_28rpt1u8rk.s[13];
          break;
        case 'panelsFabricated':
          ++cov_28rpt1u8rk.b[0][2];
          ++cov_28rpt1u8rk.s[14];

          friendlyName = 'Panels Fabricated';
          ++cov_28rpt1u8rk.s[15];
          break;
        case 'submittalsSent':
          ++cov_28rpt1u8rk.b[0][3];
          ++cov_28rpt1u8rk.s[16];

          friendlyName = 'Submittals Sent';
          ++cov_28rpt1u8rk.s[17];
          break;
        case 'submittalsApproved':
          ++cov_28rpt1u8rk.b[0][4];
          ++cov_28rpt1u8rk.s[18];

          friendlyName = 'Submittals Approved';
          ++cov_28rpt1u8rk.s[19];
          break;
        case 'subsReleased':
          ++cov_28rpt1u8rk.b[0][5];
          ++cov_28rpt1u8rk.s[20];

          friendlyName = 'SubContractor Released';
          ++cov_28rpt1u8rk.s[21];
          break;
        case 'projectTurnover':
          ++cov_28rpt1u8rk.b[0][6];
          ++cov_28rpt1u8rk.s[22];

          friendlyName = 'Project Turnover';
          ++cov_28rpt1u8rk.s[23];
          break;
        case 'training':
          ++cov_28rpt1u8rk.b[0][7];
          ++cov_28rpt1u8rk.s[24];

          friendlyName = 'Training';
          ++cov_28rpt1u8rk.s[25];
          break;
        case 'redlines':
          ++cov_28rpt1u8rk.b[0][8];
          ++cov_28rpt1u8rk.s[26];

          friendlyName = 'Redlines Received';
          ++cov_28rpt1u8rk.s[27];
          break;
        case 'omsent':
          ++cov_28rpt1u8rk.b[0][9];
          ++cov_28rpt1u8rk.s[28];

          friendlyName = 'O & M Sent';
          ++cov_28rpt1u8rk.s[29];
          break;
        default:
          ++cov_28rpt1u8rk.b[0][10];

      }
      // TODO: clean up permissions on function
      // checks permissions. could be cleaned up.
      ++cov_28rpt1u8rk.s[30];
      if ((++cov_28rpt1u8rk.b[2][0], user.roles.admin) || (++cov_28rpt1u8rk.b[2][1], user.roles.pm)) {
        ++cov_28rpt1u8rk.b[1][0];
        ++cov_28rpt1u8rk.s[31];

        // builds object if applicable
        if (!self.project.milestones) {
          ++cov_28rpt1u8rk.b[3][0];
          ++cov_28rpt1u8rk.s[32];

          self.project.milestones = {};
        } else {
          ++cov_28rpt1u8rk.b[3][1];
        }

        ++cov_28rpt1u8rk.s[33];
        if (self.project.milestones[milestone]) {
          ++cov_28rpt1u8rk.b[4][0];
          ++cov_28rpt1u8rk.s[34];

          delete self.project.milestones[milestone];
          ++cov_28rpt1u8rk.s[35];
          self.project.$save().then(function () {
            ++cov_28rpt1u8rk.f[3];

            var activity = (++cov_28rpt1u8rk.s[36], {
              userId: user.$id,
              project: self.project.uid,
              type: 'Milestone',
              action: 'Removed',
              info: friendlyName,
              link: milestone
            });
            ++cov_28rpt1u8rk.s[37];
            ActivityService.add(activity);
          });
          ++cov_28rpt1u8rk.s[38];
          return;
        } else {
          ++cov_28rpt1u8rk.b[4][1];
        }

        ++cov_28rpt1u8rk.s[39];
        self.project.milestones[milestone] = {
          timestamp: Utils.now(),
          user: username
        };
        ++cov_28rpt1u8rk.s[40];
        self.project.$save().then(function () {
          ++cov_28rpt1u8rk.f[4];

          var activity = (++cov_28rpt1u8rk.s[41], {
            userId: user.$id,
            project: self.project.uid,
            type: 'Milestone',
            action: 'Completed',
            info: friendlyName,
            link: milestone
          });
          ++cov_28rpt1u8rk.s[42];
          ActivityService.add(activity);
        });
      } else {
        ++cov_28rpt1u8rk.b[1][1];
      }
    };

    ++cov_28rpt1u8rk.s[43];
    self.$onInit = function () {
      ++cov_28rpt1u8rk.f[5];
    };

    ++cov_28rpt1u8rk.s[44];
    self.$onDestroy = function () {
      ++cov_28rpt1u8rk.f[6];
      ++cov_28rpt1u8rk.s[45];

      self.project.$destroy();
    };
  }

  ++cov_28rpt1u8rk.s[46];
  angular.module('app').component('projectDetails', projectDetails);
})();