'use strict';

var cov_2jnbbubns6 = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/common/comment/post-comment/post-comment.component.js',
      hash = '560dbc5200497b57b494373102acb11380dfcecf',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/common/comment/post-comment/post-comment.component.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 39,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 22
        },
        end: {
          line: 10,
          column: 3
        }
      },
      '2': {
        start: {
          line: 12,
          column: 2
        },
        end: {
          line: 12,
          column: 51
        }
      },
      '3': {
        start: {
          line: 15,
          column: 17
        },
        end: {
          line: 15,
          column: 21
        }
      },
      '4': {
        start: {
          line: 16,
          column: 17
        },
        end: {
          line: 16,
          column: 36
        }
      },
      '5': {
        start: {
          line: 17,
          column: 4
        },
        end: {
          line: 17,
          column: 22
        }
      },
      '6': {
        start: {
          line: 18,
          column: 4
        },
        end: {
          line: 18,
          column: 31
        }
      },
      '7': {
        start: {
          line: 19,
          column: 4
        },
        end: {
          line: 19,
          column: 40
        }
      },
      '8': {
        start: {
          line: 21,
          column: 4
        },
        end: {
          line: 33,
          column: 6
        }
      },
      '9': {
        start: {
          line: 22,
          column: 6
        },
        end: {
          line: 22,
          column: 39
        }
      },
      '10': {
        start: {
          line: 23,
          column: 6
        },
        end: {
          line: 25,
          column: 7
        }
      },
      '11': {
        start: {
          line: 24,
          column: 8
        },
        end: {
          line: 24,
          column: 46
        }
      },
      '12': {
        start: {
          line: 26,
          column: 6
        },
        end: {
          line: 28,
          column: 7
        }
      },
      '13': {
        start: {
          line: 27,
          column: 8
        },
        end: {
          line: 27,
          column: 44
        }
      },
      '14': {
        start: {
          line: 29,
          column: 6
        },
        end: {
          line: 32,
          column: 9
        }
      },
      '15': {
        start: {
          line: 30,
          column: 8
        },
        end: {
          line: 30,
          column: 35
        }
      },
      '16': {
        start: {
          line: 31,
          column: 8
        },
        end: {
          line: 31,
          column: 26
        }
      },
      '17': {
        start: {
          line: 36,
          column: 2
        },
        end: {
          line: 38,
          column: 43
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 39,
            column: 1
          }
        }
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 14,
            column: 11
          },
          end: {
            line: 14,
            column: 21
          }
        },
        loc: {
          start: {
            line: 14,
            column: 45
          },
          end: {
            line: 34,
            column: 3
          }
        }
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 21,
            column: 16
          },
          end: {
            line: 21,
            column: 17
          }
        },
        loc: {
          start: {
            line: 21,
            column: 22
          },
          end: {
            line: 33,
            column: 5
          }
        }
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 29,
            column: 44
          },
          end: {
            line: 29,
            column: 45
          }
        },
        loc: {
          start: {
            line: 29,
            column: 50
          },
          end: {
            line: 32,
            column: 7
          }
        }
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 23,
            column: 6
          },
          end: {
            line: 25,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 23,
            column: 6
          },
          end: {
            line: 25,
            column: 7
          }
        }, {
          start: {
            line: 23,
            column: 6
          },
          end: {
            line: 25,
            column: 7
          }
        }]
      },
      '1': {
        loc: {
          start: {
            line: 26,
            column: 6
          },
          end: {
            line: 28,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 26,
            column: 6
          },
          end: {
            line: 28,
            column: 7
          }
        }, {
          start: {
            line: 26,
            column: 6
          },
          end: {
            line: 28,
            column: 7
          }
        }]
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_2jnbbubns6.s[0];
(function () {
  ++cov_2jnbbubns6.f[0];

  var postComment = (++cov_2jnbbubns6.s[1], {
    bindings: {
      projectId: '<?',
      ticketId: '<?'
    },
    templateUrl: 'app/common/comment/post-comment/post-comment.html',
    controller: controller
  });

  ++cov_2jnbbubns6.s[2];
  controller.$inject = ['CommentService', 'Users'];

  function controller(CommentService, Users) {
    ++cov_2jnbbubns6.f[1];

    var self = (++cov_2jnbbubns6.s[3], this);
    var user = (++cov_2jnbbubns6.s[4], Users.currentUser());
    ++cov_2jnbbubns6.s[5];
    self.message = {};
    ++cov_2jnbbubns6.s[6];
    self.commentToggle = false;
    ++cov_2jnbbubns6.s[7];
    self.userImage = user.profile.image;

    ++cov_2jnbbubns6.s[8];
    self.post = function () {
      ++cov_2jnbbubns6.f[2];
      ++cov_2jnbbubns6.s[9];

      self.message.senderId = user.$id;
      ++cov_2jnbbubns6.s[10];
      if (angular.isDefined(self.projectId)) {
        ++cov_2jnbbubns6.b[0][0];
        ++cov_2jnbbubns6.s[11];

        self.message.project = self.projectId;
      } else {
        ++cov_2jnbbubns6.b[0][1];
      }
      ++cov_2jnbbubns6.s[12];
      if (angular.isDefined(self.ticketId)) {
        ++cov_2jnbbubns6.b[1][0];
        ++cov_2jnbbubns6.s[13];

        self.message.ticket = self.ticketId;
      } else {
        ++cov_2jnbbubns6.b[1][1];
      }
      ++cov_2jnbbubns6.s[14];
      CommentService.add(self.message).then(function () {
        ++cov_2jnbbubns6.f[3];
        ++cov_2jnbbubns6.s[15];

        self.commentToggle = false;
        ++cov_2jnbbubns6.s[16];
        self.message = {};
      });
    };
  }

  ++cov_2jnbbubns6.s[17];
  angular.module('dc-comments').component('postComment', postComment);
})();