'use strict';

var cov_2p30hmin86 = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/users/user-profile/user-profile.js',
      hash = 'ae507ac5d987dc984a647fb76448bea7d4ed3212',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/users/user-profile/user-profile.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 43,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 22
        },
        end: {
          line: 6,
          column: 3
        }
      },
      '2': {
        start: {
          line: 8,
          column: 2
        },
        end: {
          line: 8,
          column: 73
        }
      },
      '3': {
        start: {
          line: 11,
          column: 17
        },
        end: {
          line: 11,
          column: 21
        }
      },
      '4': {
        start: {
          line: 12,
          column: 21
        },
        end: {
          line: 12,
          column: 42
        }
      },
      '5': {
        start: {
          line: 13,
          column: 4
        },
        end: {
          line: 17,
          column: 5
        }
      },
      '6': {
        start: {
          line: 19,
          column: 4
        },
        end: {
          line: 19,
          column: 43
        }
      },
      '7': {
        start: {
          line: 21,
          column: 4
        },
        end: {
          line: 24,
          column: 6
        }
      },
      '8': {
        start: {
          line: 22,
          column: 6
        },
        end: {
          line: 22,
          column: 27
        }
      },
      '9': {
        start: {
          line: 23,
          column: 6
        },
        end: {
          line: 23,
          column: 35
        }
      },
      '10': {
        start: {
          line: 27,
          column: 4
        },
        end: {
          line: 37,
          column: 9
        }
      },
      '11': {
        start: {
          line: 30,
          column: 8
        },
        end: {
          line: 36,
          column: 11
        }
      },
      '12': {
        start: {
          line: 31,
          column: 10
        },
        end: {
          line: 31,
          column: 27
        }
      },
      '13': {
        start: {
          line: 32,
          column: 10
        },
        end: {
          line: 35,
          column: 15
        }
      },
      '14': {
        start: {
          line: 34,
          column: 14
        },
        end: {
          line: 34,
          column: 43
        }
      },
      '15': {
        start: {
          line: 40,
          column: 2
        },
        end: {
          line: 42,
          column: 43
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 43,
            column: 1
          }
        }
      },
      '1': {
        name: 'userProfileCtrl',
        decl: {
          start: {
            line: 10,
            column: 11
          },
          end: {
            line: 10,
            column: 26
          }
        },
        loc: {
          start: {
            line: 10,
            column: 65
          },
          end: {
            line: 38,
            column: 3
          }
        }
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 21,
            column: 22
          },
          end: {
            line: 21,
            column: 23
          }
        },
        loc: {
          start: {
            line: 21,
            column: 28
          },
          end: {
            line: 24,
            column: 5
          }
        }
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 29,
            column: 12
          },
          end: {
            line: 29,
            column: 13
          }
        },
        loc: {
          start: {
            line: 29,
            column: 23
          },
          end: {
            line: 37,
            column: 7
          }
        }
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 30,
            column: 24
          },
          end: {
            line: 30,
            column: 25
          }
        },
        loc: {
          start: {
            line: 30,
            column: 32
          },
          end: {
            line: 36,
            column: 9
          }
        }
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 33,
            column: 21
          },
          end: {
            line: 33,
            column: 22
          }
        },
        loc: {
          start: {
            line: 33,
            column: 33
          },
          end: {
            line: 35,
            column: 13
          }
        }
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 13,
            column: 4
          },
          end: {
            line: 17,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 13,
            column: 4
          },
          end: {
            line: 17,
            column: 5
          }
        }, {
          start: {
            line: 13,
            column: 4
          },
          end: {
            line: 17,
            column: 5
          }
        }]
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0
    },
    b: {
      '0': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_2p30hmin86.s[0];
(function () {
  ++cov_2p30hmin86.f[0];

  var userProfile = (++cov_2p30hmin86.s[1], {
    templateUrl: 'app/users/user-profile/user-profile.html',
    controller: userProfileCtrl
  });

  ++cov_2p30hmin86.s[2];
  userProfileCtrl.$inject = ['Users', '$stateParams', 'ActivityService'];

  function userProfileCtrl(Users, $stateParams, ActivityService) {
    ++cov_2p30hmin86.f[1];

    var self = (++cov_2p30hmin86.s[3], this);
    var username = (++cov_2p30hmin86.s[4], $stateParams.username);
    ++cov_2p30hmin86.s[5];
    if (!username) {
      // handle no username
      // redirect to user not found page?
      // $state.go()

      ++cov_2p30hmin86.b[0][0];
    } else {
      ++cov_2p30hmin86.b[0][1];
    }

    ++cov_2p30hmin86.s[6];
    self.currentUser = Users.currentUser();

    ++cov_2p30hmin86.s[7];
    self.$onDestroy = function () {
      ++cov_2p30hmin86.f[2];
      ++cov_2p30hmin86.s[8];

      self.user.$destroy();
      ++cov_2p30hmin86.s[9];
      self.userActivity.$destroy();
    };

    // find userobject by username
    ++cov_2p30hmin86.s[10];
    Users.getProfileByUsername(username).then(function (profile) {
      ++cov_2p30hmin86.f[3];
      ++cov_2p30hmin86.s[11];

      profile.$loaded(function (user) {
        ++cov_2p30hmin86.f[4];
        ++cov_2p30hmin86.s[12];

        self.user = user;
        ++cov_2p30hmin86.s[13];
        ActivityService.getActivitiesByUser(user.$id).$loaded(function (activity) {
          ++cov_2p30hmin86.f[5];
          ++cov_2p30hmin86.s[14];

          self.userActivity = activity;
        });
      });
    });
  }

  ++cov_2p30hmin86.s[15];
  angular.module('app').component('userProfile', userProfile);
})();