// 'use strict';
// (function () {
//   const userTags = {
//     bindings: {
//       ngModel: '=',
//       required: '<'
//     },
//     templateUrl: 'app/users/helpers/user-tags.html',
//     controller
//   };

//   controller.$inject = ['Users'];

//   function controller(Users) {
//     const self = this;

//     self.$onInit = () => {
//       Users.all().$loaded(users => {
//         self.users = users;
//       });
//     };
//   }

//   angular
//     .module('dc-user')
//     .component('userTags', userTags)
//     .config(['tagsInputConfigProvider', tagsInputConfigProvider => {
//       tagsInputConfigProvider.setDefaults('tagsInput', {
//         addFromAutocompleteOnly: true
//       });
//       tagsInputConfigProvider.setDefaults('autoComplete', {
//         loadOnFocus: true,
//         minLength: 1
//       });
//     }
//     ]);
// })();
"use strict";

var cov_2c1t0otnxm = function () {
  var path = "/Users/ryanluton/Developer/divcon/divcon-tools/src/app/users/helpers/user-tags.component.js",
      hash = "5e61d15b25eb366c6b2ecc967c40317b526576b8",
      global = new Function('return this')(),
      gcv = "__coverage__",
      coverageData = {
    path: "/Users/ryanluton/Developer/divcon/divcon-tools/src/app/users/helpers/user-tags.component.js",
    statementMap: {},
    fnMap: {},
    branchMap: {},
    s: {},
    f: {},
    b: {},
    _coverageSchema: "332fd63041d2c1bcb487cc26dd0d5f7d97098a6c"
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();