'use strict';

var cov_en58s51b3 = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/routes.js',
      hash = '2fe6becb04c78884d9137f08f27e3a29da5f00a4',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/routes.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 187,
          column: 5
        }
      },
      '1': {
        start: {
          line: 5,
          column: 4
        },
        end: {
          line: 5,
          column: 54
        }
      },
      '2': {
        start: {
          line: 6,
          column: 4
        },
        end: {
          line: 6,
          column: 48
        }
      },
      '3': {
        start: {
          line: 8,
          column: 4
        },
        end: {
          line: 168,
          column: 9
        }
      },
      '4': {
        start: {
          line: 19,
          column: 12
        },
        end: {
          line: 19,
          column: 48
        }
      },
      '5': {
        start: {
          line: 22,
          column: 12
        },
        end: {
          line: 22,
          column: 41
        }
      },
      '6': {
        start: {
          line: 77,
          column: 12
        },
        end: {
          line: 82,
          column: 14
        }
      },
      '7': {
        start: {
          line: 124,
          column: 12
        },
        end: {
          line: 124,
          column: 80
        }
      },
      '8': {
        start: {
          line: 173,
          column: 4
        },
        end: {
          line: 177,
          column: 7
        }
      },
      '9': {
        start: {
          line: 174,
          column: 6
        },
        end: {
          line: 176,
          column: 7
        }
      },
      '10': {
        start: {
          line: 175,
          column: 8
        },
        end: {
          line: 175,
          column: 27
        }
      },
      '11': {
        start: {
          line: 180,
          column: 4
        },
        end: {
          line: 180,
          column: 34
        }
      },
      '12': {
        start: {
          line: 183,
          column: 2
        },
        end: {
          line: 186,
          column: 40
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 187,
            column: 1
          }
        }
      },
      '1': {
        name: 'routesConfig',
        decl: {
          start: {
            line: 4,
            column: 11
          },
          end: {
            line: 4,
            column: 23
          }
        },
        loc: {
          start: {
            line: 4,
            column: 79
          },
          end: {
            line: 169,
            column: 3
          }
        }
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 18,
            column: 32
          },
          end: {
            line: 18,
            column: 33
          }
        },
        loc: {
          start: {
            line: 18,
            column: 48
          },
          end: {
            line: 20,
            column: 11
          }
        }
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 21,
            column: 27
          },
          end: {
            line: 21,
            column: 28
          }
        },
        loc: {
          start: {
            line: 21,
            column: 44
          },
          end: {
            line: 23,
            column: 11
          }
        }
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 123,
            column: 48
          },
          end: {
            line: 123,
            column: 49
          }
        },
        loc: {
          start: {
            line: 123,
            column: 82
          },
          end: {
            line: 125,
            column: 11
          }
        }
      },
      '5': {
        name: 'run',
        decl: {
          start: {
            line: 171,
            column: 11
          },
          end: {
            line: 171,
            column: 14
          }
        },
        loc: {
          start: {
            line: 171,
            column: 35
          },
          end: {
            line: 181,
            column: 3
          }
        }
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 173,
            column: 40
          },
          end: {
            line: 173,
            column: 41
          }
        },
        loc: {
          start: {
            line: 173,
            column: 100
          },
          end: {
            line: 177,
            column: 5
          }
        }
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 174,
            column: 6
          },
          end: {
            line: 176,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 174,
            column: 6
          },
          end: {
            line: 176,
            column: 7
          }
        }, {
          start: {
            line: 174,
            column: 6
          },
          end: {
            line: 176,
            column: 7
          }
        }]
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0
    },
    b: {
      '0': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_en58s51b3.s[0];
(function () {
  ++cov_en58s51b3.f[0];

  /** @ngInject */
  function routesConfig($stateProvider, $urlRouterProvider, $locationProvider) {
    ++cov_en58s51b3.f[1];
    ++cov_en58s51b3.s[1];

    $locationProvider.html5Mode(true).hashPrefix('!');
    ++cov_en58s51b3.s[2];
    $urlRouterProvider.otherwise('/auth/login');

    ++cov_en58s51b3.s[3];
    $stateProvider.state('app', {
      url: '/app',
      component: 'app',
      abstract: true,
      bindings: {
        currentAuth: 'currentAuth',
        users: 'User'
      },
      resolve: {
        currentAuth: ['Auth', function (Auth) {
          ++cov_en58s51b3.f[2];
          ++cov_en58s51b3.s[4];

          return Auth.requireAuthentication();
        }],
        users: ['Users', function (Users) {
          ++cov_en58s51b3.f[3];
          ++cov_en58s51b3.s[5];

          return Users.all().$loaded();
        }]
      },
      data: {
        authRequired: true
      }
    }).state('app.main', {
      url: '/',
      templateUrl: 'app/dashboard/dashboard.html'
    })
    // products
    // .state('app.products', {
    //   url: '/products',
    //   template: '<ui-view></ui-view>',
    //   controller: 'productsCtrl',
    //   controllerAs: 'vm',
    //   abstract: true
    // })
    // .state('app.products.list', {
    //   url: '/',
    //   templateUrl: 'products/list'
    // })
    // .state('app.products.edit', {
    //   url: '/edit/:id',
    //   templateUrl: 'products/create'
    // })
    // .state('app.products.create', {
    //   url: '/create',
    //   templateUrl: 'products/create'
    // })
    // projects
    .state('app.projects', {
      url: '/projects',
      template: '<ui-view></ui-view>',
      abstract: true
    }).state('app.projects.list', {
      url: '',
      templateUrl: 'app/projects/projects-list.html'
    }).state('app.projects.create', {
      url: '/create',
      component: 'createProject'
    }).state('app.projects.edit', {
      url: '/edit/:projectID',
      component: 'editProject'
    }).state('app.projects.priorities', {
      url: '/priorities',
      component: 'projectPriorities',
      redirectTo: 'app.projects.priorities.submittals',
      resolve: {
        priority: function priority() {
          ++cov_en58s51b3.s[6];

          return {
            submittals: 'submittals',
            databases: 'databases',
            graphics: 'graphics',
            asbuilts: 'asbuilts'
          };
        }
      }
    }).state('app.projects.priorities.submittals', {
      url: '/submittals',
      component: 'priorityType',
      bindings: {
        projects: 'projects',
        priority: 'priority.submittals'
      }
    }).state('app.projects.priorities.databases', {
      url: '/databases',
      component: 'priorityType',
      bindings: {
        priority: 'priority.databases'
      }
    }).state('app.projects.priorities.graphics', {
      url: '/graphics',
      component: 'priorityType',
      bindings: {
        priority: 'priority.graphics'
      }
    }).state('app.projects.priorities.asbuilts', {
      url: '/asbuilts',
      component: 'priorityType',
      bindings: {
        priority: 'priority.asbuilts'
      }
    }).state('app.project', {
      url: '/projects/:projectID',
      component: 'projectDetails',
      redirectTo: 'app.project.board',
      bindings: {
        project: 'project'
      },
      resolve: {
        project: ['Projects', '$transition$', function (Projects, $transition$) {
          ++cov_en58s51b3.f[4];
          ++cov_en58s51b3.s[7];

          return Projects.getProjectByNumber($transition$.params().projectID);
        }]
      }
    }).state('app.project.board', {
      component: 'comments',
      bindings: {
        projectId: 'project.$id'
      }
    }).state('app.project.numbers', {
      component: 'projectNumbers',
      bindings: {
        projectId: 'project.$id'
      }
    }).state('app.project.tickets', {
      component: 'projectTickets',
      bindings: {
        projectId: 'project.$id'
      }
    }).state('app.project.checkout', {
      templateUrl: 'app/project/checkout-sheets/checkout-sheets.html'
    }).state('app.project.activity', {
      component: 'activity',
      bindings: {
        projectId: 'project.$id'
      }
    })
    // profile
    .state('app.profile', {
      url: '/profile/:username',
      template: '<ui-view></ui-view>',
      abstract: true
    }).state('app.profile.main', {
      url: '/',
      component: 'userProfile'
    }).state('app.profile.edit', {
      url: '/edit',
      component: 'editProfile'
    });
  }

  function run($rootScope, $state) {
    ++cov_en58s51b3.f[5];
    ++cov_en58s51b3.s[8];

    /** @ngInject */
    $rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams, error) {
      ++cov_en58s51b3.f[6];
      ++cov_en58s51b3.s[9];
      // eslint-disable-line angular/on-watch
      if (error === 'AUTH_REQUIRED') {
        ++cov_en58s51b3.b[0][0];
        ++cov_en58s51b3.s[10];

        $state.go('login');
      } else {
        ++cov_en58s51b3.b[0][1];
      }
    });

    // disables Dropzone auto initialization
    ++cov_en58s51b3.s[11];
    Dropzone.autoDiscover = false;
  }

  ++cov_en58s51b3.s[12];
  angular.module('app').config(['$stateProvider', '$urlRouterProvider', '$locationProvider', routesConfig]).run(['$rootScope', '$state', run]);
})();