'use strict';

var cov_1vbh7zi5r5 = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/project/project-numbers/project-numbers.js',
      hash = '383801c4bd9728dda0c8da92e9a642205e92efd8',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/project/project-numbers/project-numbers.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 139,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 25
        },
        end: {
          line: 9,
          column: 3
        }
      },
      '2': {
        start: {
          line: 11,
          column: 2
        },
        end: {
          line: 11,
          column: 45
        }
      },
      '3': {
        start: {
          line: 14,
          column: 17
        },
        end: {
          line: 14,
          column: 21
        }
      },
      '4': {
        start: {
          line: 15,
          column: 4
        },
        end: {
          line: 15,
          column: 30
        }
      },
      '5': {
        start: {
          line: 16,
          column: 4
        },
        end: {
          line: 37,
          column: 7
        }
      },
      '6': {
        start: {
          line: 42,
          column: 6
        },
        end: {
          line: 42,
          column: 28
        }
      },
      '7': {
        start: {
          line: 43,
          column: 6
        },
        end: {
          line: 43,
          column: 21
        }
      },
      '8': {
        start: {
          line: 50,
          column: 4
        },
        end: {
          line: 63,
          column: 6
        }
      },
      '9': {
        start: {
          line: 51,
          column: 6
        },
        end: {
          line: 62,
          column: 7
        }
      },
      '10': {
        start: {
          line: 52,
          column: 8
        },
        end: {
          line: 61,
          column: 13
        }
      },
      '11': {
        start: {
          line: 54,
          column: 12
        },
        end: {
          line: 54,
          column: 29
        }
      },
      '12': {
        start: {
          line: 55,
          column: 12
        },
        end: {
          line: 60,
          column: 13
        }
      },
      '13': {
        start: {
          line: 56,
          column: 14
        },
        end: {
          line: 56,
          column: 28
        }
      },
      '14': {
        start: {
          line: 57,
          column: 19
        },
        end: {
          line: 60,
          column: 13
        }
      },
      '15': {
        start: {
          line: 59,
          column: 14
        },
        end: {
          line: 59,
          column: 28
        }
      },
      '16': {
        start: {
          line: 65,
          column: 4
        },
        end: {
          line: 70,
          column: 6
        }
      },
      '17': {
        start: {
          line: 66,
          column: 6
        },
        end: {
          line: 68,
          column: 7
        }
      },
      '18': {
        start: {
          line: 67,
          column: 8
        },
        end: {
          line: 67,
          column: 29
        }
      },
      '19': {
        start: {
          line: 69,
          column: 6
        },
        end: {
          line: 69,
          column: 33
        }
      },
      '20': {
        start: {
          line: 72,
          column: 4
        },
        end: {
          line: 75,
          column: 6
        }
      },
      '21': {
        start: {
          line: 73,
          column: 6
        },
        end: {
          line: 73,
          column: 20
        }
      },
      '22': {
        start: {
          line: 74,
          column: 6
        },
        end: {
          line: 74,
          column: 30
        }
      },
      '23': {
        start: {
          line: 78,
          column: 19
        },
        end: {
          line: 78,
          column: 21
        }
      },
      '24': {
        start: {
          line: 79,
          column: 19
        },
        end: {
          line: 79,
          column: 20
        }
      },
      '25': {
        start: {
          line: 80,
          column: 6
        },
        end: {
          line: 85,
          column: 9
        }
      },
      '26': {
        start: {
          line: 81,
          column: 8
        },
        end: {
          line: 84,
          column: 9
        }
      },
      '27': {
        start: {
          line: 82,
          column: 10
        },
        end: {
          line: 82,
          column: 49
        }
      },
      '28': {
        start: {
          line: 83,
          column: 10
        },
        end: {
          line: 83,
          column: 35
        }
      },
      '29': {
        start: {
          line: 86,
          column: 6
        },
        end: {
          line: 88,
          column: 7
        }
      },
      '30': {
        start: {
          line: 87,
          column: 8
        },
        end: {
          line: 87,
          column: 30
        }
      },
      '31': {
        start: {
          line: 89,
          column: 6
        },
        end: {
          line: 91,
          column: 9
        }
      },
      '32': {
        start: {
          line: 96,
          column: 6
        },
        end: {
          line: 101,
          column: 11
        }
      },
      '33': {
        start: {
          line: 98,
          column: 10
        },
        end: {
          line: 98,
          column: 33
        }
      },
      '34': {
        start: {
          line: 99,
          column: 10
        },
        end: {
          line: 99,
          column: 24
        }
      },
      '35': {
        start: {
          line: 100,
          column: 10
        },
        end: {
          line: 100,
          column: 25
        }
      },
      '36': {
        start: {
          line: 104,
          column: 4
        },
        end: {
          line: 109,
          column: 6
        }
      },
      '37': {
        start: {
          line: 105,
          column: 6
        },
        end: {
          line: 108,
          column: 7
        }
      },
      '38': {
        start: {
          line: 106,
          column: 8
        },
        end: {
          line: 106,
          column: 38
        }
      },
      '39': {
        start: {
          line: 107,
          column: 8
        },
        end: {
          line: 107,
          column: 32
        }
      },
      '40': {
        start: {
          line: 112,
          column: 6
        },
        end: {
          line: 115,
          column: 11
        }
      },
      '41': {
        start: {
          line: 114,
          column: 10
        },
        end: {
          line: 114,
          column: 24
        }
      },
      '42': {
        start: {
          line: 119,
          column: 6
        },
        end: {
          line: 119,
          column: 19
        }
      },
      '43': {
        start: {
          line: 120,
          column: 6
        },
        end: {
          line: 120,
          column: 20
        }
      },
      '44': {
        start: {
          line: 121,
          column: 6
        },
        end: {
          line: 121,
          column: 19
        }
      },
      '45': {
        start: {
          line: 122,
          column: 6
        },
        end: {
          line: 131,
          column: 11
        }
      },
      '46': {
        start: {
          line: 124,
          column: 10
        },
        end: {
          line: 124,
          column: 38
        }
      },
      '47': {
        start: {
          line: 125,
          column: 10
        },
        end: {
          line: 127,
          column: 11
        }
      },
      '48': {
        start: {
          line: 126,
          column: 12
        },
        end: {
          line: 126,
          column: 34
        }
      },
      '49': {
        start: {
          line: 128,
          column: 10
        },
        end: {
          line: 130,
          column: 11
        }
      },
      '50': {
        start: {
          line: 129,
          column: 12
        },
        end: {
          line: 129,
          column: 38
        }
      },
      '51': {
        start: {
          line: 132,
          column: 6
        },
        end: {
          line: 132,
          column: 21
        }
      },
      '52': {
        start: {
          line: 136,
          column: 2
        },
        end: {
          line: 138,
          column: 49
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 139,
            column: 1
          }
        }
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 13,
            column: 11
          },
          end: {
            line: 13,
            column: 21
          }
        },
        loc: {
          start: {
            line: 13,
            column: 38
          },
          end: {
            line: 134,
            column: 3
          }
        }
      },
      '2': {
        name: 'onclick',
        decl: {
          start: {
            line: 40,
            column: 13
          },
          end: {
            line: 40,
            column: 20
          }
        },
        loc: {
          start: {
            line: 40,
            column: 24
          },
          end: {
            line: 44,
            column: 5
          }
        }
      },
      '3': {
        name: 'onmouseover',
        decl: {
          start: {
            line: 46,
            column: 13
          },
          end: {
            line: 46,
            column: 24
          }
        },
        loc: {
          start: {
            line: 46,
            column: 27
          },
          end: {
            line: 46,
            column: 29
          }
        }
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 50,
            column: 19
          },
          end: {
            line: 50,
            column: 20
          }
        },
        loc: {
          start: {
            line: 50,
            column: 31
          },
          end: {
            line: 63,
            column: 5
          }
        }
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 53,
            column: 19
          },
          end: {
            line: 53,
            column: 20
          }
        },
        loc: {
          start: {
            line: 53,
            column: 27
          },
          end: {
            line: 61,
            column: 11
          }
        }
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 65,
            column: 22
          },
          end: {
            line: 65,
            column: 23
          }
        },
        loc: {
          start: {
            line: 65,
            column: 34
          },
          end: {
            line: 70,
            column: 5
          }
        }
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 72,
            column: 20
          },
          end: {
            line: 72,
            column: 21
          }
        },
        loc: {
          start: {
            line: 72,
            column: 26
          },
          end: {
            line: 75,
            column: 5
          }
        }
      },
      '8': {
        name: 'updateCharts',
        decl: {
          start: {
            line: 77,
            column: 13
          },
          end: {
            line: 77,
            column: 25
          }
        },
        loc: {
          start: {
            line: 77,
            column: 28
          },
          end: {
            line: 93,
            column: 5
          }
        }
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 80,
            column: 39
          },
          end: {
            line: 80,
            column: 40
          }
        },
        loc: {
          start: {
            line: 80,
            column: 47
          },
          end: {
            line: 85,
            column: 7
          }
        }
      },
      '10': {
        name: 'loadNumbers',
        decl: {
          start: {
            line: 95,
            column: 13
          },
          end: {
            line: 95,
            column: 24
          }
        },
        loc: {
          start: {
            line: 95,
            column: 27
          },
          end: {
            line: 102,
            column: 5
          }
        }
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 97,
            column: 17
          },
          end: {
            line: 97,
            column: 18
          }
        },
        loc: {
          start: {
            line: 97,
            column: 25
          },
          end: {
            line: 101,
            column: 9
          }
        }
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 104,
            column: 19
          },
          end: {
            line: 104,
            column: 20
          }
        },
        loc: {
          start: {
            line: 104,
            column: 29
          },
          end: {
            line: 109,
            column: 5
          }
        }
      },
      '13': {
        name: 'setDefaults',
        decl: {
          start: {
            line: 111,
            column: 13
          },
          end: {
            line: 111,
            column: 24
          }
        },
        loc: {
          start: {
            line: 111,
            column: 27
          },
          end: {
            line: 116,
            column: 5
          }
        }
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 113,
            column: 14
          },
          end: {
            line: 113,
            column: 15
          }
        },
        loc: {
          start: {
            line: 113,
            column: 20
          },
          end: {
            line: 115,
            column: 9
          }
        }
      },
      '15': {
        name: 'numberCalcs',
        decl: {
          start: {
            line: 118,
            column: 13
          },
          end: {
            line: 118,
            column: 24
          }
        },
        loc: {
          start: {
            line: 118,
            column: 27
          },
          end: {
            line: 133,
            column: 5
          }
        }
      },
      '16': {
        name: '(anonymous_16)',
        decl: {
          start: {
            line: 123,
            column: 8
          },
          end: {
            line: 123,
            column: 9
          }
        },
        loc: {
          start: {
            line: 123,
            column: 15
          },
          end: {
            line: 131,
            column: 9
          }
        }
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 51,
            column: 6
          },
          end: {
            line: 62,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 51,
            column: 6
          },
          end: {
            line: 62,
            column: 7
          }
        }, {
          start: {
            line: 51,
            column: 6
          },
          end: {
            line: 62,
            column: 7
          }
        }]
      },
      '1': {
        loc: {
          start: {
            line: 55,
            column: 12
          },
          end: {
            line: 60,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 55,
            column: 12
          },
          end: {
            line: 60,
            column: 13
          }
        }, {
          start: {
            line: 55,
            column: 12
          },
          end: {
            line: 60,
            column: 13
          }
        }]
      },
      '2': {
        loc: {
          start: {
            line: 57,
            column: 19
          },
          end: {
            line: 60,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 57,
            column: 19
          },
          end: {
            line: 60,
            column: 13
          }
        }, {
          start: {
            line: 57,
            column: 19
          },
          end: {
            line: 60,
            column: 13
          }
        }]
      },
      '3': {
        loc: {
          start: {
            line: 66,
            column: 6
          },
          end: {
            line: 68,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 66,
            column: 6
          },
          end: {
            line: 68,
            column: 7
          }
        }, {
          start: {
            line: 66,
            column: 6
          },
          end: {
            line: 68,
            column: 7
          }
        }]
      },
      '4': {
        loc: {
          start: {
            line: 81,
            column: 8
          },
          end: {
            line: 84,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 81,
            column: 8
          },
          end: {
            line: 84,
            column: 9
          }
        }, {
          start: {
            line: 81,
            column: 8
          },
          end: {
            line: 84,
            column: 9
          }
        }]
      },
      '5': {
        loc: {
          start: {
            line: 86,
            column: 6
          },
          end: {
            line: 88,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 86,
            column: 6
          },
          end: {
            line: 88,
            column: 7
          }
        }, {
          start: {
            line: 86,
            column: 6
          },
          end: {
            line: 88,
            column: 7
          }
        }]
      },
      '6': {
        loc: {
          start: {
            line: 105,
            column: 6
          },
          end: {
            line: 108,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 105,
            column: 6
          },
          end: {
            line: 108,
            column: 7
          }
        }, {
          start: {
            line: 105,
            column: 6
          },
          end: {
            line: 108,
            column: 7
          }
        }]
      },
      '7': {
        loc: {
          start: {
            line: 125,
            column: 10
          },
          end: {
            line: 127,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 125,
            column: 10
          },
          end: {
            line: 127,
            column: 11
          }
        }, {
          start: {
            line: 125,
            column: 10
          },
          end: {
            line: 127,
            column: 11
          }
        }]
      },
      '8': {
        loc: {
          start: {
            line: 128,
            column: 10
          },
          end: {
            line: 130,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 128,
            column: 10
          },
          end: {
            line: 130,
            column: 11
          }
        }, {
          start: {
            line: 128,
            column: 10
          },
          end: {
            line: 130,
            column: 11
          }
        }]
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_1vbh7zi5r5.s[0];
(function () {
  ++cov_1vbh7zi5r5.f[0];

  var projectNumbers = (++cov_1vbh7zi5r5.s[1], {
    bindings: {
      projectId: '<'
    },
    templateUrl: 'app/project/project-numbers/project-numbers.html',
    controller: controller
  });

  ++cov_1vbh7zi5r5.s[2];
  controller.$inject = ['$scope', 'Numbers'];

  function controller(scope, Numbers) {
    ++cov_1vbh7zi5r5.f[1];

    var self = (++cov_1vbh7zi5r5.s[3], this);
    ++cov_1vbh7zi5r5.s[4];
    self.graphData = 'holder';
    ++cov_1vbh7zi5r5.s[5];
    self.chart = c3.generate({
      bindto: '#chart',
      size: {
        height: 160
      },
      legend: {
        show: false
      },
      pie: {
        label: {
          show: false
        }
      },
      data: {
        columns: [[]],
        type: 'pie',
        onclick: onclick,
        onmouseover: onmouseover
      }
    });

    // c3js interaction handlers
    function onclick(d) {
      ++cov_1vbh7zi5r5.f[2];
      ++cov_1vbh7zi5r5.s[6];

      // can pass i in onclick as well
      self.graphData = d.id;
      ++cov_1vbh7zi5r5.s[7];
      scope.$apply();
    }

    function onmouseover() {
      ++cov_1vbh7zi5r5.f[3];
    }

    // normal component functions

    ++cov_1vbh7zi5r5.s[8];
    self.$onInit = function () {
      ++cov_1vbh7zi5r5.f[4];
      ++cov_1vbh7zi5r5.s[9];

      if (self.projectId) {
        ++cov_1vbh7zi5r5.b[0][0];
        ++cov_1vbh7zi5r5.s[10];

        Numbers.get(self.projectId).$loaded(function (data) {
          ++cov_1vbh7zi5r5.f[5];
          ++cov_1vbh7zi5r5.s[11];

          self.data = data;
          ++cov_1vbh7zi5r5.s[12];
          if (data.$value !== null) {
            ++cov_1vbh7zi5r5.b[1][0];
            ++cov_1vbh7zi5r5.s[13];

            loadNumbers();
          } else {
              ++cov_1vbh7zi5r5.b[1][1];
              ++cov_1vbh7zi5r5.s[14];
              if (data.$value === null) {
                ++cov_1vbh7zi5r5.b[2][0];
                ++cov_1vbh7zi5r5.s[15];

                // set project defaults if null
                setDefaults();
              } else {
                ++cov_1vbh7zi5r5.b[2][1];
              }
            }
        });
      } else {
        ++cov_1vbh7zi5r5.b[0][1];
      }
    };

    ++cov_1vbh7zi5r5.s[16];
    self.$onDestroy = function () {
      ++cov_1vbh7zi5r5.f[6];
      ++cov_1vbh7zi5r5.s[17];

      if (self.data) {
        ++cov_1vbh7zi5r5.b[3][0];
        ++cov_1vbh7zi5r5.s[18];

        self.data.$destroy();
      } else {
        ++cov_1vbh7zi5r5.b[3][1];
      }
      ++cov_1vbh7zi5r5.s[19];
      self.financials.$destroy();
    };

    ++cov_1vbh7zi5r5.s[20];
    self.callback = function () {
      ++cov_1vbh7zi5r5.f[7];
      ++cov_1vbh7zi5r5.s[21];

      numberCalcs();
      ++cov_1vbh7zi5r5.s[22];
      self.financials.$save();
    };

    function updateCharts() {
      ++cov_1vbh7zi5r5.f[8];

      var data = (++cov_1vbh7zi5r5.s[23], []);
      var hasVal = (++cov_1vbh7zi5r5.s[24], 0);
      ++cov_1vbh7zi5r5.s[25];
      angular.forEach(self.financials, function (each) {
        ++cov_1vbh7zi5r5.f[9];
        ++cov_1vbh7zi5r5.s[26];

        if (each.name !== 'Change Orders') {
          ++cov_1vbh7zi5r5.b[4][0];
          ++cov_1vbh7zi5r5.s[27];

          data.push([each.name, each.estimated]);
          ++cov_1vbh7zi5r5.s[28];
          hasVal += each.estimated;
        } else {
          ++cov_1vbh7zi5r5.b[4][1];
        }
      });
      ++cov_1vbh7zi5r5.s[29];
      if (hasVal > 0) {
        ++cov_1vbh7zi5r5.b[5][0];
        ++cov_1vbh7zi5r5.s[30];

        self.showChart = true;
      } else {
        ++cov_1vbh7zi5r5.b[5][1];
      }
      ++cov_1vbh7zi5r5.s[31];
      self.chart.load({
        columns: data
      });
      // $window.dispatchEvent(new Event('resize'));
    }

    function loadNumbers() {
      ++cov_1vbh7zi5r5.f[10];
      ++cov_1vbh7zi5r5.s[32];

      Numbers.get(self.projectId).$loaded(function (data) {
        ++cov_1vbh7zi5r5.f[11];
        ++cov_1vbh7zi5r5.s[33];

        self.financials = data;
        ++cov_1vbh7zi5r5.s[34];
        numberCalcs();
        ++cov_1vbh7zi5r5.s[35];
        updateCharts();
      });
    }

    ++cov_1vbh7zi5r5.s[36];
    self.onHover = function (object) {
      ++cov_1vbh7zi5r5.f[12];
      ++cov_1vbh7zi5r5.s[37];

      if (self.graphData.name !== object.name) {
        ++cov_1vbh7zi5r5.b[6][0];
        ++cov_1vbh7zi5r5.s[38];

        self.chart.focus(object.name);
        ++cov_1vbh7zi5r5.s[39];
        self.graphData = object;
      } else {
        ++cov_1vbh7zi5r5.b[6][1];
      }
    };

    function setDefaults() {
      ++cov_1vbh7zi5r5.f[13];
      ++cov_1vbh7zi5r5.s[40];

      Numbers.create(self.projectId).then(function () {
        ++cov_1vbh7zi5r5.f[14];
        ++cov_1vbh7zi5r5.s[41];

        loadNumbers();
      });
    }

    function numberCalcs() {
      ++cov_1vbh7zi5r5.f[15];
      ++cov_1vbh7zi5r5.s[42];

      self.sum = 0;
      ++cov_1vbh7zi5r5.s[43];
      self.cost = 0;
      ++cov_1vbh7zi5r5.s[44];
      self.est = 0;
      ++cov_1vbh7zi5r5.s[45];
      angular.forEach(self.financials, function (num) {
        ++cov_1vbh7zi5r5.f[16];
        ++cov_1vbh7zi5r5.s[46];

        self.sum += num.perComplete;
        ++cov_1vbh7zi5r5.s[47];
        if (num.cost) {
          ++cov_1vbh7zi5r5.b[7][0];
          ++cov_1vbh7zi5r5.s[48];

          self.cost += num.cost;
        } else {
          ++cov_1vbh7zi5r5.b[7][1];
        }
        ++cov_1vbh7zi5r5.s[49];
        if (num.estimated) {
          ++cov_1vbh7zi5r5.b[8][0];
          ++cov_1vbh7zi5r5.s[50];

          self.est += num.estimated;
        } else {
          ++cov_1vbh7zi5r5.b[8][1];
        }
      });
      ++cov_1vbh7zi5r5.s[51];
      self.sum /= 10;
    }
  }

  ++cov_1vbh7zi5r5.s[52];
  angular.module('app').component('projectNumbers', projectNumbers);
})();