'use strict';

var cov_1b9738w99k = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/service/helpers/ticket-count.component.js',
      hash = '81dba30dd26ab81434ec21ee8b02b8886a259c8f',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/service/helpers/ticket-count.component.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 40,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 22
        },
        end: {
          line: 9,
          column: 3
        }
      },
      '2': {
        start: {
          line: 11,
          column: 2
        },
        end: {
          line: 11,
          column: 41
        }
      },
      '3': {
        start: {
          line: 14,
          column: 17
        },
        end: {
          line: 14,
          column: 21
        }
      },
      '4': {
        start: {
          line: 15,
          column: 4
        },
        end: {
          line: 15,
          column: 25
        }
      },
      '5': {
        start: {
          line: 17,
          column: 4
        },
        end: {
          line: 28,
          column: 6
        }
      },
      '6': {
        start: {
          line: 18,
          column: 6
        },
        end: {
          line: 27,
          column: 7
        }
      },
      '7': {
        start: {
          line: 19,
          column: 8
        },
        end: {
          line: 26,
          column: 11
        }
      },
      '8': {
        start: {
          line: 20,
          column: 10
        },
        end: {
          line: 20,
          column: 33
        }
      },
      '9': {
        start: {
          line: 21,
          column: 10
        },
        end: {
          line: 25,
          column: 13
        }
      },
      '10': {
        start: {
          line: 22,
          column: 12
        },
        end: {
          line: 24,
          column: 13
        }
      },
      '11': {
        start: {
          line: 23,
          column: 14
        },
        end: {
          line: 23,
          column: 33
        }
      },
      '12': {
        start: {
          line: 30,
          column: 4
        },
        end: {
          line: 34,
          column: 6
        }
      },
      '13': {
        start: {
          line: 31,
          column: 6
        },
        end: {
          line: 33,
          column: 7
        }
      },
      '14': {
        start: {
          line: 32,
          column: 8
        },
        end: {
          line: 32,
          column: 32
        }
      },
      '15': {
        start: {
          line: 37,
          column: 2
        },
        end: {
          line: 39,
          column: 43
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 40,
            column: 1
          }
        }
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 13,
            column: 11
          },
          end: {
            line: 13,
            column: 21
          }
        },
        loc: {
          start: {
            line: 13,
            column: 37
          },
          end: {
            line: 35,
            column: 3
          }
        }
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 17,
            column: 22
          },
          end: {
            line: 17,
            column: 23
          }
        },
        loc: {
          start: {
            line: 17,
            column: 28
          },
          end: {
            line: 28,
            column: 5
          }
        }
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 19,
            column: 62
          },
          end: {
            line: 19,
            column: 63
          }
        },
        loc: {
          start: {
            line: 19,
            column: 73
          },
          end: {
            line: 26,
            column: 9
          }
        }
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 21,
            column: 26
          },
          end: {
            line: 21,
            column: 27
          }
        },
        loc: {
          start: {
            line: 21,
            column: 36
          },
          end: {
            line: 25,
            column: 11
          }
        }
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 30,
            column: 22
          },
          end: {
            line: 30,
            column: 23
          }
        },
        loc: {
          start: {
            line: 30,
            column: 28
          },
          end: {
            line: 34,
            column: 5
          }
        }
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 18,
            column: 6
          },
          end: {
            line: 27,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 18,
            column: 6
          },
          end: {
            line: 27,
            column: 7
          }
        }, {
          start: {
            line: 18,
            column: 6
          },
          end: {
            line: 27,
            column: 7
          }
        }]
      },
      '1': {
        loc: {
          start: {
            line: 22,
            column: 12
          },
          end: {
            line: 24,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 22,
            column: 12
          },
          end: {
            line: 24,
            column: 13
          }
        }, {
          start: {
            line: 22,
            column: 12
          },
          end: {
            line: 24,
            column: 13
          }
        }]
      },
      '2': {
        loc: {
          start: {
            line: 31,
            column: 6
          },
          end: {
            line: 33,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 31,
            column: 6
          },
          end: {
            line: 33,
            column: 7
          }
        }, {
          start: {
            line: 31,
            column: 6
          },
          end: {
            line: 33,
            column: 7
          }
        }]
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_1b9738w99k.s[0];
(function () {
  ++cov_1b9738w99k.f[0];

  var ticketCount = (++cov_1b9738w99k.s[1], {
    bindings: {
      customerId: '<'
    },
    template: '<span uib-tooltip="Open / Total">{{$ctrl.openTickets}}/{{$ctrl.tickets.length}}</span>',
    controller: controller
  });

  ++cov_1b9738w99k.s[2];
  controller.$inject = ['TicketService'];

  function controller(TicketService) {
    ++cov_1b9738w99k.f[1];

    var self = (++cov_1b9738w99k.s[3], this);
    ++cov_1b9738w99k.s[4];
    self.openTickets = 0;

    ++cov_1b9738w99k.s[5];
    self.$onChanges = function () {
      ++cov_1b9738w99k.f[2];
      ++cov_1b9738w99k.s[6];

      if (self.customerId) {
        ++cov_1b9738w99k.b[0][0];
        ++cov_1b9738w99k.s[7];

        TicketService.$getByCustomer(self.customerId).$loaded(function (tickets) {
          ++cov_1b9738w99k.f[3];
          ++cov_1b9738w99k.s[8];

          self.tickets = tickets;
          ++cov_1b9738w99k.s[9];
          tickets.forEach(function (ticket) {
            ++cov_1b9738w99k.f[4];
            ++cov_1b9738w99k.s[10];

            if (ticket.open === true) {
              ++cov_1b9738w99k.b[1][0];
              ++cov_1b9738w99k.s[11];

              self.openTickets++;
            } else {
              ++cov_1b9738w99k.b[1][1];
            }
          });
        });
      } else {
        ++cov_1b9738w99k.b[0][1];
      }
    };

    ++cov_1b9738w99k.s[12];
    self.$onDestroy = function () {
      ++cov_1b9738w99k.f[5];
      ++cov_1b9738w99k.s[13];

      if (self.tickets) {
        ++cov_1b9738w99k.b[2][0];
        ++cov_1b9738w99k.s[14];

        self.tickets.$destroy();
      } else {
        ++cov_1b9738w99k.b[2][1];
      }
    };
  }

  ++cov_1b9738w99k.s[15];
  angular.module('dc-service').component('ticketCount', ticketCount);
})();