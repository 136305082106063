'use strict';

var cov_1mnxeb4jvg = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/common/customer/sites/helpers/site-address.component.js',
      hash = '2be7731c64a6b7ab02d3e7394df84b0283bcb2c2',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/common/customer/sites/helpers/site-address.component.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 32,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 22
        },
        end: {
          line: 9,
          column: 3
        }
      },
      '2': {
        start: {
          line: 11,
          column: 2
        },
        end: {
          line: 11,
          column: 51
        }
      },
      '3': {
        start: {
          line: 14,
          column: 17
        },
        end: {
          line: 14,
          column: 21
        }
      },
      '4': {
        start: {
          line: 15,
          column: 4
        },
        end: {
          line: 15,
          column: 19
        }
      },
      '5': {
        start: {
          line: 18,
          column: 4
        },
        end: {
          line: 26,
          column: 6
        }
      },
      '6': {
        start: {
          line: 19,
          column: 6
        },
        end: {
          line: 25,
          column: 7
        }
      },
      '7': {
        start: {
          line: 20,
          column: 8
        },
        end: {
          line: 24,
          column: 13
        }
      },
      '8': {
        start: {
          line: 22,
          column: 12
        },
        end: {
          line: 22,
          column: 35
        }
      },
      '9': {
        start: {
          line: 23,
          column: 12
        },
        end: {
          line: 23,
          column: 27
        }
      },
      '10': {
        start: {
          line: 29,
          column: 2
        },
        end: {
          line: 31,
          column: 43
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 32,
            column: 1
          }
        }
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 13,
            column: 11
          },
          end: {
            line: 13,
            column: 21
          }
        },
        loc: {
          start: {
            line: 13,
            column: 42
          },
          end: {
            line: 27,
            column: 3
          }
        }
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 18,
            column: 19
          },
          end: {
            line: 18,
            column: 20
          }
        },
        loc: {
          start: {
            line: 18,
            column: 25
          },
          end: {
            line: 26,
            column: 5
          }
        }
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 21,
            column: 16
          },
          end: {
            line: 21,
            column: 17
          }
        },
        loc: {
          start: {
            line: 21,
            column: 24
          },
          end: {
            line: 24,
            column: 11
          }
        }
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 19,
            column: 6
          },
          end: {
            line: 25,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 19,
            column: 6
          },
          end: {
            line: 25,
            column: 7
          }
        }, {
          start: {
            line: 19,
            column: 6
          },
          end: {
            line: 25,
            column: 7
          }
        }]
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0
    },
    b: {
      '0': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_1mnxeb4jvg.s[0];
(function () {
  ++cov_1mnxeb4jvg.f[0];

  var siteAddress = (++cov_1mnxeb4jvg.s[1], {
    bindings: {
      uid: '<'
    },
    templateUrl: 'app/common/customer/sites/helpers/site-address.html',
    controller: controller
  });

  ++cov_1mnxeb4jvg.s[2];
  controller.$inject = ['$db', '$attrs', '$scope'];

  function controller($db, $attrs, scope) {
    ++cov_1mnxeb4jvg.f[1];

    var self = (++cov_1mnxeb4jvg.s[3], this);
    ++cov_1mnxeb4jvg.s[4];
    self.site = '';

    // $onChanges
    ++cov_1mnxeb4jvg.s[5];
    self.$onInit = function () {
      ++cov_1mnxeb4jvg.f[2];
      ++cov_1mnxeb4jvg.s[6];

      if (angular.isDefined(self.uid)) {
        ++cov_1mnxeb4jvg.b[0][0];
        ++cov_1mnxeb4jvg.s[7];

        $db.sites.child(self.uid).once('value').then(function (data) {
          ++cov_1mnxeb4jvg.f[3];
          ++cov_1mnxeb4jvg.s[8];

          self.site = data.val();
          ++cov_1mnxeb4jvg.s[9];
          scope.$apply();
        });
      } else {
        ++cov_1mnxeb4jvg.b[0][1];
      }
    };
  }

  ++cov_1mnxeb4jvg.s[10];
  angular.module('dc-customer').component('siteAddress', siteAddress);
})();