'use strict';

var cov_1qv8tpwczn = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/app.js',
      hash = '557519551500c0de891342c0d34c8553e047ecbe',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/app.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 58,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 14
        },
        end: {
          line: 10,
          column: 3
        }
      },
      '2': {
        start: {
          line: 12,
          column: 2
        },
        end: {
          line: 12,
          column: 92
        }
      },
      '3': {
        start: {
          line: 15,
          column: 17
        },
        end: {
          line: 15,
          column: 21
        }
      },
      '4': {
        start: {
          line: 17,
          column: 4
        },
        end: {
          line: 46,
          column: 6
        }
      },
      '5': {
        start: {
          line: 18,
          column: 6
        },
        end: {
          line: 18,
          column: 45
        }
      },
      '6': {
        start: {
          line: 19,
          column: 6
        },
        end: {
          line: 19,
          column: 44
        }
      },
      '7': {
        start: {
          line: 21,
          column: 6
        },
        end: {
          line: 36,
          column: 9
        }
      },
      '8': {
        start: {
          line: 22,
          column: 8
        },
        end: {
          line: 22,
          column: 32
        }
      },
      '9': {
        start: {
          line: 23,
          column: 8
        },
        end: {
          line: 35,
          column: 9
        }
      },
      '10': {
        start: {
          line: 24,
          column: 32
        },
        end: {
          line: 26,
          column: 12
        }
      },
      '11': {
        start: {
          line: 27,
          column: 10
        },
        end: {
          line: 34,
          column: 15
        }
      },
      '12': {
        start: {
          line: 29,
          column: 14
        },
        end: {
          line: 31,
          column: 15
        }
      },
      '13': {
        start: {
          line: 30,
          column: 16
        },
        end: {
          line: 30,
          column: 42
        }
      },
      '14': {
        start: {
          line: 38,
          column: 6
        },
        end: {
          line: 40,
          column: 9
        }
      },
      '15': {
        start: {
          line: 39,
          column: 8
        },
        end: {
          line: 39,
          column: 31
        }
      },
      '16': {
        start: {
          line: 42,
          column: 23
        },
        end: {
          line: 42,
          column: 52
        }
      },
      '17': {
        start: {
          line: 43,
          column: 6
        },
        end: {
          line: 45,
          column: 9
        }
      },
      '18': {
        start: {
          line: 44,
          column: 8
        },
        end: {
          line: 44,
          column: 33
        }
      },
      '19': {
        start: {
          line: 48,
          column: 4
        },
        end: {
          line: 52,
          column: 6
        }
      },
      '20': {
        start: {
          line: 49,
          column: 6
        },
        end: {
          line: 49,
          column: 31
        }
      },
      '21': {
        start: {
          line: 50,
          column: 6
        },
        end: {
          line: 50,
          column: 31
        }
      },
      '22': {
        start: {
          line: 51,
          column: 6
        },
        end: {
          line: 51,
          column: 20
        }
      },
      '23': {
        start: {
          line: 55,
          column: 2
        },
        end: {
          line: 57,
          column: 27
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 58,
            column: 1
          }
        }
      },
      '1': {
        name: 'appCtrl',
        decl: {
          start: {
            line: 14,
            column: 11
          },
          end: {
            line: 14,
            column: 18
          }
        },
        loc: {
          start: {
            line: 14,
            column: 78
          },
          end: {
            line: 53,
            column: 3
          }
        }
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 17,
            column: 19
          },
          end: {
            line: 17,
            column: 20
          }
        },
        loc: {
          start: {
            line: 17,
            column: 25
          },
          end: {
            line: 46,
            column: 5
          }
        }
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 21,
            column: 30
          },
          end: {
            line: 21,
            column: 31
          }
        },
        loc: {
          start: {
            line: 21,
            column: 37
          },
          end: {
            line: 36,
            column: 7
          }
        }
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 28,
            column: 18
          },
          end: {
            line: 28,
            column: 19
          }
        },
        loc: {
          start: {
            line: 28,
            column: 24
          },
          end: {
            line: 32,
            column: 13
          }
        }
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 32,
            column: 15
          },
          end: {
            line: 32,
            column: 16
          }
        },
        loc: {
          start: {
            line: 32,
            column: 21
          },
          end: {
            line: 34,
            column: 13
          }
        }
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 38,
            column: 32
          },
          end: {
            line: 38,
            column: 33
          }
        },
        loc: {
          start: {
            line: 38,
            column: 39
          },
          end: {
            line: 40,
            column: 7
          }
        }
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 43,
            column: 30
          },
          end: {
            line: 43,
            column: 31
          }
        },
        loc: {
          start: {
            line: 43,
            column: 42
          },
          end: {
            line: 45,
            column: 7
          }
        }
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 48,
            column: 22
          },
          end: {
            line: 48,
            column: 23
          }
        },
        loc: {
          start: {
            line: 48,
            column: 28
          },
          end: {
            line: 52,
            column: 5
          }
        }
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 23,
            column: 8
          },
          end: {
            line: 35,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 23,
            column: 8
          },
          end: {
            line: 35,
            column: 9
          }
        }, {
          start: {
            line: 23,
            column: 8
          },
          end: {
            line: 35,
            column: 9
          }
        }]
      },
      '1': {
        loc: {
          start: {
            line: 29,
            column: 14
          },
          end: {
            line: 31,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 29,
            column: 14
          },
          end: {
            line: 31,
            column: 15
          }
        }, {
          start: {
            line: 29,
            column: 14
          },
          end: {
            line: 31,
            column: 15
          }
        }]
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_1qv8tpwczn.s[0];
(function () {
  ++cov_1qv8tpwczn.f[0];

  var app = (++cov_1qv8tpwczn.s[1], {
    bindings: {
      User: '='
    },
    templateUrl: 'app/app.html',
    controller: appCtrl,
    controllerAs: '$app'
  });

  ++cov_1qv8tpwczn.s[2];
  appCtrl.$inject = ['Users', 'Projects', '$db', '$firebaseObject', '$uibModal', '$window'];

  function appCtrl(Users, Projects, $db, $firebaseObject, $uibModal, $window) {
    ++cov_1qv8tpwczn.f[1];

    var self = (++cov_1qv8tpwczn.s[3], this);

    ++cov_1qv8tpwczn.s[4];
    self.$onInit = function () {
      ++cov_1qv8tpwczn.f[2];
      ++cov_1qv8tpwczn.s[5];

      self.roles = Users.currentUser().roles;
      ++cov_1qv8tpwczn.s[6];
      self.projects = Projects.$getRecent();

      ++cov_1qv8tpwczn.s[7];
      $db.version.on('value', function (ver) {
        ++cov_1qv8tpwczn.f[3];
        ++cov_1qv8tpwczn.s[8];

        self.latest = ver.val();
        ++cov_1qv8tpwczn.s[9];
        if (self.latest > self.using) {
          ++cov_1qv8tpwczn.b[0][0];

          var modalInstance = (++cov_1qv8tpwczn.s[10], $uibModal.open({
            component: 'updateAppVersion'
          }));
          ++cov_1qv8tpwczn.s[11];
          modalInstance.result.then(function (ok) {
            ++cov_1qv8tpwczn.f[4];
            ++cov_1qv8tpwczn.s[12];

            if (ok === true) {
              ++cov_1qv8tpwczn.b[1][0];
              ++cov_1qv8tpwczn.s[13];

              $window.location.reload();
            } else {
              ++cov_1qv8tpwczn.b[1][1];
            }
          }, function () {
            // modal dismiss promise resolves here.

            ++cov_1qv8tpwczn.f[5];
          });
        } else {
          ++cov_1qv8tpwczn.b[0][1];
        }
      });

      ++cov_1qv8tpwczn.s[14];
      $db.version.once('value', function (ver) {
        ++cov_1qv8tpwczn.f[6];
        ++cov_1qv8tpwczn.s[15];

        self.using = ver.val();
      });

      var metadata = (++cov_1qv8tpwczn.s[16], $firebaseObject($db.metadata));
      ++cov_1qv8tpwczn.s[17];
      metadata.$loaded().then(function (metadata) {
        ++cov_1qv8tpwczn.f[7];
        ++cov_1qv8tpwczn.s[18];

        self.metadata = metadata;
      });
    };

    ++cov_1qv8tpwczn.s[19];
    self.$onDestroy = function () {
      ++cov_1qv8tpwczn.f[8];
      ++cov_1qv8tpwczn.s[20];

      self.projects.$destroy();
      ++cov_1qv8tpwczn.s[21];
      self.metadata.$destroy();
      ++cov_1qv8tpwczn.s[22];
      Users.reset();
    };
  }

  ++cov_1qv8tpwczn.s[23];
  angular.module('app').component('app', app);
})();