'use strict';

var cov_2fvw6c1qzi = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/products/product.service.js',
      hash = '67d1d0bf40351b49a4b16cb80b6fdc99e97d79d3',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/products/product.service.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 18,
          column: 5
        }
      },
      '1': {
        start: {
          line: 5,
          column: 20
        },
        end: {
          line: 7,
          column: 5
        }
      },
      '2': {
        start: {
          line: 9,
          column: 6
        },
        end: {
          line: 9,
          column: 32
        }
      },
      '3': {
        start: {
          line: 12,
          column: 4
        },
        end: {
          line: 12,
          column: 19
        }
      },
      '4': {
        start: {
          line: 15,
          column: 2
        },
        end: {
          line: 17,
          column: 56
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 18,
            column: 1
          }
        }
      },
      '1': {
        name: 'ProductService',
        decl: {
          start: {
            line: 4,
            column: 11
          },
          end: {
            line: 4,
            column: 25
          }
        },
        loc: {
          start: {
            line: 4,
            column: 31
          },
          end: {
            line: 13,
            column: 3
          }
        }
      },
      '2': {
        name: 'all',
        decl: {
          start: {
            line: 8,
            column: 13
          },
          end: {
            line: 8,
            column: 16
          }
        },
        loc: {
          start: {
            line: 8,
            column: 19
          },
          end: {
            line: 10,
            column: 5
          }
        }
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_2fvw6c1qzi.s[0];
(function () {
  ++cov_2fvw6c1qzi.f[0];

  // defines function Tickets
  function ProductService($db) {
    ++cov_2fvw6c1qzi.f[1];

    var service = (++cov_2fvw6c1qzi.s[1], {
      all: all
    });
    function all() {
      ++cov_2fvw6c1qzi.f[2];
      ++cov_2fvw6c1qzi.s[2];

      return $db.products.get();
    }
    // console.log($db);
    ++cov_2fvw6c1qzi.s[3];
    return service;
  }

  ++cov_2fvw6c1qzi.s[4];
  angular.module('dc-products').factory('ProductService', ['$db', ProductService]);
})();