'use strict';

var cov_28cq8fp5ni = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/users/create-user/create-user.js',
      hash = '117f8af3141ecd2b49c3090b4e52ac31bf1b4a66',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/users/create-user/create-user.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 71,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 21
        },
        end: {
          line: 10,
          column: 3
        }
      },
      '2': {
        start: {
          line: 12,
          column: 2
        },
        end: {
          line: 12,
          column: 81
        }
      },
      '3': {
        start: {
          line: 15,
          column: 17
        },
        end: {
          line: 15,
          column: 21
        }
      },
      '4': {
        start: {
          line: 17,
          column: 4
        },
        end: {
          line: 29,
          column: 6
        }
      },
      '5': {
        start: {
          line: 31,
          column: 4
        },
        end: {
          line: 38,
          column: 6
        }
      },
      '6': {
        start: {
          line: 32,
          column: 19
        },
        end: {
          line: 35,
          column: 7
        }
      },
      '7': {
        start: {
          line: 36,
          column: 6
        },
        end: {
          line: 37,
          column: 30
        }
      },
      '8': {
        start: {
          line: 42,
          column: 23
        },
        end: {
          line: 42,
          column: 36
        }
      },
      '9': {
        start: {
          line: 43,
          column: 18
        },
        end: {
          line: 43,
          column: 30
        }
      },
      '10': {
        start: {
          line: 44,
          column: 6
        },
        end: {
          line: 60,
          column: 11
        }
      },
      '11': {
        start: {
          line: 46,
          column: 10
        },
        end: {
          line: 46,
          column: 40
        }
      },
      '12': {
        start: {
          line: 47,
          column: 10
        },
        end: {
          line: 47,
          column: 46
        }
      },
      '13': {
        start: {
          line: 48,
          column: 10
        },
        end: {
          line: 48,
          column: 42
        }
      },
      '14': {
        start: {
          line: 49,
          column: 10
        },
        end: {
          line: 49,
          column: 106
        }
      },
      '15': {
        start: {
          line: 50,
          column: 10
        },
        end: {
          line: 50,
          column: 76
        }
      },
      '16': {
        start: {
          line: 51,
          column: 10
        },
        end: {
          line: 51,
          column: 33
        }
      },
      '17': {
        start: {
          line: 54,
          column: 10
        },
        end: {
          line: 54,
          column: 34
        }
      },
      '18': {
        start: {
          line: 57,
          column: 10
        },
        end: {
          line: 57,
          column: 26
        }
      },
      '19': {
        start: {
          line: 59,
          column: 10
        },
        end: {
          line: 59,
          column: 26
        }
      },
      '20': {
        start: {
          line: 64,
          column: 6
        },
        end: {
          line: 64,
          column: 20
        }
      },
      '21': {
        start: {
          line: 68,
          column: 2
        },
        end: {
          line: 70,
          column: 41
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 71,
            column: 1
          }
        }
      },
      '1': {
        name: 'createUserCtrl',
        decl: {
          start: {
            line: 14,
            column: 11
          },
          end: {
            line: 14,
            column: 25
          }
        },
        loc: {
          start: {
            line: 14,
            column: 67
          },
          end: {
            line: 66,
            column: 3
          }
        }
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 31,
            column: 22
          },
          end: {
            line: 31,
            column: 23
          }
        },
        loc: {
          start: {
            line: 31,
            column: 30
          },
          end: {
            line: 38,
            column: 5
          }
        }
      },
      '3': {
        name: 'success',
        decl: {
          start: {
            line: 41,
            column: 13
          },
          end: {
            line: 41,
            column: 20
          }
        },
        loc: {
          start: {
            line: 41,
            column: 31
          },
          end: {
            line: 61,
            column: 5
          }
        }
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 45,
            column: 17
          },
          end: {
            line: 45,
            column: 18
          }
        },
        loc: {
          start: {
            line: 45,
            column: 28
          },
          end: {
            line: 52,
            column: 9
          }
        }
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 53,
            column: 14
          },
          end: {
            line: 53,
            column: 15
          }
        },
        loc: {
          start: {
            line: 53,
            column: 20
          },
          end: {
            line: 55,
            column: 9
          }
        }
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 55,
            column: 11
          },
          end: {
            line: 55,
            column: 12
          }
        },
        loc: {
          start: {
            line: 55,
            column: 20
          },
          end: {
            line: 58,
            column: 9
          }
        }
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 58,
            column: 17
          },
          end: {
            line: 58,
            column: 18
          }
        },
        loc: {
          start: {
            line: 58,
            column: 26
          },
          end: {
            line: 60,
            column: 9
          }
        }
      },
      '8': {
        name: 'error',
        decl: {
          start: {
            line: 63,
            column: 13
          },
          end: {
            line: 63,
            column: 18
          }
        },
        loc: {
          start: {
            line: 63,
            column: 24
          },
          end: {
            line: 65,
            column: 5
          }
        }
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_28cq8fp5ni.s[0];
(function () {
  ++cov_28cq8fp5ni.f[0];

  var createUser = (++cov_28cq8fp5ni.s[1], {
    bindings: {
      currentAuth: '=',
      User: '='
    },
    templateUrl: 'app/users/create-user/create-user.html',
    controller: createUserCtrl
  });

  ++cov_28cq8fp5ni.s[2];
  createUserCtrl.$inject = ['Users', 'Auth', '$http', '$state', '$log', 'Utils'];

  function createUserCtrl(Users, Auth, $http, $state, $log, Utils) {
    ++cov_28cq8fp5ni.f[1];

    var self = (++cov_28cq8fp5ni.s[3], this);

    ++cov_28cq8fp5ni.s[4];
    self.user = {
      password: '',
      profile: {
        name: {
          first: '',
          last: ''
        },
        email: ''
      },
      roles: {
        admin: false
      }
    };

    ++cov_28cq8fp5ni.s[5];
    self.createUser = function (user) {
      ++cov_28cq8fp5ni.f[2];

      var body = (++cov_28cq8fp5ni.s[6], {
        email: user.profile.email,
        password: user.password
      });
      ++cov_28cq8fp5ni.s[7];
      $http.post('api/add-user', body).then(success, error);
    };

    // success callback from firebase function $http
    function success(response) {
      ++cov_28cq8fp5ni.f[3];

      var userData = (++cov_28cq8fp5ni.s[8], response.data);
      var uid = (++cov_28cq8fp5ni.s[9], userData.uid);
      ++cov_28cq8fp5ni.s[10];
      Users.getProfile(uid).$loaded(function (userRef) {
        ++cov_28cq8fp5ni.f[4];
        ++cov_28cq8fp5ni.s[11];

        userRef.created = Utils.now();
        ++cov_28cq8fp5ni.s[12];
        userRef.profile = self.user.profile;
        ++cov_28cq8fp5ni.s[13];
        userRef.roles = self.user.roles;
        ++cov_28cq8fp5ni.s[14];
        userRef.profile.username = self.user.profile.name.first.charAt(0) + self.user.profile.name.last;
        ++cov_28cq8fp5ni.s[15];
        userRef.profile.username = userRef.profile.username.toLowerCase();
        ++cov_28cq8fp5ni.s[16];
        return userRef.$save();
      }).then(function () {
        ++cov_28cq8fp5ni.f[5];
        ++cov_28cq8fp5ni.s[17];

        $state.go('users.list');
      }, function (error) {
        ++cov_28cq8fp5ni.f[6];
        ++cov_28cq8fp5ni.s[18];

        // handle error
        $log.log(error);
      }).catch(function (error) {
        ++cov_28cq8fp5ni.f[7];
        ++cov_28cq8fp5ni.s[19];

        $log.log(error);
      });
    }
    // error callback from firebase function $http
    function error(err) {
      ++cov_28cq8fp5ni.f[8];
      ++cov_28cq8fp5ni.s[20];

      $log.log(err);
    }
  }

  ++cov_28cq8fp5ni.s[21];
  angular.module('app').component('createUser', createUser);
})();