'use strict';

var cov_29ym30cdt4 = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/service/helpers/priority-select.component.js',
      hash = '7ff96766eeab86e3936860c3a7189cd9741842f8',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/service/helpers/priority-select.component.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 52,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 20
        },
        end: {
          line: 16,
          column: 3
        }
      },
      '2': {
        start: {
          line: 18,
          column: 2
        },
        end: {
          line: 18,
          column: 26
        }
      },
      '3': {
        start: {
          line: 21,
          column: 17
        },
        end: {
          line: 21,
          column: 21
        }
      },
      '4': {
        start: {
          line: 23,
          column: 4
        },
        end: {
          line: 46,
          column: 6
        }
      },
      '5': {
        start: {
          line: 24,
          column: 6
        },
        end: {
          line: 24,
          column: 30
        }
      },
      '6': {
        start: {
          line: 25,
          column: 6
        },
        end: {
          line: 45,
          column: 9
        }
      },
      '7': {
        start: {
          line: 49,
          column: 2
        },
        end: {
          line: 51,
          column: 44
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 52,
            column: 1
          }
        }
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 20,
            column: 11
          },
          end: {
            line: 20,
            column: 21
          }
        },
        loc: {
          start: {
            line: 20,
            column: 24
          },
          end: {
            line: 47,
            column: 3
          }
        }
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 23,
            column: 19
          },
          end: {
            line: 23,
            column: 20
          }
        },
        loc: {
          start: {
            line: 23,
            column: 25
          },
          end: {
            line: 46,
            column: 5
          }
        }
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_29ym30cdt4.s[0];
(function () {
  ++cov_29ym30cdt4.f[0];

  var component = (++cov_29ym30cdt4.s[1], {
    bindings: {
      ngModel: '=',
      required: '='
    },
    template: '\n    <select name="" id="" class="form-control" ng-model="$ctrl.ngModel">\n      <option ng-repeat="priority in $ctrl.priorities" ng-value="priority.value" ng-selected="$ctrl.ngModel == priority.value">\n      {{priority.name}} <i class="ion {{$ctrl.icon}}" ng-class="priority.class" ng-repeat="icon in priority.icons"></i>\n      </option>\n    </select>  \n    ',
    controller: controller
  });

  ++cov_29ym30cdt4.s[2];
  controller.$inject = [];

  function controller() {
    ++cov_29ym30cdt4.f[1];

    var self = (++cov_29ym30cdt4.s[3], this);

    ++cov_29ym30cdt4.s[4];
    self.$onInit = function () {
      ++cov_29ym30cdt4.f[2];
      ++cov_29ym30cdt4.s[5];

      self.icon = 'ion-alert';
      ++cov_29ym30cdt4.s[6];
      self.priorities = [{
        value: 0,
        icons: 0,
        class: '',
        name: 'Low'
      }, {
        value: 1,
        icons: 1,
        class: 'text-success',
        name: 'Normal'
      }, {
        value: 5,
        icons: 2,
        class: 'text-warning',
        name: 'High'
      }, {
        value: 10,
        icons: 3,
        class: 'text-danger',
        name: 'Urgent'
      }];
    };
  }

  ++cov_29ym30cdt4.s[7];
  angular.module('dc-service').component('prioritySelect', component);
})();