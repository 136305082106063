'use strict';

var cov_10kbkx3ln6 = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/index.js',
      hash = '50c542a17c8fb2bca954be2b1bd4be1126e23862',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/index.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 24,
          column: 15
        }
      }
    },
    fnMap: {},
    branchMap: {},
    s: {
      '0': 0
    },
    f: {},
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_10kbkx3ln6.s[0];
angular.module('app', ['ui.router', 'firebase', 'dc-common', 'dc-user', 'dc-service', 'dc-products', 'ngAnimate', 'ngTouch', 'ngSanitize', 'ngMessages', 'ngPassword', 'ng-currency', 'angularMoment', 'angular-sortable-view', 'ngResponsiveBreakpoints', 'thatisuday.dropzone']).constant('appConfig', {
  clientID: 'divcon'
}).constant('Modernizr', Modernizr);