// 'use strict';
// (function () {
//   const userImage = {
//     bindings: {
//       uid: '<',
//       imgclass: '@'
//     },
//     template: `
//       <img ng-src="{{$ctrl.image}}" alt="{{$ctrl.alt}}" class="{{$ctrl.imgclass}}"/>
//     `,
//     controller
//   };

//   controller.$inject = ['$db', '$scope', '$attrs'];

//   function controller($db, scope, $attrs) {
//     const self = this;
//     self.$onChanges = (prev, next) => {
//       if (next !== prev && angular.isDefined(self.uid)) {
//         $db.users.child(self.uid + '/profile/image').once('value')
//           .then(data => {
//             const image = data.val();
//             self.image = image || 'images/default.png';
//             scope.$apply();
//           });
//       }
//     };
//     // $postLink
//     self.$postLink = () => {
//       if ('userNameLink' in $attrs) {
//         self.showLink = true;
//       }
//     };
//   }

//   angular
//     .module('app')
//     .component('userImage', userImage);
// })();
"use strict";

var cov_ozo1vxv0y = function () {
  var path = "/Users/ryanluton/Developer/divcon/divcon-tools/src/app/components/user-helpers/user-image.js",
      hash = "0cea9df605687bcb6a6533d685bf2c01477acbae",
      global = new Function('return this')(),
      gcv = "__coverage__",
      coverageData = {
    path: "/Users/ryanluton/Developer/divcon/divcon-tools/src/app/components/user-helpers/user-image.js",
    statementMap: {},
    fnMap: {},
    branchMap: {},
    s: {},
    f: {},
    b: {},
    _coverageSchema: "332fd63041d2c1bcb487cc26dd0d5f7d97098a6c"
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();