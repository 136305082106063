'use strict';

var cov_1x50x31tsu = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/projects/edit-project/edit-project.js',
      hash = 'd7854bc1c8f81cc25ee5310bad92fbef06937a14',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/projects/edit-project/edit-project.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 62,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 22
        },
        end: {
          line: 6,
          column: 3
        }
      },
      '2': {
        start: {
          line: 8,
          column: 2
        },
        end: {
          line: 8,
          column: 75
        }
      },
      '3': {
        start: {
          line: 11,
          column: 17
        },
        end: {
          line: 11,
          column: 21
        }
      },
      '4': {
        start: {
          line: 12,
          column: 4
        },
        end: {
          line: 22,
          column: 5
        }
      },
      '5': {
        start: {
          line: 13,
          column: 6
        },
        end: {
          line: 21,
          column: 11
        }
      },
      '6': {
        start: {
          line: 16,
          column: 10
        },
        end: {
          line: 16,
          column: 37
        }
      },
      '7': {
        start: {
          line: 17,
          column: 10
        },
        end: {
          line: 20,
          column: 15
        }
      },
      '8': {
        start: {
          line: 19,
          column: 14
        },
        end: {
          line: 19,
          column: 37
        }
      },
      '9': {
        start: {
          line: 24,
          column: 4
        },
        end: {
          line: 34,
          column: 6
        }
      },
      '10': {
        start: {
          line: 25,
          column: 6
        },
        end: {
          line: 33,
          column: 11
        }
      },
      '11': {
        start: {
          line: 27,
          column: 10
        },
        end: {
          line: 27,
          column: 38
        }
      },
      '12': {
        start: {
          line: 30,
          column: 10
        },
        end: {
          line: 32,
          column: 13
        }
      },
      '13': {
        start: {
          line: 36,
          column: 4
        },
        end: {
          line: 51,
          column: 6
        }
      },
      '14': {
        start: {
          line: 37,
          column: 28
        },
        end: {
          line: 39,
          column: 8
        }
      },
      '15': {
        start: {
          line: 40,
          column: 6
        },
        end: {
          line: 50,
          column: 11
        }
      },
      '16': {
        start: {
          line: 42,
          column: 10
        },
        end: {
          line: 47,
          column: 11
        }
      },
      '17': {
        start: {
          line: 43,
          column: 12
        },
        end: {
          line: 46,
          column: 17
        }
      },
      '18': {
        start: {
          line: 45,
          column: 16
        },
        end: {
          line: 45,
          column: 47
        }
      },
      '19': {
        start: {
          line: 53,
          column: 4
        },
        end: {
          line: 56,
          column: 6
        }
      },
      '20': {
        start: {
          line: 54,
          column: 6
        },
        end: {
          line: 54,
          column: 30
        }
      },
      '21': {
        start: {
          line: 55,
          column: 6
        },
        end: {
          line: 55,
          column: 30
        }
      },
      '22': {
        start: {
          line: 59,
          column: 2
        },
        end: {
          line: 61,
          column: 43
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 62,
            column: 1
          }
        }
      },
      '1': {
        name: 'editProjectCtrl',
        decl: {
          start: {
            line: 10,
            column: 11
          },
          end: {
            line: 10,
            column: 26
          }
        },
        loc: {
          start: {
            line: 10,
            column: 65
          },
          end: {
            line: 57,
            column: 3
          }
        }
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 15,
            column: 14
          },
          end: {
            line: 15,
            column: 15
          }
        },
        loc: {
          start: {
            line: 15,
            column: 29
          },
          end: {
            line: 21,
            column: 9
          }
        }
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 18,
            column: 21
          },
          end: {
            line: 18,
            column: 22
          }
        },
        loc: {
          start: {
            line: 18,
            column: 32
          },
          end: {
            line: 20,
            column: 13
          }
        }
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 24,
            column: 16
          },
          end: {
            line: 24,
            column: 17
          }
        },
        loc: {
          start: {
            line: 24,
            column: 22
          },
          end: {
            line: 34,
            column: 5
          }
        }
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 26,
            column: 14
          },
          end: {
            line: 26,
            column: 15
          }
        },
        loc: {
          start: {
            line: 26,
            column: 20
          },
          end: {
            line: 28,
            column: 9
          }
        }
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 29,
            column: 14
          },
          end: {
            line: 29,
            column: 15
          }
        },
        loc: {
          start: {
            line: 29,
            column: 20
          },
          end: {
            line: 33,
            column: 9
          }
        }
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 36,
            column: 18
          },
          end: {
            line: 36,
            column: 19
          }
        },
        loc: {
          start: {
            line: 36,
            column: 24
          },
          end: {
            line: 51,
            column: 5
          }
        }
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 41,
            column: 14
          },
          end: {
            line: 41,
            column: 15
          }
        },
        loc: {
          start: {
            line: 41,
            column: 20
          },
          end: {
            line: 48,
            column: 9
          }
        }
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 44,
            column: 20
          },
          end: {
            line: 44,
            column: 21
          }
        },
        loc: {
          start: {
            line: 44,
            column: 26
          },
          end: {
            line: 46,
            column: 15
          }
        }
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 48,
            column: 11
          },
          end: {
            line: 48,
            column: 12
          }
        },
        loc: {
          start: {
            line: 48,
            column: 17
          },
          end: {
            line: 50,
            column: 9
          }
        }
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 53,
            column: 22
          },
          end: {
            line: 53,
            column: 23
          }
        },
        loc: {
          start: {
            line: 53,
            column: 28
          },
          end: {
            line: 56,
            column: 5
          }
        }
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 12,
            column: 4
          },
          end: {
            line: 22,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 12,
            column: 4
          },
          end: {
            line: 22,
            column: 5
          }
        }, {
          start: {
            line: 12,
            column: 4
          },
          end: {
            line: 22,
            column: 5
          }
        }]
      },
      '1': {
        loc: {
          start: {
            line: 42,
            column: 10
          },
          end: {
            line: 47,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 42,
            column: 10
          },
          end: {
            line: 47,
            column: 11
          }
        }, {
          start: {
            line: 42,
            column: 10
          },
          end: {
            line: 47,
            column: 11
          }
        }]
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_1x50x31tsu.s[0];
(function () {
  ++cov_1x50x31tsu.f[0];

  var editProject = (++cov_1x50x31tsu.s[1], {
    templateUrl: 'app/projects/edit-project/edit-project.html',
    controller: editProjectCtrl
  });

  ++cov_1x50x31tsu.s[2];
  editProjectCtrl.$inject = ['Projects', 'Numbers', '$state', '$uibModal'];

  function editProjectCtrl(Projects, Numbers, $state, $uibModal) {
    ++cov_1x50x31tsu.f[1];

    var self = (++cov_1x50x31tsu.s[3], this);
    ++cov_1x50x31tsu.s[4];
    if ($state.params.projectID) {
      ++cov_1x50x31tsu.b[0][0];
      ++cov_1x50x31tsu.s[5];

      Projects.getProjectByNumber($state.params.projectID).then(function (projectData) {
        ++cov_1x50x31tsu.f[2];
        ++cov_1x50x31tsu.s[6];

        self.project = projectData;
        ++cov_1x50x31tsu.s[7];
        Numbers.get(self.project.$id).$loaded(function (numbers) {
          ++cov_1x50x31tsu.f[3];
          ++cov_1x50x31tsu.s[8];

          self.numbers = numbers;
        });
      });
    } else {
      ++cov_1x50x31tsu.b[0][1];
    }

    ++cov_1x50x31tsu.s[9];
    self.save = function () {
      ++cov_1x50x31tsu.f[4];
      ++cov_1x50x31tsu.s[10];

      self.numbers.$save().then(function () {
        ++cov_1x50x31tsu.f[5];
        ++cov_1x50x31tsu.s[11];

        return self.project.$save();
      }).then(function () {
        ++cov_1x50x31tsu.f[6];
        ++cov_1x50x31tsu.s[12];

        $state.go('app.project', {
          projectID: $state.params.projectID
        });
      });
    };

    ++cov_1x50x31tsu.s[13];
    self.delete = function () {
      ++cov_1x50x31tsu.f[7];

      var modalInstance = (++cov_1x50x31tsu.s[14], $uibModal.open({
        component: 'confirmModal'
      }));
      ++cov_1x50x31tsu.s[15];
      modalInstance.result.then(function (ok) {
        ++cov_1x50x31tsu.f[8];
        ++cov_1x50x31tsu.s[16];

        if (ok === true) {
          ++cov_1x50x31tsu.b[1][0];
          ++cov_1x50x31tsu.s[17];

          Projects.remove(self.project.$id).then(function () {
            ++cov_1x50x31tsu.f[9];
            ++cov_1x50x31tsu.s[18];

            $state.go('app.projects.list');
          });
        } else {
          ++cov_1x50x31tsu.b[1][1];
        }
      }, function () {
        // modal dismiss promise resolves here.

        ++cov_1x50x31tsu.f[10];
      });
    };

    ++cov_1x50x31tsu.s[19];
    self.$onDestroy = function () {
      ++cov_1x50x31tsu.f[11];
      ++cov_1x50x31tsu.s[20];

      self.project.$destroy();
      ++cov_1x50x31tsu.s[21];
      self.numbers.$destroy();
    };
  }

  ++cov_1x50x31tsu.s[22];
  angular.module('app').component('editProject', editProject);
})();