'use strict';

var cov_1he8ozxgz9 = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/project/project-tickets/project-tickets.component.js',
      hash = 'd3dec08a5037e40a15e7a9f574a67bf50305232b',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/project/project-tickets/project-tickets.component.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 33,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 25
        },
        end: {
          line: 9,
          column: 3
        }
      },
      '2': {
        start: {
          line: 11,
          column: 2
        },
        end: {
          line: 11,
          column: 41
        }
      },
      '3': {
        start: {
          line: 14,
          column: 17
        },
        end: {
          line: 14,
          column: 21
        }
      },
      '4': {
        start: {
          line: 16,
          column: 4
        },
        end: {
          line: 21,
          column: 6
        }
      },
      '5': {
        start: {
          line: 17,
          column: 6
        },
        end: {
          line: 20,
          column: 11
        }
      },
      '6': {
        start: {
          line: 19,
          column: 10
        },
        end: {
          line: 19,
          column: 33
        }
      },
      '7': {
        start: {
          line: 23,
          column: 4
        },
        end: {
          line: 27,
          column: 6
        }
      },
      '8': {
        start: {
          line: 24,
          column: 6
        },
        end: {
          line: 26,
          column: 7
        }
      },
      '9': {
        start: {
          line: 25,
          column: 8
        },
        end: {
          line: 25,
          column: 32
        }
      },
      '10': {
        start: {
          line: 30,
          column: 2
        },
        end: {
          line: 32,
          column: 49
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 33,
            column: 1
          }
        }
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 13,
            column: 11
          },
          end: {
            line: 13,
            column: 21
          }
        },
        loc: {
          start: {
            line: 13,
            column: 37
          },
          end: {
            line: 28,
            column: 3
          }
        }
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 16,
            column: 19
          },
          end: {
            line: 16,
            column: 20
          }
        },
        loc: {
          start: {
            line: 16,
            column: 25
          },
          end: {
            line: 21,
            column: 5
          }
        }
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 18,
            column: 17
          },
          end: {
            line: 18,
            column: 18
          }
        },
        loc: {
          start: {
            line: 18,
            column: 28
          },
          end: {
            line: 20,
            column: 9
          }
        }
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 23,
            column: 22
          },
          end: {
            line: 23,
            column: 23
          }
        },
        loc: {
          start: {
            line: 23,
            column: 28
          },
          end: {
            line: 27,
            column: 5
          }
        }
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 24,
            column: 6
          },
          end: {
            line: 26,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 24,
            column: 6
          },
          end: {
            line: 26,
            column: 7
          }
        }, {
          start: {
            line: 24,
            column: 6
          },
          end: {
            line: 26,
            column: 7
          }
        }]
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0
    },
    b: {
      '0': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_1he8ozxgz9.s[0];
(function () {
  ++cov_1he8ozxgz9.f[0];

  var projectTickets = (++cov_1he8ozxgz9.s[1], {
    bindings: {
      projectId: '<'
    },
    templateUrl: 'app/project/project-tickets/project-tickets.html',
    controller: controller
  });

  ++cov_1he8ozxgz9.s[2];
  controller.$inject = ['TicketService'];

  function controller(TicketService) {
    ++cov_1he8ozxgz9.f[1];

    var self = (++cov_1he8ozxgz9.s[3], this);

    ++cov_1he8ozxgz9.s[4];
    self.$onInit = function () {
      ++cov_1he8ozxgz9.f[2];
      ++cov_1he8ozxgz9.s[5];

      TicketService.$getByProject(self.projectId).$loaded(function (tickets) {
        ++cov_1he8ozxgz9.f[3];
        ++cov_1he8ozxgz9.s[6];

        self.tickets = tickets;
      });
    };

    ++cov_1he8ozxgz9.s[7];
    self.$onDestroy = function () {
      ++cov_1he8ozxgz9.f[4];
      ++cov_1he8ozxgz9.s[8];

      if (self.tickets) {
        ++cov_1he8ozxgz9.b[0][0];
        ++cov_1he8ozxgz9.s[9];

        self.tickets.$destroy();
      } else {
        ++cov_1he8ozxgz9.b[0][1];
      }
    };
  }

  ++cov_1he8ozxgz9.s[10];
  angular.module('app').component('projectTickets', projectTickets);
})();