'use strict';

var cov_1lb8bnujqc = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/common/auth/auth.service.js',
      hash = '7d38509dc88b3dff889c07ab6db01c6a854e5a72',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/common/auth/auth.service.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 69,
          column: 5
        }
      },
      '1': {
        start: {
          line: 4,
          column: 17
        },
        end: {
          line: 4,
          column: 32
        }
      },
      '2': {
        start: {
          line: 5,
          column: 19
        },
        end: {
          line: 5,
          column: 23
        }
      },
      '3': {
        start: {
          line: 7,
          column: 20
        },
        end: {
          line: 14,
          column: 5
        }
      },
      '4': {
        start: {
          line: 16,
          column: 4
        },
        end: {
          line: 16,
          column: 19
        }
      },
      '5': {
        start: {
          line: 19,
          column: 6
        },
        end: {
          line: 19,
          column: 26
        }
      },
      '6': {
        start: {
          line: 20,
          column: 23
        },
        end: {
          line: 24,
          column: 7
        }
      },
      '7': {
        start: {
          line: 25,
          column: 6
        },
        end: {
          line: 25,
          column: 36
        }
      },
      '8': {
        start: {
          line: 26,
          column: 6
        },
        end: {
          line: 26,
          column: 22
        }
      },
      '9': {
        start: {
          line: 30,
          column: 6
        },
        end: {
          line: 30,
          column: 22
        }
      },
      '10': {
        start: {
          line: 31,
          column: 6
        },
        end: {
          line: 31,
          column: 35
        }
      },
      '11': {
        start: {
          line: 35,
          column: 6
        },
        end: {
          line: 35,
          column: 22
        }
      },
      '12': {
        start: {
          line: 39,
          column: 6
        },
        end: {
          line: 41,
          column: 29
        }
      },
      '13': {
        start: {
          line: 45,
          column: 6
        },
        end: {
          line: 47,
          column: 29
        }
      },
      '14': {
        start: {
          line: 51,
          column: 6
        },
        end: {
          line: 53,
          column: 24
        }
      },
      '15': {
        start: {
          line: 57,
          column: 6
        },
        end: {
          line: 57,
          column: 31
        }
      },
      '16': {
        start: {
          line: 61,
          column: 6
        },
        end: {
          line: 63,
          column: 7
        }
      },
      '17': {
        start: {
          line: 62,
          column: 8
        },
        end: {
          line: 62,
          column: 24
        }
      },
      '18': {
        start: {
          line: 67,
          column: 2
        },
        end: {
          line: 68,
          column: 65
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 69,
            column: 1
          }
        }
      },
      '1': {
        name: 'Auth',
        decl: {
          start: {
            line: 3,
            column: 11
          },
          end: {
            line: 3,
            column: 15
          }
        },
        loc: {
          start: {
            line: 3,
            column: 48
          },
          end: {
            line: 65,
            column: 3
          }
        }
      },
      '2': {
        name: 'storeAuthData',
        decl: {
          start: {
            line: 18,
            column: 13
          },
          end: {
            line: 18,
            column: 26
          }
        },
        loc: {
          start: {
            line: 18,
            column: 37
          },
          end: {
            line: 27,
            column: 5
          }
        }
      },
      '3': {
        name: 'onSignIn',
        decl: {
          start: {
            line: 29,
            column: 13
          },
          end: {
            line: 29,
            column: 21
          }
        },
        loc: {
          start: {
            line: 29,
            column: 28
          },
          end: {
            line: 32,
            column: 5
          }
        }
      },
      '4': {
        name: 'clearAuthData',
        decl: {
          start: {
            line: 34,
            column: 13
          },
          end: {
            line: 34,
            column: 26
          }
        },
        loc: {
          start: {
            line: 34,
            column: 29
          },
          end: {
            line: 36,
            column: 5
          }
        }
      },
      '5': {
        name: 'login',
        decl: {
          start: {
            line: 38,
            column: 13
          },
          end: {
            line: 38,
            column: 18
          }
        },
        loc: {
          start: {
            line: 38,
            column: 25
          },
          end: {
            line: 42,
            column: 5
          }
        }
      },
      '6': {
        name: 'logout',
        decl: {
          start: {
            line: 44,
            column: 13
          },
          end: {
            line: 44,
            column: 19
          }
        },
        loc: {
          start: {
            line: 44,
            column: 22
          },
          end: {
            line: 48,
            column: 5
          }
        }
      },
      '7': {
        name: 'requireAuthentication',
        decl: {
          start: {
            line: 50,
            column: 13
          },
          end: {
            line: 50,
            column: 34
          }
        },
        loc: {
          start: {
            line: 50,
            column: 37
          },
          end: {
            line: 54,
            column: 5
          }
        }
      },
      '8': {
        name: 'isAuthenticated',
        decl: {
          start: {
            line: 56,
            column: 13
          },
          end: {
            line: 56,
            column: 28
          }
        },
        loc: {
          start: {
            line: 56,
            column: 31
          },
          end: {
            line: 58,
            column: 5
          }
        }
      },
      '9': {
        name: 'getUser',
        decl: {
          start: {
            line: 60,
            column: 13
          },
          end: {
            line: 60,
            column: 20
          }
        },
        loc: {
          start: {
            line: 60,
            column: 23
          },
          end: {
            line: 64,
            column: 5
          }
        }
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 61,
            column: 6
          },
          end: {
            line: 63,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 61,
            column: 6
          },
          end: {
            line: 63,
            column: 7
          }
        }, {
          start: {
            line: 61,
            column: 6
          },
          end: {
            line: 63,
            column: 7
          }
        }]
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0
    },
    b: {
      '0': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_1lb8bnujqc.s[0];
(function () {
  ++cov_1lb8bnujqc.f[0];

  function Auth($firebaseAuth, ActivityService) {
    ++cov_1lb8bnujqc.f[1];

    var auth = (++cov_1lb8bnujqc.s[1], $firebaseAuth());
    var authData = (++cov_1lb8bnujqc.s[2], null);

    var service = (++cov_1lb8bnujqc.s[3], {
      auth: auth,
      login: login,
      logout: logout,
      requireAuthentication: requireAuthentication,
      isAuthenticated: isAuthenticated,
      getUser: getUser
    });

    ++cov_1lb8bnujqc.s[4];
    return service;

    function storeAuthData(response) {
      ++cov_1lb8bnujqc.f[2];
      ++cov_1lb8bnujqc.s[5];

      authData = response;
      var activity = (++cov_1lb8bnujqc.s[6], {
        userId: authData.uid,
        type: 'User',
        action: 'Login'
      });
      ++cov_1lb8bnujqc.s[7];
      ActivityService.add(activity);
      ++cov_1lb8bnujqc.s[8];
      return authData;
    }

    function onSignIn(user) {
      ++cov_1lb8bnujqc.f[3];
      ++cov_1lb8bnujqc.s[9];

      authData = user;
      ++cov_1lb8bnujqc.s[10];
      return auth.$requireSignIn();
    }

    function clearAuthData() {
      ++cov_1lb8bnujqc.f[4];
      ++cov_1lb8bnujqc.s[11];

      authData = null;
    }

    function login(user) {
      ++cov_1lb8bnujqc.f[5];
      ++cov_1lb8bnujqc.s[12];

      return auth.$signInWithEmailAndPassword(user.email, user.password).then(storeAuthData);
    }

    function logout() {
      ++cov_1lb8bnujqc.f[6];
      ++cov_1lb8bnujqc.s[13];

      return auth.$signOut().then(clearAuthData);
    }

    function requireAuthentication() {
      ++cov_1lb8bnujqc.f[7];
      ++cov_1lb8bnujqc.s[14];

      return auth.$waitForSignIn().then(onSignIn);
    }

    function isAuthenticated() {
      ++cov_1lb8bnujqc.f[8];
      ++cov_1lb8bnujqc.s[15];

      return Boolean(authData);
    }

    function getUser() {
      ++cov_1lb8bnujqc.f[9];
      ++cov_1lb8bnujqc.s[16];

      if (authData) {
        ++cov_1lb8bnujqc.b[0][0];
        ++cov_1lb8bnujqc.s[17];

        return authData;
      } else {
        ++cov_1lb8bnujqc.b[0][1];
      }
    }
  }

  ++cov_1lb8bnujqc.s[18];
  angular.module('dc-auth').factory('Auth', ['$firebaseAuth', 'ActivityService', Auth]);
})();