'use strict';

var cov_2adkrmv2p4 = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/common/auth/login/login.js',
      hash = '0e40e8bcd844095728f59478e600fc7ed7562dc6',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/common/auth/login/login.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 43,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 16
        },
        end: {
          line: 10,
          column: 3
        }
      },
      '2': {
        start: {
          line: 12,
          column: 2
        },
        end: {
          line: 12,
          column: 49
        }
      },
      '3': {
        start: {
          line: 15,
          column: 17
        },
        end: {
          line: 15,
          column: 21
        }
      },
      '4': {
        start: {
          line: 17,
          column: 4
        },
        end: {
          line: 17,
          column: 19
        }
      },
      '5': {
        start: {
          line: 19,
          column: 34
        },
        end: {
          line: 24,
          column: 5
        }
      },
      '6': {
        start: {
          line: 20,
          column: 20
        },
        end: {
          line: 20,
          column: 41
        }
      },
      '7': {
        start: {
          line: 21,
          column: 21
        },
        end: {
          line: 21,
          column: 43
        }
      },
      '8': {
        start: {
          line: 22,
          column: 22
        },
        end: {
          line: 22,
          column: 80
        }
      },
      '9': {
        start: {
          line: 23,
          column: 6
        },
        end: {
          line: 23,
          column: 40
        }
      },
      '10': {
        start: {
          line: 26,
          column: 4
        },
        end: {
          line: 37,
          column: 6
        }
      },
      '11': {
        start: {
          line: 27,
          column: 6
        },
        end: {
          line: 30,
          column: 7
        }
      },
      '12': {
        start: {
          line: 29,
          column: 8
        },
        end: {
          line: 29,
          column: 15
        }
      },
      '13': {
        start: {
          line: 31,
          column: 6
        },
        end: {
          line: 36,
          column: 11
        }
      },
      '14': {
        start: {
          line: 34,
          column: 10
        },
        end: {
          line: 34,
          column: 29
        }
      },
      '15': {
        start: {
          line: 35,
          column: 10
        },
        end: {
          line: 35,
          column: 54
        }
      },
      '16': {
        start: {
          line: 40,
          column: 2
        },
        end: {
          line: 42,
          column: 31
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 43,
            column: 1
          }
        }
      },
      '1': {
        name: 'loginCtrl',
        decl: {
          start: {
            line: 14,
            column: 11
          },
          end: {
            line: 14,
            column: 20
          }
        },
        loc: {
          start: {
            line: 14,
            column: 41
          },
          end: {
            line: 38,
            column: 3
          }
        }
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 19,
            column: 34
          },
          end: {
            line: 19,
            column: 35
          }
        },
        loc: {
          start: {
            line: 19,
            column: 40
          },
          end: {
            line: 24,
            column: 5
          }
        }
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 26,
            column: 21
          },
          end: {
            line: 26,
            column: 22
          }
        },
        loc: {
          start: {
            line: 26,
            column: 37
          },
          end: {
            line: 37,
            column: 5
          }
        }
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 33,
            column: 15
          },
          end: {
            line: 33,
            column: 16
          }
        },
        loc: {
          start: {
            line: 33,
            column: 24
          },
          end: {
            line: 36,
            column: 9
          }
        }
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 27,
            column: 6
          },
          end: {
            line: 30,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 27,
            column: 6
          },
          end: {
            line: 30,
            column: 7
          }
        }, {
          start: {
            line: 27,
            column: 6
          },
          end: {
            line: 30,
            column: 7
          }
        }]
      },
      '1': {
        loc: {
          start: {
            line: 27,
            column: 10
          },
          end: {
            line: 27,
            column: 38
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 27,
            column: 10
          },
          end: {
            line: 27,
            column: 21
          }
        }, {
          start: {
            line: 27,
            column: 25
          },
          end: {
            line: 27,
            column: 38
          }
        }]
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_2adkrmv2p4.s[0];
(function () {
  ++cov_2adkrmv2p4.f[0];

  var login = (++cov_2adkrmv2p4.s[1], {
    templateUrl: 'app/common/auth/login/login.html',
    controller: loginCtrl,
    bindings: {
      // Resolved state from Auth redirect hook
      returnTo: '<'
    }
  });

  ++cov_2adkrmv2p4.s[2];
  loginCtrl.$inject = ['Auth', '$state', '$log'];

  function loginCtrl(Auth, $state, $log) {
    ++cov_2adkrmv2p4.f[1];

    var self = (++cov_2adkrmv2p4.s[3], this);

    ++cov_2adkrmv2p4.s[4];
    self.user = {};

    ++cov_2adkrmv2p4.s[5];
    var returnToOriginalState = function returnToOriginalState() {
      ++cov_2adkrmv2p4.f[2];

      var state = (++cov_2adkrmv2p4.s[6], self.returnTo.state());
      var params = (++cov_2adkrmv2p4.s[7], self.returnTo.params());
      var options = (++cov_2adkrmv2p4.s[8], Object.assign({}, self.returnTo.options(), { reload: true }));
      ++cov_2adkrmv2p4.s[9];
      $state.go(state, params, options);
    };

    ++cov_2adkrmv2p4.s[10];
    self.userLogin = function (user) {
      ++cov_2adkrmv2p4.f[3];
      ++cov_2adkrmv2p4.s[11];

      if ((++cov_2adkrmv2p4.b[1][0], !user.email) && (++cov_2adkrmv2p4.b[1][1], user.password)) {
        ++cov_2adkrmv2p4.b[0][0];
        ++cov_2adkrmv2p4.s[12];

        // log error
        return;
      } else {
        ++cov_2adkrmv2p4.b[0][1];
      }
      ++cov_2adkrmv2p4.s[13];
      Auth.login(user).then(returnToOriginalState).catch(function (error) {
        ++cov_2adkrmv2p4.f[4];
        ++cov_2adkrmv2p4.s[14];

        self.error = error;
        ++cov_2adkrmv2p4.s[15];
        $log.error('Authentication failed:', error);
      });
    };
  }

  ++cov_2adkrmv2p4.s[16];
  angular.module('dc-auth').component('login', login);
})();