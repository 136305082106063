'use strict';

var cov_22i1a4w5hz = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/common/auth/auth.component.js',
      hash = 'baa6247da43eaba9745e1de52acc77e3b9518c1b',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/common/auth/auth.component.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 20,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 15
        },
        end: {
          line: 6,
          column: 3
        }
      },
      '2': {
        start: {
          line: 8,
          column: 2
        },
        end: {
          line: 8,
          column: 26
        }
      },
      '3': {
        start: {
          line: 11,
          column: 17
        },
        end: {
          line: 11,
          column: 21
        }
      },
      '4': {
        start: {
          line: 12,
          column: 4
        },
        end: {
          line: 12,
          column: 28
        }
      },
      '5': {
        start: {
          line: 13,
          column: 4
        },
        end: {
          line: 13,
          column: 31
        }
      },
      '6': {
        start: {
          line: 14,
          column: 4
        },
        end: {
          line: 14,
          column: 31
        }
      },
      '7': {
        start: {
          line: 17,
          column: 2
        },
        end: {
          line: 19,
          column: 29
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 20,
            column: 1
          }
        }
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 10,
            column: 11
          },
          end: {
            line: 10,
            column: 21
          }
        },
        loc: {
          start: {
            line: 10,
            column: 24
          },
          end: {
            line: 15,
            column: 3
          }
        }
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 12,
            column: 19
          },
          end: {
            line: 12,
            column: 20
          }
        },
        loc: {
          start: {
            line: 12,
            column: 25
          },
          end: {
            line: 12,
            column: 27
          }
        }
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 13,
            column: 22
          },
          end: {
            line: 13,
            column: 23
          }
        },
        loc: {
          start: {
            line: 13,
            column: 28
          },
          end: {
            line: 13,
            column: 30
          }
        }
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 14,
            column: 22
          },
          end: {
            line: 14,
            column: 23
          }
        },
        loc: {
          start: {
            line: 14,
            column: 28
          },
          end: {
            line: 14,
            column: 30
          }
        }
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_22i1a4w5hz.s[0];
(function () {
  ++cov_22i1a4w5hz.f[0];

  var auth = (++cov_22i1a4w5hz.s[1], {
    template: '<ui-view></ui-view>',
    controller: controller
  });

  ++cov_22i1a4w5hz.s[2];
  controller.$inject = [];

  function controller() {
    ++cov_22i1a4w5hz.f[1];

    var self = (++cov_22i1a4w5hz.s[3], this);
    ++cov_22i1a4w5hz.s[4];
    self.$onInit = function () {
      ++cov_22i1a4w5hz.f[2];
    };
    ++cov_22i1a4w5hz.s[5];
    self.$onUpdates = function () {
      ++cov_22i1a4w5hz.f[3];
    };
    ++cov_22i1a4w5hz.s[6];
    self.$onDestroy = function () {
      ++cov_22i1a4w5hz.f[4];
    };
  }

  ++cov_22i1a4w5hz.s[7];
  angular.module('dc-auth').component('auth', auth);
})();