'use strict';

var cov_1cfkl35z7h = function () {
  var path = '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/service/ticket-list/ticket-list.component.js',
      hash = '341157f7abe5f5528cdaea909bbc6b7fa7f0de3c',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/divcon/divcon-tools/src/app/service/ticket-list/ticket-list.component.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 89,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 21
        },
        end: {
          line: 6,
          column: 3
        }
      },
      '2': {
        start: {
          line: 8,
          column: 2
        },
        end: {
          line: 8,
          column: 50
        }
      },
      '3': {
        start: {
          line: 11,
          column: 17
        },
        end: {
          line: 11,
          column: 21
        }
      },
      '4': {
        start: {
          line: 12,
          column: 4
        },
        end: {
          line: 12,
          column: 21
        }
      },
      '5': {
        start: {
          line: 13,
          column: 4
        },
        end: {
          line: 13,
          column: 39
        }
      },
      '6': {
        start: {
          line: 15,
          column: 4
        },
        end: {
          line: 17,
          column: 6
        }
      },
      '7': {
        start: {
          line: 16,
          column: 6
        },
        end: {
          line: 16,
          column: 39
        }
      },
      '8': {
        start: {
          line: 19,
          column: 4
        },
        end: {
          line: 25,
          column: 6
        }
      },
      '9': {
        start: {
          line: 20,
          column: 6
        },
        end: {
          line: 23,
          column: 7
        }
      },
      '10': {
        start: {
          line: 21,
          column: 8
        },
        end: {
          line: 21,
          column: 32
        }
      },
      '11': {
        start: {
          line: 22,
          column: 8
        },
        end: {
          line: 22,
          column: 15
        }
      },
      '12': {
        start: {
          line: 24,
          column: 6
        },
        end: {
          line: 24,
          column: 32
        }
      },
      '13': {
        start: {
          line: 27,
          column: 4
        },
        end: {
          line: 33,
          column: 6
        }
      },
      '14': {
        start: {
          line: 28,
          column: 6
        },
        end: {
          line: 31,
          column: 7
        }
      },
      '15': {
        start: {
          line: 29,
          column: 8
        },
        end: {
          line: 29,
          column: 38
        }
      },
      '16': {
        start: {
          line: 30,
          column: 8
        },
        end: {
          line: 30,
          column: 15
        }
      },
      '17': {
        start: {
          line: 32,
          column: 6
        },
        end: {
          line: 32,
          column: 41
        }
      },
      '18': {
        start: {
          line: 35,
          column: 4
        },
        end: {
          line: 45,
          column: 6
        }
      },
      '19': {
        start: {
          line: 36,
          column: 6
        },
        end: {
          line: 40,
          column: 7
        }
      },
      '20': {
        start: {
          line: 37,
          column: 8
        },
        end: {
          line: 37,
          column: 34
        }
      },
      '21': {
        start: {
          line: 38,
          column: 8
        },
        end: {
          line: 38,
          column: 32
        }
      },
      '22': {
        start: {
          line: 39,
          column: 8
        },
        end: {
          line: 39,
          column: 15
        }
      },
      '23': {
        start: {
          line: 41,
          column: 6
        },
        end: {
          line: 43,
          column: 7
        }
      },
      '24': {
        start: {
          line: 42,
          column: 8
        },
        end: {
          line: 42,
          column: 33
        }
      },
      '25': {
        start: {
          line: 44,
          column: 6
        },
        end: {
          line: 44,
          column: 31
        }
      },
      '26': {
        start: {
          line: 47,
          column: 4
        },
        end: {
          line: 56,
          column: 6
        }
      },
      '27': {
        start: {
          line: 48,
          column: 6
        },
        end: {
          line: 51,
          column: 7
        }
      },
      '28': {
        start: {
          line: 49,
          column: 8
        },
        end: {
          line: 49,
          column: 27
        }
      },
      '29': {
        start: {
          line: 50,
          column: 8
        },
        end: {
          line: 50,
          column: 15
        }
      },
      '30': {
        start: {
          line: 52,
          column: 6
        },
        end: {
          line: 54,
          column: 7
        }
      },
      '31': {
        start: {
          line: 53,
          column: 8
        },
        end: {
          line: 53,
          column: 32
        }
      },
      '32': {
        start: {
          line: 55,
          column: 6
        },
        end: {
          line: 55,
          column: 25
        }
      },
      '33': {
        start: {
          line: 58,
          column: 4
        },
        end: {
          line: 64,
          column: 6
        }
      },
      '34': {
        start: {
          line: 59,
          column: 6
        },
        end: {
          line: 62,
          column: 7
        }
      },
      '35': {
        start: {
          line: 60,
          column: 8
        },
        end: {
          line: 60,
          column: 38
        }
      },
      '36': {
        start: {
          line: 61,
          column: 8
        },
        end: {
          line: 61,
          column: 15
        }
      },
      '37': {
        start: {
          line: 63,
          column: 6
        },
        end: {
          line: 63,
          column: 35
        }
      },
      '38': {
        start: {
          line: 66,
          column: 4
        },
        end: {
          line: 72,
          column: 6
        }
      },
      '39': {
        start: {
          line: 67,
          column: 6
        },
        end: {
          line: 70,
          column: 7
        }
      },
      '40': {
        start: {
          line: 68,
          column: 8
        },
        end: {
          line: 68,
          column: 29
        }
      },
      '41': {
        start: {
          line: 69,
          column: 8
        },
        end: {
          line: 69,
          column: 15
        }
      },
      '42': {
        start: {
          line: 71,
          column: 6
        },
        end: {
          line: 71,
          column: 27
        }
      },
      '43': {
        start: {
          line: 74,
          column: 4
        },
        end: {
          line: 83,
          column: 6
        }
      },
      '44': {
        start: {
          line: 75,
          column: 6
        },
        end: {
          line: 75,
          column: 23
        }
      },
      '45': {
        start: {
          line: 76,
          column: 6
        },
        end: {
          line: 76,
          column: 30
        }
      },
      '46': {
        start: {
          line: 77,
          column: 6
        },
        end: {
          line: 77,
          column: 27
        }
      },
      '47': {
        start: {
          line: 78,
          column: 6
        },
        end: {
          line: 78,
          column: 42
        }
      },
      '48': {
        start: {
          line: 79,
          column: 6
        },
        end: {
          line: 79,
          column: 45
        }
      },
      '49': {
        start: {
          line: 80,
          column: 6
        },
        end: {
          line: 82,
          column: 9
        }
      },
      '50': {
        start: {
          line: 81,
          column: 8
        },
        end: {
          line: 81,
          column: 31
        }
      },
      '51': {
        start: {
          line: 86,
          column: 2
        },
        end: {
          line: 88,
          column: 41
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 89,
            column: 1
          }
        }
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 10,
            column: 11
          },
          end: {
            line: 10,
            column: 21
          }
        },
        loc: {
          start: {
            line: 10,
            column: 44
          },
          end: {
            line: 84,
            column: 3
          }
        }
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 15,
            column: 23
          },
          end: {
            line: 15,
            column: 24
          }
        },
        loc: {
          start: {
            line: 15,
            column: 31
          },
          end: {
            line: 17,
            column: 5
          }
        }
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 19,
            column: 24
          },
          end: {
            line: 19,
            column: 25
          }
        },
        loc: {
          start: {
            line: 19,
            column: 34
          },
          end: {
            line: 25,
            column: 5
          }
        }
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 27,
            column: 24
          },
          end: {
            line: 27,
            column: 25
          }
        },
        loc: {
          start: {
            line: 27,
            column: 30
          },
          end: {
            line: 33,
            column: 5
          }
        }
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 35,
            column: 23
          },
          end: {
            line: 35,
            column: 24
          }
        },
        loc: {
          start: {
            line: 35,
            column: 29
          },
          end: {
            line: 45,
            column: 5
          }
        }
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 47,
            column: 25
          },
          end: {
            line: 47,
            column: 26
          }
        },
        loc: {
          start: {
            line: 47,
            column: 31
          },
          end: {
            line: 56,
            column: 5
          }
        }
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 58,
            column: 22
          },
          end: {
            line: 58,
            column: 23
          }
        },
        loc: {
          start: {
            line: 58,
            column: 28
          },
          end: {
            line: 64,
            column: 5
          }
        }
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 66,
            column: 24
          },
          end: {
            line: 66,
            column: 25
          }
        },
        loc: {
          start: {
            line: 66,
            column: 30
          },
          end: {
            line: 72,
            column: 5
          }
        }
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 74,
            column: 19
          },
          end: {
            line: 74,
            column: 20
          }
        },
        loc: {
          start: {
            line: 74,
            column: 25
          },
          end: {
            line: 83,
            column: 5
          }
        }
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 80,
            column: 38
          },
          end: {
            line: 80,
            column: 39
          }
        },
        loc: {
          start: {
            line: 80,
            column: 49
          },
          end: {
            line: 82,
            column: 7
          }
        }
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 20,
            column: 6
          },
          end: {
            line: 23,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 20,
            column: 6
          },
          end: {
            line: 23,
            column: 7
          }
        }, {
          start: {
            line: 20,
            column: 6
          },
          end: {
            line: 23,
            column: 7
          }
        }]
      },
      '1': {
        loc: {
          start: {
            line: 28,
            column: 6
          },
          end: {
            line: 31,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 28,
            column: 6
          },
          end: {
            line: 31,
            column: 7
          }
        }, {
          start: {
            line: 28,
            column: 6
          },
          end: {
            line: 31,
            column: 7
          }
        }]
      },
      '2': {
        loc: {
          start: {
            line: 36,
            column: 6
          },
          end: {
            line: 40,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 36,
            column: 6
          },
          end: {
            line: 40,
            column: 7
          }
        }, {
          start: {
            line: 36,
            column: 6
          },
          end: {
            line: 40,
            column: 7
          }
        }]
      },
      '3': {
        loc: {
          start: {
            line: 41,
            column: 6
          },
          end: {
            line: 43,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 41,
            column: 6
          },
          end: {
            line: 43,
            column: 7
          }
        }, {
          start: {
            line: 41,
            column: 6
          },
          end: {
            line: 43,
            column: 7
          }
        }]
      },
      '4': {
        loc: {
          start: {
            line: 48,
            column: 6
          },
          end: {
            line: 51,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 48,
            column: 6
          },
          end: {
            line: 51,
            column: 7
          }
        }, {
          start: {
            line: 48,
            column: 6
          },
          end: {
            line: 51,
            column: 7
          }
        }]
      },
      '5': {
        loc: {
          start: {
            line: 52,
            column: 6
          },
          end: {
            line: 54,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 52,
            column: 6
          },
          end: {
            line: 54,
            column: 7
          }
        }, {
          start: {
            line: 52,
            column: 6
          },
          end: {
            line: 54,
            column: 7
          }
        }]
      },
      '6': {
        loc: {
          start: {
            line: 59,
            column: 6
          },
          end: {
            line: 62,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 59,
            column: 6
          },
          end: {
            line: 62,
            column: 7
          }
        }, {
          start: {
            line: 59,
            column: 6
          },
          end: {
            line: 62,
            column: 7
          }
        }]
      },
      '7': {
        loc: {
          start: {
            line: 67,
            column: 6
          },
          end: {
            line: 70,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 67,
            column: 6
          },
          end: {
            line: 70,
            column: 7
          }
        }, {
          start: {
            line: 67,
            column: 6
          },
          end: {
            line: 70,
            column: 7
          }
        }]
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_1cfkl35z7h.s[0];
(function () {
  ++cov_1cfkl35z7h.f[0];

  var ticketList = (++cov_1cfkl35z7h.s[1], {
    templateUrl: 'app/service/ticket-list/ticket-list.html',
    controller: controller
  });

  ++cov_1cfkl35z7h.s[2];
  controller.$inject = ['Users', 'TicketService'];

  function controller(Users, TicketService) {
    ++cov_1cfkl35z7h.f[1];

    var self = (++cov_1cfkl35z7h.s[3], this);
    ++cov_1cfkl35z7h.s[4];
    self.filter = {};
    ++cov_1cfkl35z7h.s[5];
    self.filter.assignedTo = self.user;

    ++cov_1cfkl35z7h.s[6];
    self.isUndefined = function (data) {
      ++cov_1cfkl35z7h.f[2];
      ++cov_1cfkl35z7h.s[7];

      return angular.isUndefined(data);
    };

    ++cov_1cfkl35z7h.s[8];
    self.ticketStatus = function (status) {
      ++cov_1cfkl35z7h.f[3];
      ++cov_1cfkl35z7h.s[9];

      if (angular.isUndefined(status)) {
        ++cov_1cfkl35z7h.b[0][0];
        ++cov_1cfkl35z7h.s[10];

        delete self.filter.open;
        ++cov_1cfkl35z7h.s[11];
        return;
      } else {
        ++cov_1cfkl35z7h.b[0][1];
      }
      ++cov_1cfkl35z7h.s[12];
      self.filter.open = status;
    };

    ++cov_1cfkl35z7h.s[13];
    self.assignedToMe = function () {
      ++cov_1cfkl35z7h.f[4];
      ++cov_1cfkl35z7h.s[14];

      if (self.filter.assignedTo) {
        ++cov_1cfkl35z7h.b[1][0];
        ++cov_1cfkl35z7h.s[15];

        delete self.filter.assignedTo;
        ++cov_1cfkl35z7h.s[16];
        return;
      } else {
        ++cov_1cfkl35z7h.b[1][1];
      }
      ++cov_1cfkl35z7h.s[17];
      self.filter.assignedTo = self.user;
    };

    ++cov_1cfkl35z7h.s[18];
    self.readyToBill = function () {
      ++cov_1cfkl35z7h.f[5];
      ++cov_1cfkl35z7h.s[19];

      if (self.filter.billed) {
        ++cov_1cfkl35z7h.b[2][0];
        ++cov_1cfkl35z7h.s[20];

        delete self.filter.billed;
        ++cov_1cfkl35z7h.s[21];
        self.filter.open = true;
        ++cov_1cfkl35z7h.s[22];
        return;
      } else {
        ++cov_1cfkl35z7h.b[2][1];
      }
      ++cov_1cfkl35z7h.s[23];
      if (self.filter.open) {
        ++cov_1cfkl35z7h.b[3][0];
        ++cov_1cfkl35z7h.s[24];

        self.filter.open = false;
      } else {
        ++cov_1cfkl35z7h.b[3][1];
      }
      ++cov_1cfkl35z7h.s[25];
      self.filter.billed = '!';
    };

    ++cov_1cfkl35z7h.s[26];
    self.readyToReview = function () {
      ++cov_1cfkl35z7h.f[6];
      ++cov_1cfkl35z7h.s[27];

      if (self.review) {
        ++cov_1cfkl35z7h.b[4][0];
        ++cov_1cfkl35z7h.s[28];

        delete self.review;
        ++cov_1cfkl35z7h.s[29];
        return;
      } else {
        ++cov_1cfkl35z7h.b[4][1];
      }
      ++cov_1cfkl35z7h.s[30];
      if (!self.filter.open) {
        ++cov_1cfkl35z7h.b[5][0];
        ++cov_1cfkl35z7h.s[31];

        self.filter.open = true;
      } else {
        ++cov_1cfkl35z7h.b[5][1];
      }
      ++cov_1cfkl35z7h.s[32];
      self.review = true;
    };

    ++cov_1cfkl35z7h.s[33];
    self.unassigned = function () {
      ++cov_1cfkl35z7h.f[7];
      ++cov_1cfkl35z7h.s[34];

      if (self.filter.assignedTo) {
        ++cov_1cfkl35z7h.b[6][0];
        ++cov_1cfkl35z7h.s[35];

        delete self.filter.assignedTo;
        ++cov_1cfkl35z7h.s[36];
        return;
      } else {
        ++cov_1cfkl35z7h.b[6][1];
      }
      ++cov_1cfkl35z7h.s[37];
      self.filter.assignedTo = '!';
    };

    ++cov_1cfkl35z7h.s[38];
    self.highPriority = function () {
      ++cov_1cfkl35z7h.f[8];
      ++cov_1cfkl35z7h.s[39];

      if (self.minPriority >= 5) {
        ++cov_1cfkl35z7h.b[7][0];
        ++cov_1cfkl35z7h.s[40];

        self.minPriority = 0;
        ++cov_1cfkl35z7h.s[41];
        return;
      } else {
        ++cov_1cfkl35z7h.b[7][1];
      }
      ++cov_1cfkl35z7h.s[42];
      self.minPriority = 5;
    };

    ++cov_1cfkl35z7h.s[43];
    self.$onInit = function () {
      ++cov_1cfkl35z7h.f[9];
      ++cov_1cfkl35z7h.s[44];

      self.filter = {};
      ++cov_1cfkl35z7h.s[45];
      self.filter.open = true;
      ++cov_1cfkl35z7h.s[46];
      self.minPriority = 0;
      ++cov_1cfkl35z7h.s[47];
      self.user = Users.currentUser().$id;
      ++cov_1cfkl35z7h.s[48];
      self.roles = Users.currentUser().roles;
      ++cov_1cfkl35z7h.s[49];
      TicketService.$getAll().$loaded(function (tickets) {
        ++cov_1cfkl35z7h.f[10];
        ++cov_1cfkl35z7h.s[50];

        self.tickets = tickets;
      });
    };
  }

  ++cov_1cfkl35z7h.s[51];
  angular.module('dc-service').component('ticketList', ticketList);
})();